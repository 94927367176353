$.fn.compareTable = function(p) {
	/**
	 * требует:
	 *   jquery
	 *   fw_MAMMON
	 *
	 * респонсивность таблицы настраиваем сами через стили
	 . - обязательный блок-обертка
		 .cars_compare_block - overflow-блок
			 .cars_compare_table - сама таблица сравнения

	 * var sets сделано на задание параметров из вне
	    $('.cars_compare').compareTable({
	        initFixedMenu: true,
	        initFunction: function() {
				//
			},
			scrollFunction: function() {
				//
			}
	    });

	 */
	var sets = {
			initFixedMenu: true,
			initFixedHeader: true,
			correctTopFixedHeader: 0,
			initCompareCards: true,
			compareCardsStart: true,
			//
			overflowBlockSelector: '.cars_compare_block',
			tableSelector: '.cars_compare_table',
			emptyCarsSelector: '.hidden_empty_compare',
			globalBlockSelector: '.cars_compare',
			//
			fixedMenuAddClass: 'fixed_menu',
			fixedHeaderAddClass: 'fixed_header',
			fixedCompareBlockAddClass: 'fixed_compare_block',
			//
			hideOriginMenuAddClass: 'hide_origin_menu',
			hideOriginHeaderAddClass: 'hide_origin_header',
			//
			deletedCellClass: 'deleted_compare_cell',
			compareButtonClass: 'favorites_button',
			deletedCompareButtonClass: 'deleted_favorites_button',
			//
			initFunction: false,
			scrollFunction: false,
		    onHide: false,
		    onUndoHide: false
		},
		isMobilePlatform = fw_MAMMON.isMobileDevice(),
		fixedRemoveClasses = sets.fixedMenuAddClass
			+ ' ' + sets.fixedHeaderAddClass
			+ ' ' + sets.fixedCompareBlockAddClass
			+ ' ' + sets.hideOriginMenuAddClass
			+ ' ' + sets.hideOriginHeaderAddClass,
		that = this,
		overflowBlock = this.find(sets.overflowBlockSelector),
		table = overflowBlock.find(sets.tableSelector),
		fixedLeftMenu,
		fixedHeader,
		fixedDifferenceMenu;

	if(overflowBlock.length){
		initPlugin();
		$(window).resize(function() {
			setResizeSizes();
			slideCards(null, overflowBlock.attr('data-act-step'));
			if(sets.scrollFunction)
				sets.scrollFunction();
		});
		$(window).scroll(function() {
			setScrollSizes();
			if(sets.scrollFunction)
				sets.scrollFunction();
		});
	}
	
	function initPlugin() {
		if(p) {
			$.each(p, function(index, value) {
				if(sets.hasOwnProperty(index))
					sets[index] = value;
			});
		}
		initFixedMenu();
		initFixedHeader();
		initSliding();
		initCompareCardsMenu();
		setResizeSizes();
		slideCards(null, 0);
		initHoverRows();
		initDeleteFromFavorites();
		if(sets.initFunction)
			sets.initFunction();
	}
	function initFixedMenu() {
		if(sets.initFixedMenu) {
			fixedLeftMenu = table.clone().insertBefore(overflowBlock)
				.removeClass(fixedRemoveClasses).addClass(sets.fixedMenuAddClass);
			fixedLeftMenu.find('> * > *:not(:first-child)').remove();
			table.addClass(sets.hideOriginMenuAddClass);
		}
	}
	function initFixedHeader() {
		if(sets.initFixedHeader) {
			fixedHeader = $(
				'<div class="' + sets.fixedHeaderAddClass + '">'
					+ '<div class="overflow_fixed_header"></div>'
				+ '</div>')
				.insertBefore(overflowBlock);
			fixedHeader.find('.overflow_fixed_header').append( table.clone().removeClass(fixedRemoveClasses) )
				.find('> * > *:not(:first-child)').remove();
			table.addClass(sets.hideOriginHeaderAddClass);
		}
	}
	function initSliding() {
		overflowBlock.attr('data-act-step', 0);
		if(isMobilePlatform) {
			that.on('swiperight', function() {
				slideCards(-1);
			})
				.on('swipeleft', function() {
					slideCards(1);
				});
		}

		if(sets.initFixedHeader) {
			fixedHeader.append(
				'<div class="slider_navigation">'
					+ '<span class="button icon-left-big" href="#" data-set="-1"></span>'
					+ '<span class="button icon-right-big" href="#" data-set="1"></span>'
				+ '</div>'
			);
		}
		that.on('click', '.slider_navigation .button', function() {
			slideCards( $(this).data('set') );
			return false;
		});
	}
	function initDeleteFromFavorites() {
		$(document).on('click', sets.tableSelector + ' .' + sets.compareButtonClass, function (event) {
			if ($(this).hasClass(sets.deletedCompareButtonClass)) {
				$(this).removeClass(sets.deletedCompareButtonClass);
			} 
			else {
				var car_block = $(this).closest('.preview_card');
				$(this).addClass(sets.deletedCompareButtonClass);
				hideCar(car_block);
			}
			fw_MAMMON.preventHref(event);
		});
		$(document).on('undoHideCar', function() {
			undoHideCar();
		});
	}
	function initCompareCardsMenu() {
		if( table.find('> *:first-child > *').length > 2 ) {
			if (sets.initFixedHeader) {
				fixedDifferenceMenu = table.clone()
					.removeClass(fixedRemoveClasses).addClass('fixed_compare_block');
				fixedDifferenceMenu.children()
					.each(function (i) {
						if (!i) {
							$(this).find('> *:not(:first-child)').remove();
							$(this).find('> *').html(
								'<div class="block">'
									+ 'Показывать только отличия?'
									+ '<ul>'
										+ '<li><a class="button act" data-set="1" href="#">Да</a></li>'
										+ '<li><a class="button" data-set="0" href="#">Нет</a></li>'
									+ '</ul>'
								+ '</div>');
						}
						else
							$(this).remove();
					});
				fixedHeader.append(fixedDifferenceMenu);
			}
			if (sets.initCompareCards) {
				compareTableShowDifferences(sets.compareCardsStart);
				that.on('click', '.difference_block .button', function () {
					compareTableShowDifferences($(this).data('set'));
					return false;
				});
			}
		}
	}
	function slideCards(shiftStep, setStep) {
		shiftStep = +shiftStep;
		var actualStep = +(overflowBlock.attr('data-act-step')),
			headerChilds = table.find('> *:first-child > *'),
			countCards = headerChilds.length - 1,
			animate = typeof setStep == 'undefined';

		setStep = typeof setStep != 'undefined' ? parseInt(setStep) : actualStep + shiftStep;
		setStep = setStep < 0 ? 0 : setStep;
		setStep = setStep > countCards - 1 ? countCards - 1 : setStep;

		var setLeft = headerChilds.eq(setStep + 1).position().left - headerChilds.eq(1).position().left,
			maxLeft = (table.width() - overflowBlock.width());

		if(setLeft <= maxLeft) {
			overflowBlock.attr('data-act-step', setStep);
			if(animate) {
				overflowBlock.animate({scrollLeft: setLeft});
				fixedHeader.find('.overflow_fixed_header').animate({scrollLeft: setLeft});
			} else {
				overflowBlock.scrollLeft(setLeft);
				fixedHeader.find('.overflow_fixed_header').scrollLeft(setLeft);
			}
		}

		that.find('.slider_navigation').removeClass('hidden')
			.find('.disabled').removeClass('disabled');

		// TODO bad fix :( из-за стилизации рамки на карточках ширина таблицы и overflow плохо сопадают )
		//погрешность влезания блока для показа стрелочек
		var imprecision = 5;
		if(table.width() - overflowBlock.width() <= imprecision)
			that.find('.slider_navigation').addClass('hidden');
		else {
			if(!setStep)
				that.find('.slider_navigation .button:first-child').addClass('disabled');
			if(countCards < setStep + 1 || (setLeft / setStep * (setStep + 1)) > maxLeft)
				that.find('.slider_navigation .button:last-child').addClass('disabled');
		}

	}
	function compareTableShowDifferences(show) {
		show = +show;
		that.find('.difference_block .act').removeClass('act');
		that.find('.difference_block .button[data-set=' + show + ']').addClass('act');
		that.find('.hidden').removeClass('hidden');

		if(show) {
			// TODO этот момент с прятаньем заголовков требует упрощения
			var blocks = [],
				block_i,
				miniBlock_i;
			table.find('> *:not(.no_check_diff)').each(function(row_i) {
				if($(this).is('.title')) {
					// TODO этот момент с прятаньем заголовков требует упрощения
					if( !$(this).is('.mini') ) {
						block_i = blocks.length;
						miniBlock_i = 0;
						blocks[block_i] = {
							title: row_i,
							miniBlocks: []
						};
						blocks[block_i].miniBlocks[miniBlock_i] = {
							hideRows: true
						};
					} else {
						miniBlock_i = blocks[block_i].miniBlocks.length;
						blocks[block_i].miniBlocks[miniBlock_i] = {
							title: row_i,
							hideRows: true
						};
					}
				} else {
					var rowValues = [];
					var value;
					var cols = $(this).find('> *:not(:first):not(.' + sets.deletedCellClass + ')');
					var cols_count = cols.size();
					cols.each(function(col_i) {
						if(!col_i)
							rowValues = [];
						value = $(this).html().trim();
						if($.inArray(value, rowValues) == -1)
							rowValues.push(value);
					});
					if(rowValues.length == 1 && cols_count > 1) {
						$(this).addClass('hidden');
						if(sets.initFixedMenu)
							fixedLeftMenu.find('> *:not(.no_check_diff)').eq(row_i).addClass('hidden');
					}
					else
						blocks[block_i].miniBlocks[miniBlock_i].hideRows = false;
				}
			});

			// TODO этот момент с прятаньем заголовков требует упрощения
			for(var i = 0; i < blocks.length; i++) {
				var hideBlock = true;
				for(var x = 0; x < blocks[i].miniBlocks.length; x++) {
					if(blocks[i].miniBlocks[x].hideRows) {
						table.find('> *:not(.no_check_diff)').eq( blocks[i].miniBlocks[x].title ).addClass('hidden');
						if(sets.initFixedMenu)
							fixedLeftMenu.find('> *:not(.no_check_diff)').eq( blocks[i].miniBlocks[x].title ).addClass('hidden');
					}
					else
						hideBlock = false;
				}
				if(hideBlock) {
					table.find('> *:not(.no_check_diff)').eq( blocks[i].title ).addClass('hidden');
					if(sets.initFixedMenu)
						fixedLeftMenu.find('> *:not(.no_check_diff)').eq( blocks[i].title ).addClass('hidden');
				}
			}
		}
		// TODO bad fix ( после сравнения высвечиваются стрелочки, но при этом прокручивать некуда )
		slideCards(null, overflowBlock.attr('data-act-step'));
	}
	//удаляет скрытые ранее машины, если такие есть, а потом скрывает текущую машину
	function hideCar(car_block){
		//дропаем все скрытые ранее ячейки из таблицы
		$('.' + sets.deletedCellClass).remove();
		//считаем номер текущего дива внутри контейнера, для того чтобы по этому номеру потом скрывать ячейки
		var cards = that.find('.preview_cards .preview_card');
		var car_index = cards.index(car_block);
		if (car_index > 0) car_index = car_index - 1;
		//вешаем на эти ячейки класс
		$(sets.tableSelector + ' > *').each(
			function() {
				$(this).find('> *:eq(' + car_index + ')').addClass(sets.deletedCellClass);
			}
		);
		compareTableShowDifferences(that.find('.difference_block .act').data('set'));
		checkEmptyCars();
		//показываем всплывающее окно с предложением вернуть
		if (sets.onHide)
			sets.onHide();
	}
	function undoHideCar() {
		that.find('.' + sets.deletedCellClass).removeClass(sets.deletedCellClass);
		compareTableShowDifferences(that.find('.difference_block .act').data('set'));
		//подразумевается, что где-то на сайте висят какие-то еще события на добавление и убирание из избранного по такой кнопке
		//например, добавляется в кукисы назад и в счетчике в шапке сверху
		that.find('.' + sets.deletedCompareButtonClass).click();
		checkEmptyCars();
		if (sets.onUndoHide)
			sets.onUndoHide();
	}
	function checkEmptyCars() {
		$(sets.globalBlockSelector).show();
		if (that.find('.preview_cards .preview_card:not(\'.difference_block\'):visible').size() > 0) {
			$(sets.emptyCarsSelector).hide();
		} 
		else {
			$(sets.emptyCarsSelector).show();
			$(sets.globalBlockSelector).hide();
		}
	}
	function setResizeSizes() {
		if(sets.initFixedMenu) {
			var originRows = table.find('> *');
			fixedLeftMenu.find('> *').each(function(i) {
				var maxHeight = originRows.eq(i).height('auto').height();
				if($(this).height('auto').height() > maxHeight)
					maxHeight = $(this).height();

				originRows.eq(i).height(maxHeight);
				$(this).height(maxHeight);
			});
		}
		if(sets.initFixedMenu || sets.initFixedHeader) {
			if(fixedDifferenceMenu) {
				fixedDifferenceMenu.find('> * > *').css({
					// TODO + 3 bad fix =( (доп ширина из-за выравнивания стрелочек, чтобы фон под ними не ездил с краю)
					maxWidth: table.find('> * > *:first-child').innerWidth() + 4,
					minWidth: table.find('> * > *:first-child').innerWidth() + 4
				});
			}
			if(sets.initFixedHeader) {
				fixedHeader.css('width', overflowBlock.innerWidth() < table.innerWidth() ? overflowBlock.innerWidth() : table.innerWidth());
				var setLeft = table.find('> * > *:nth-child(2)').offset().left - table.offset().left;
				fixedHeader.find('.slider_navigation').css({
					left: setLeft,
					width: fixedHeader.innerWidth() - setLeft
				});
			}
		}
		slideCards(null, overflowBlock.attr('data-act-step'));
	}
	function setScrollSizes() {
		if(sets.initFixedMenu || sets.initFixedHeader) {
			var checkTop = -(table.find('> * > *').offset().top - $(window).scrollTop() - sets.correctTopFixedHeader);

			if(checkTop > 0)
				fixedHeader.css('top', sets.correctTopFixedHeader).addClass('fly');
			else
				fixedHeader.css('top', 'auto').removeClass('fly');
		}
	}
	function initHoverRows() {
		that
			.on('mouseover', sets.tableSelector + ' > *:not(.no_hover) > * ', function() {
				$(sets.tableSelector +' > *:nth-child(' + ($(this).parent().index() + 1) + ')').addClass('hover');
			})
			.on('mouseout', sets.tableSelector +' > * > * ', function() {
				$(sets.tableSelector + ' .hover').removeClass('hover');
			});
	}

	return this;
};