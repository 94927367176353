var SEARCH_PANEL = function () {
    var controllers = {};

    var dynamic_classes = {
        panel_content_block: "search_panel_content_block",
        panel_content: "search_panel_content",
        panel_container: "search_panel_container",
        opened: "opened",
        search_panel_opened: "search_panel_opened",
        search_ready: "search_ready cars-block-sm",
        disabled: "disabled",
        checked: "checked"
    };

    var selectors = {
        sp_container: ".search_panel_container",
        sp_open_close_button: ".toggle_search_button",
        change_search_button: ".change_search_button",
        hidden_panel_content: ".panel_content_for_detach",
        toggle_status_container: ".toggle_search_panel_status_container",
        selected_items_container: ".selected_items_container",
        start_search_button: ".start_search_button",
        custom_check_container: ".custom_check",
        custom_check_inputs: ".custom_check input",
        hidden_brands_panel_content: ".brands_panel_content_for_detach",
        hidden_models_panel_content: ".models_panel_content_for_detach",
        brands_button: ".open_brands_window_button",
        models_button: ".open_models_window_button",
        brands_confirm_button: ".confirm_brands_select_button",
        models_confirm_button: ".confirm_models_select_button",

        panel_checkboxes: ".checkbox_or_single_check",

        models_multi_select_checker: ".models_multi_select_checker",
        brands_multi_select_checker: ".brands_multi_select_checker",
        checkboxes_list_container: ".checkboxes_list_container",
        search_result_container: ".search_result_container",
        page_data_container: ".page_data_container",
        remove_after_cars_search: ".remove_after_cars_search",
        result_count_text: ".result_count",
        result_target_container: ".search_result_target_container",
        pagination_pre_load_before: ".pagination_pre_load_before",
        pagination_post_load_after: ".pagination_post_load_after",
        search_params_container: ".search_params_container",
        new_search_action: ".new_search_action",
        with_back_button: ".with_back_button",
        back_panel: ".back_panel",
        back_by_url_hash_action: ".back_by_url_hash_action",
        ajax_breadcrumbs_target_container: ".ajax_breadcrumbs_target_container"
    };

    var events_namespaces = {
        open_close_panel: "open_close_panel",
        start_search: "start_search",
        custom_check: "custom_check",
        multi_select_checker: "multi_select_checker",
        panel_check: "panel_check",
        confirm_select: "confirm_select",
        sync_selectors: "sync_selectors",
        analyze_hash: "analyze_hash",
        paginations: "paginations",
        sort_orders: "sort_orders",
        wrappers: "wrappers",
        favorites: "favorites",
        filter_change: "filter_change",
        city_change: "city_change"
    };

    var MULTI_CHECK_PANEL = function () {
        var $multi_check_panel = {};
        var default_options = {
            hidden_content: null,
            panel_title: "",
            multi_checker_selector: "",
            submit_button_selector: "",
            checkboxes_list_container: "",
            panel_checkboxes: ""
        };
        var default_callbacks = {
            beforeOpen: null,
            afterClose: null,
            afterOpen: null,
            check: null,
            unCheck: null,
            confirm: null,
            changeSelectionMode: null
        };

        function createInstance(options, callbacks) {
            var $instance = {};
            var inst_options = $.extend({}, default_options, options);
            var inst_callbacks = $.extend({}, default_callbacks, callbacks);

            function openPanel() {

                if (!$instance.panel) {
                    if (inst_callbacks.beforeOpen) inst_callbacks.beforeOpen($instance);
                    $instance.panel = fw_WIN.create(
                        {
                            without_padding: true,
                            block_mode: true,
                            top_offset: 53,
                            offset: 0,
                            title: inst_options.panel_title,
                            content_class: dynamic_classes.panel_content,
                            container_class: dynamic_classes.panel_container,
                            content: $instance.hidden_content,
                            content_block_class: dynamic_classes.panel_content_block
                        },
                        {
                            onAfterOpen: function () {
                                fw_MAMMON.fixBodyScroll.doFix(true);
                                if (inst_callbacks.afterOpen) inst_callbacks.afterOpen($instance);
                                $('body').addClass('open_panel');
                            },
                            onBeforeClose: function () {
                                clearDetach($instance.hidden_content);
                                $('body').removeClass('open_panel');
                                return true;
                            },
                            onAfterClose: function () {
                                if (inst_callbacks.afterClose) inst_callbacks.afterClose($instance);
                                $instance.panel = null;

                            }
                        }
                    );
                }
            }


            function changeCheckbox(checkbox) {
                var value = $(checkbox).val();
                if ($(checkbox).is(":checked")) {
                    if (inst_callbacks.check) inst_callbacks.check($instance, value);
                    if ($instance.panel != null && $instance.single_select_mode) {
                        $instance.checkboxes_list_container.find(inst_options.panel_checkboxes + ":checked").each(function () {
                            if (checkbox != this) {
                                $(this).prop('checked', false).change();
                            }
                        });
                        confirmSelect();
                    }
                } else {
                    if (inst_callbacks.unCheck) inst_callbacks.unCheck($instance, value);
                }
            }

            function updateSelectMode() {
                var old_single_selection_mode = $instance.single_select_mode;
                $instance.single_select_mode = !$instance.multi_checker.is(":checked");
                if (old_single_selection_mode != $instance.single_select_mode) {
                    if (inst_callbacks.changeSelectionMode) inst_callbacks.changeSelectionMode($instance, $instance.single_select_mode);
                }
            }

            function initElements() {
                $instance.hidden_content = inst_options.hidden_content;
                $instance.multi_checker = $instance.hidden_content.find(inst_options.multi_checker_selector);
                $instance.submit_button = $instance.hidden_content.find(inst_options.submit_button_selector);
                $instance.checkboxes_list_container = $instance.hidden_content.find(inst_options.checkboxes_list_container);
            }

            function confirmSelect() {
                closePanel($instance.panel);
                if (inst_callbacks.confirm) inst_callbacks.confirm($instance);
            }

            function initEvents() {
                $instance.multi_checker.on("change." + events_namespaces.multi_select_checker, function () {
                    updateSelectMode();
                });
                $instance.submit_button.on("click." + events_namespaces.confirm_select, function () {
                    confirmSelect();
                });
                $instance.checkboxes_list_container.on("change." + events_namespaces.panel_check, inst_options.panel_checkboxes, function () {
                    changeCheckbox(this);
                });
            }


            $instance = {
                // TODO v1
                //single_select_mode: true,
                single_select_mode: false,
                multi_checker: null,
                submit_button: null,
                hidden_content: null,
                checkboxes_list_container: null,
                options: inst_options,
                panel: null,
                openPanel: openPanel
            };

            initElements();
            initEvents();

            return $instance;
        }

        $multi_check_panel = {
            createInst: createInstance
        };
        return $multi_check_panel;
    }();

    var $search_panel = {};

    function scrollToPanelWithAction(callback) {
        if (!$search_panel.flags.now_scrolling) {
            if ($search_panel.elements.panel_container.offset().top > $(document).scrollTop()) {
                $search_panel.flags.now_scrolling = true;
                $("body, html").scrollTo($search_panel.elements.panel_container, 300, {
                    onAfter: function () {
                        // IE8 сучка...
                        setTimeout(function () {
                            if (callback) callback();
                        }, 1);
                        $search_panel.flags.now_scrolling = false;
                    }
                });
            } else {
                if (callback) callback();
            }
        }
    }

    function closePanel(panel_for_close) {
        if (panel_for_close) {
            panel_for_close.close();
        }
    }

    function togglePanel(panel_for_toggle, callback) {
        if (panel_for_toggle) {
            closePanel(panel_for_toggle);
        } else {
            scrollToPanelWithAction(callback);
        }
    }

    function markPanelAsOpened() {
        $search_panel.elements.toggle_status_container.addClass(dynamic_classes.opened);
        $('body').addClass(dynamic_classes.search_panel_opened);
    }

    function markPanelAsClosed() {
        $search_panel.elements.toggle_status_container.removeClass(dynamic_classes.opened);
        $('body').removeClass(dynamic_classes.search_panel_opened);
    }

    function markPanelAsReady() {
        $search_panel.elements.panel_container.addClass(dynamic_classes.search_ready);
    }

    function markPanelAsNotReady() {
        $search_panel.elements.panel_container.removeClass(dynamic_classes.search_ready);
    }

    function setUpResults() {
        $search_panel.elements.search_result_container.show();
        $search_panel.elements.page_data_container.hide();
        $search_panel.elements.remove_after_cars_search.remove();
    }

    function disableModelsButton() {
        $search_panel.elements.select_models_button.addClass("disabled");
        $search_panel.flags.models_enabled = false;
    }

    function setDefaultCity() {
        if ($.cookie('current_cities') === undefined || $.cookie('current_cities') === null) {
            //ставим дефолтный город который определился по ip
            setCheckValue("city", $search_panel.current_data.default_city, true);
        } else {
            var cookie_cities = JSON.parse($.cookie('current_cities'));
            //ставим города сохраненные в сookies
            for (var city_id in cookie_cities) {
                if (cookie_cities.hasOwnProperty(city_id)) {
                    setCheckValue("city", city_id, true);
                }
            }
        }
    }

    function enableModelsButton() {
        $search_panel.elements.select_models_button.removeClass("disabled");
        $search_panel.flags.models_enabled = true;
    }

    function openMainPanel() {
        if (!$search_panel.elements.opened_panel) {
            markPanelAsNotReady();
            markPanelAsOpened();
            closePanel($search_panel.models_multi_check_panel.panel);
            closePanel($search_panel.brands_multi_check_panel.panel);
            c_window_enhanced = $search_panel.elements.opened_panel = fw_WIN.create(
                {
                    without_padding: true,
                    title: "",
                    content: $search_panel.elements.hidden_panel_content,
                    content_block_class: "",
                    overlay_class: "-enhanced-search",
                    window_class: "-enhanced-search",
                    top_offset: 80,
                    content_class: "fw_win_form_content",
                    //lock_page: true
                },
                {
                    onAfterOpen: function () {
                        $('.fw_win_wrapper .j-close-enhanced-window').click(function () {
                            $search_panel.elements.opened_panel.close();
                        });
                    },
                    onBeforeClose: function () {
                        clearDetach($search_panel.elements.hidden_panel_content);
                        return true;
                    },
                    onAfterClose: function () {
                        markPanelAsClosed();
                        $search_panel.elements.opened_panel = null;
                        closePanel($search_panel.models_multi_check_panel.panel);
                        closePanel($search_panel.brands_multi_check_panel.panel);
                    }
                }
            );
        }
    }

    function clearDetach(target) {
        target.detach();
        target.find(".body_scroll_fix").css({width: ""});
    }

    function clearOldResults() {
        $search_panel.current_data.hash = "";
        $search_panel.current_data.min_page = 1;
        $search_panel.current_data.max_page = 1;
        $search_panel.current_data.pages = {};
    }

    function startSearch() {
        sendDataToAnalyze();
    }

    function sendDataToAnalyze() {
        var data = fw_MAMMON.serializeObjSmart($search_panel.elements.hidden_panel_content);
        data.current_url = location.pathname;
        data.brands_and_models = getBrandsAndModelsTree();
        var page_postfix = getPageFromCurrentHash();
        fw_MOJAX.send({
            url: "/ajax/cars/analyzeFilter/",
            data: data,
            user_data: data,
            user_success: function (data, user_data) {
                closePanel($search_panel.elements.opened_panel);
                closePanel($search_panel.models_multi_check_panel.panel);
                closePanel($search_panel.brands_multi_check_panel.panel);
                if (data['need_redirect_to']) {
                    if (!$.empty($search_panel.elements.search_result_container) && window.history && history.pushState) {
                        pushHistoryStateByData(data);
                    } else {
                        location.href = data['need_redirect_to'] + page_postfix;
                        return;
                    }
                }
                if (data['hash']) {
                    var full_hash = data['hash'] + page_postfix;
                    if (window.location.hash != "#search:" + full_hash) {
                        window.location.hash = "search:" + full_hash;
                    } else {
                        analyzeHash();
                    }
                }
            },
            overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
            overlay_to: $()
                .add($search_panel.elements.panel_container)
                .add($search_panel.elements.hidden_panel_content)
                .add($search_panel.elements.hidden_brands_panel_content)
                .add($search_panel.elements.hidden_models_panel_content)
        });
    }

    function fillAjaxBreadcrumbs(breadcrums) {
        var bc_target_container = $(selectors.ajax_breadcrumbs_target_container);
        if ($.empty(breadcrums)) {
            bc_target_container.hide();
        } else {
            bc_target_container.show();
            breadcrums = markBCLastElement(breadcrums);
            breadcrums = addHasDropdownPropertyForMustache(breadcrums);
            breadcrums = addBreadcrumbsCurrentProperyForMustashe(breadcrums);
            bc_target_container.html(
                ich['ajax_breadcrumbs']({
                    breadcrumbs: breadcrums
                })
            );
            INIT.setBCPopOvers();
        }
    }

    function addBreadcrumbsCurrentProperyForMustashe(breadcrums) {
        breadcrums.forEach(function (item, i, arr) {
            if (!$.empty(breadcrums[i]['data']) && !$.empty(breadcrums[i]['data']['current_id']) && !$.empty(breadcrums[i]['data']['drop_down_list'])) {
                var current_id = breadcrums[i]['data']['current_id'];
                breadcrums[i]['data']['drop_down_list'].forEach(function (dropdown_item, dropdown_item_num, dropdown_arr) {
                    if (breadcrums[i]['data']['drop_down_list'][dropdown_item_num]['id'] == current_id) {
                        breadcrums[i]['data']['drop_down_list'][dropdown_item_num]['current_element'] = true;
                    }
                });
            }
        });
        return breadcrums;
    }

    function addHasDropdownPropertyForMustache(breadcrums) {
        breadcrums.forEach(function (item, i, arr) {
            if (!$.empty(breadcrums[i]['data']) && !$.empty(breadcrums[i]['data']['drop_down_list'])) breadcrums[i]['data']['has_dropdown_list'] = true;
        });
        return breadcrums;
    }

    function markBCLastElement(breadcrums) {
        breadcrums.forEach(function (item, i, arr) {
            if (arr.length == (i + 1)) breadcrums[i]['is_last'] = true;
        });
        return breadcrums;
    }

    function getPageFromCurrentHash() {
        var page = $search_panel.current_data.hash.replace(/^.*;page=([0-9]+)$/, "$1");
        return (!$.empty(page) && page != $search_panel.current_data.hash) ? ";page=" + page : "";
    }

    // Запускается при загрузке страницы и при изменении хэша.
    function analyzeHash() {
        //при переходе (например по кнопке назад) из поиска на страницу где не было поиска
        if (location.hash == "") {
            if ($search_panel.current_data.hash != "") {
                location.reload();
            }
            return;
        }
        closePanel($search_panel.elements.opened_panel);
        closePanel($search_panel.models_multi_check_panel.panel);
        closePanel($search_panel.brands_multi_check_panel.panel);
        if (!/^#search:/.test(location.hash)) {
            return;
        }
        var hash_with_page = location.hash.replace(/^#search:/, "");
        var hash = hash_with_page.replace(/;page=[0-9]+$/, "");
        var page = hash_with_page.replace(/^.*;page=([0-9]+)$/, "$1");
        if (page == hash_with_page) {
            page = 1;
        }
        if ($search_panel.current_data.hash != hash) {
            $search_panel.current_data.hash = hash;
            $search_panel.current_data.pages = {};
            fillPanelFromHash();
            getCarsListByHash(page);
        } else if (!$search_panel.current_data.pages[page]) {
            getCarsListByHash(page);
        } else {
            moveToPage(page);
        }
    }

    function pushHistoryStateByData(data) {
        document.title = data['title'];
        history.pushState(
            {
                title: data['title']
            },
            data['title'],
            data['need_redirect_to']
        );
        trackYandexHit(data['need_redirect_to'], data['title']);
        trackGoogleHit(data['need_redirect_to'], data['title']);
    }

    function getCarsListByHash(page) {
        hideBackToSearchPanel();
        fw_MOJAX.send({
            url: "/ajax/cars/getCarsListByFilter/",
            data: {
                hash: $search_panel.current_data.hash,
                page: page
            },
            user_data: {
                page: page
            },
            user_success: function (data, user_data) {
                var page = parseInt(user_data['page']);
                data['cars_list'] = extendCarListByBanners(data['cars_list'], page, data['show_banner']);
                setCarsResult(data, page);
                markPanelAsReady();
                setUpResults();
                addResultsInPanel();
                moveToPage(page);
                fillAjaxBreadcrumbs(data.ajax_full_breadcrumbs);
                //событие на которое можно вешат действия GTM
                $('body').trigger('cars_list_loaded');
                slidePreviewImages();
                sliderInit();
                openCallbackCalltouchForm();
                truncateCarName();
                checkCarListStyle();
                checkBrowsers();

            },
            overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
            overlay_to: $()
                .add($search_panel.elements.result_target_container)
                .add($search_panel.elements.search_result_container)
        });
    }


    function openCallbackCalltouchForm() {
        $(".js_open_callback_calltouch_form").click(function (event) {
            showCallBackCallTouchForm(
                $(this).data('show-uid'),
                $(this).data('calltouch-route-key')
            );
            fw_MAMMON.preventHref(event);
        });
    }

    function extendCarListByBanners(cars_list, page, show_banner) {
        /*carWithBanner - массив индексов машин, после которых нужно отображать баннер rem.ru*/
        //MajorExpertLib::getCarsListByFilter здесь выставляется какому авто дать инфу для динамического баннера
        var carWithBanner = [3, 13],
            listLength = cars_list.list.length;
        if (listLength) {
            (listLength < 4) ? $('#banner-in-search').show() : $('#banner-in-search').hide();
            cars_list.list.forEach(function (car, index) {
                /*если нужно, чтобы индексы учитывались по всем страницам надо к globalIndex добавить  + listLength * (page - 1)*/
                var globalIndex = index + 1,
                    bannerIndex = carWithBanner.indexOf(globalIndex) + 1;
                if (show_banner) {
                    car['banner'] = (bannerIndex && bannerIndex !== 2 && (index + 1 !== listLength));
                    if (listLength >= 15) {
                        car['bannerWithCarInfo'] = (bannerIndex && bannerIndex === 2 && (index + 1 !== listLength));
                    }
                }
            });
        }
        return cars_list;
    }

    function getEmptyContent(container) {
        fw_MOJAX.send({
            to: container,
            url: "/ajax/cars/getEmptySearchResult/",
            overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
            overlay_to: container
        });
    }

    function dropCarsCountTimer() {
        if ($search_panel.counter_timer != null) {
            window.clearTimeout($search_panel.counter_timer);
            $search_panel.counter_timer = null;
        }
    }

    function updateFilerCounter() {
        getFilterCount();
    }

    function getFilterCount() {
        var filters = [
            {
                "selector": 'city[]',
                "type": 'multiselect'
            },
            {
                "selector": 'salon[]',
                "type": 'multiselect'
            },
            // todo: писать все

        ];
        $counter_filter_options = 0;
        $.each(filters, function (index, filer_element) {
            var selected_options = $search_panel.elements.hidden_panel_content
                .find("[data-name-wrapper='" + filer_element.selector + "']")
                .find('input:checked');
            if (selected_options.length > 0) {
                $counter_filter_options = $counter_filter_options + 1;
            }
        });
        return $counter_filter_options;
    }

    function getCarsCount() {
        dropCarsCountTimer();
        $search_panel.counter_timer = window.setTimeout(
            function () {
                getCarsCountByFilter();
            },
            100
        );
    }

    function getCarsCountByFilter() {
        var data = fw_MAMMON.serializeObjSmart($search_panel.elements.hidden_panel_content);
        data.current_url = location.pathname;
        data.brands_and_models = getBrandsAndModelsTree();

        fw_MOJAX.send({
            url: "/ajax/cars/getCarsCountByFilter/",
            data: data,
            user_success: function (data, user_data) {
                setCarsCountResult(data['cars_count']);
            },
            overlay_mode: fw_MOJAX.NONE,
            code: "counter"
        });
    }

    function setCarsCountResult(count) {
        $search_panel.elements.start_search_button.find(".button_label").each(function (index) {
            $(this).text('wht');
        });
        $search_panel.elements.start_search_button.find(".button_label").text("Показать " + count + " авто");
    }

    function addResultsInPanel() {
        var main_list_for_data_results = {
            city: {
                type: 'check'
            }
        };
        var list_for_data_results = {
            price: {
                type: 'interval',
                postfix: ' <i class="icon-rouble"></i>',
                use_format: true
            },
            year: {
                type: 'interval',
                postfix: ' г.',
                use_format: false
            },
            gearbox: {
                type: 'select',
                postfix: ''
            },
            run: {
                type: 'interval',
                postfix: ' км',
                use_format: true
            },
            engine_type: {
                type: 'check'
            },
            volume: {
                type: 'input_interval',
                postfix: ' л.',
                use_format: true
            },
            power: {
                type: 'input_interval',
                postfix: ' л.с.',
                use_format: true
            },
            drive_type: {
                type: 'check'
            },
            body: {
                type: 'check'
            },
            salon_seats: {
                type: 'interval',
                postfix: ' мест',
                use_format: true
            },
            salon: {
                type: 'check'
            },
            spec_program: {
                type: 'check'
            },
            sale_price: {
                type: 'check'
            }
        };
        $search_panel.current_data.selected_items_count = 0;
        $search_panel.elements.selected_items_container.empty();

        for (var main_code in main_list_for_data_results) {
            if (main_list_for_data_results.hasOwnProperty(main_code)) {
                var main_data = main_list_for_data_results[main_code];
                if (main_data['type'] == 'check') {
                    $search_panel.elements.hidden_panel_content.find("input.filter_checkbox[name='" + main_code + "[]']:checked").each(function () {
                        var checkbox = $(this);
                        if (checkbox.is(":checked")) {
                            addSelectedItem(checkbox.closest(".custom_check").find(".check_name").text(), {
                                type: main_data['type'],
                                name: main_code,
                                id: checkbox.val()
                            });
                        }
                    });
                }
            }
        }
        for (var brand_id in $search_panel.current_data.selected_brands) {
            if ($search_panel.current_data.selected_brands.hasOwnProperty(brand_id)) {
                var has_models = false;
                for (var model_id in $search_panel.current_data.selected_brands[brand_id].models) {
                    if ($search_panel.current_data.selected_brands[brand_id].models.hasOwnProperty(model_id)) {
                        has_models = true;
                        addSelectedItem(getBrandName(brand_id) + " " + getModelName(model_id), {
                            type: "model",
                            id: model_id,
                            brand_id: brand_id
                        });
                    }
                }
                if (!has_models) {
                    addSelectedItem(getBrandName(brand_id), {
                        type: "brand",
                        id: brand_id
                    });
                }
            }
        }
        for (var code in list_for_data_results) {
            if (list_for_data_results.hasOwnProperty(code)) {
                var data = list_for_data_results[code];
                if (data['type'] == 'interval') {
                    var from_interval = $search_panel.elements.hidden_panel_content.find("select.filter_select[name='" + code + "[from]']");
                    var to_interval = $search_panel.elements.hidden_panel_content.find("select.filter_select[name='" + code + "[to]']");
                    if (from_interval.val() != "") {
                        var from_name = data['use_format'] ?
                            fw_MAMMON.formattedNumber(from_interval.val()) :
                            from_interval.val();
                        addSelectedItem("от " + from_name + data['postfix'], {
                            type: data['type'],
                            name: code,
                            side: "from"
                        });
                    }
                    var to_name = data['use_format'] ?
                        fw_MAMMON.formattedNumber(to_interval.val()) :
                        to_interval.val();
                    if (to_interval.val() != "") {
                        addSelectedItem("до " + to_name + data['postfix'], {
                            type: data['type'],
                            name: code,
                            side: "to"
                        });
                    }
                } else if (data['type'] == 'select') {
                    var select = $search_panel.elements.hidden_panel_content.find("select.filter_select[name='" + code + "[]']");
                    if (!$.empty(select.val())) {
                        addSelectedItem(select.find("option:selected").text(), {
                            type: "interval",
                            name: code,
                            side: ""
                        });
                    }
                } else if (data['type'] == 'check') {
                    $search_panel.elements.hidden_panel_content.find("input.filter_checkbox[name='" + code + "[]']:checked").each(function () {
                        var checkbox = $(this);
                        if (checkbox.is(":checked")) {
                            addSelectedItem(checkbox.closest(".custom_check").find(".check_name").text(), {
                                type: data['type'],
                                name: code,
                                id: checkbox.val()
                            });
                        }
                    });
                } else if (data['type'] == 'input_interval') {
                    var from_interval = $search_panel.elements.hidden_panel_content.find("input[name='" + code + "[from]']");
                    var to_interval = $search_panel.elements.hidden_panel_content.find("input[name='" + code + "[to]']");
                    if (from_interval.val() != "") {
                        var from_name = data['use_format'] ?
                            fw_MAMMON.formattedNumber(from_interval.val(), 1) :
                            from_interval.val();
                        addSelectedItem("от " + from_name + data['postfix'], {
                            type: data['type'],
                            name: code + "[from]"
                        });
                    }
                    var to_name = data['use_format'] ?
                        fw_MAMMON.formattedNumber(to_interval.val(), 1) :
                        to_interval.val();
                    if (to_interval.val() != "") {
                        addSelectedItem("до " + to_name + data['postfix'], {
                            type: data['type'],
                            name: code + "[to]"
                        });
                    }
                }
            }
        }
        if ($search_panel.current_data.selected_items_count == 0) {
            //addSelectedItem("Все автомобили");
            markPanelAsNotReady();
        }
    }

    function brandWithoutModels(brand_id) {
        if ($search_panel.current_data.selected_brands[brand_id]) {
            for (var model_id in $search_panel.current_data.selected_brands[brand_id].models) {
                if ($search_panel.current_data.selected_brands[brand_id].models.hasOwnProperty(model_id)) {
                    return false;
                }
            }
        }
        return true;
    }

    function getDismissFuncCallback(link) {
        var data = $(link).data('callback-info');
        switch (data['type']) {
            case 'brand':
                setBrandsValue(data['id'], false);
                break;
            case 'model':
                setModelsValue(data['id'], false);
                //Проверяем, если больше нет моделей у этого бренда, то и его отрубаем.
                if (brandWithoutModels(data['brand_id'])) {
                    setBrandsValue(data['brand_id'], false);
                }
                break;
            case 'check':
                setCheckValue(data['name'], data['id'], false);
                break;
            case 'interval':
                setIntervalValue(data['name'], data['side'], "");
                break;
            case 'input_interval':
                setInputValue(data['name'], "");
                break;
        }
        startSearch();
    }

    function getBrandName(brand_id) {
        return $search_panel.data_lib['brands'][brand_id]['name'];
    }

    function getModelName(model_id) {
        return $search_panel.data_lib['models'][model_id]['name'];
    }

    function addSelectedItem(name, data) {
        $search_panel.current_data.selected_items_count++;
        var selected_item = $('<a class="selected_item" href="#" onclick="return false;">').html($("<span>").html(name));
        if (!$.empty(data)) {
            selected_item.append(' <i class="icon-cancel"></i>');
            selected_item.data('callback-info', data);
            selected_item.click(function () {
                getDismissFuncCallback(this);
            });
        }
        $search_panel.elements.selected_items_container.append(selected_item);
    }

    function setCarsResult(data, page) {
        var cars = data['cars_list'];
        var pagination = data['pagination'];
        var rem_ru_domain = data['rem_ru_domain'];
        var cars_template = data['cars_template'];
        var display_type_class = data['display_type_class'];

        if (cars['count'] != undefined) {
            $search_panel.elements.result_count_text.text(fw_MAMMON.formattedNumber(cars['count']) + " " + fw_MAMMON.morph(
                cars['count'],
                "автомобиль",
                "автомобиля",
                "автомобилей"
            ));
        }
        if ($.empty(cars['list'])) {
            $search_panel.elements.search_result_container.addClass("empty_result");
            $search_panel.elements.result_target_container.html(
                '<div class="page_content">' +
                '<p>К сожалению по Вашему запросу ничего не найдено. Попробуйте <a class="clear_search" href="#" onclick="return false;">очистить поиск</a> или удалить лишние параметры выше.</p>' +
                '	<div class="empty_data_container"></div>' +
                '</div>');
            $search_panel.elements.result_target_container.find(".clear_search").click(function () {
                clearPanel();
                startSearch();
            });
            $search_panel.elements.result_pagination_post_load_container.empty();
            getEmptyContent($search_panel.elements.result_target_container.find(".empty_data_container"));
        } else {
            $search_panel.elements.search_result_container.removeClass("empty_result");
            // Анализируем страницу и думаем, куда вставить результат.
            var insert_type = processPageAndGetInsertType(page);
            // Обрабатываем паджинатор после списка
            $search_panel.elements.result_pagination_post_load_container.html(
                ich['post_pagination']({
                    pagination: pagination,
                    next_page: $search_panel.current_data.max_page + 1,
                    show_next_page: pagination['max_page'] > $search_panel.current_data.max_page,
                    has_pagination: pagination['max_page'] > 1
                })
            );
            $search_panel.elements.result_target_container[insert_type](
                ich['cars_list']({
                    cars: cars['list'],
                    cars_template: cars_template,
                    display_type_class: display_type_class,
                    page: page,
                    rem_ru_domain: rem_ru_domain,
                    show_page: pagination['max_page'] > 1 && pagination['current'] > 1,
                    max_page: pagination['max_page'],
                    format_number: function () {
                        return function (text, render) {
                            return fw_MAMMON.formattedNumber(render(text));
                        }
                    },
                    format_number_float: function () {
                        return function (text, render) {
                            return fw_MAMMON.formattedNumber(render(text), 1);
                        }
                    }
                })
            );
        }
    }

    function processPageAndGetInsertType(page) {
        var insertType = "html";
        if ($.empty($search_panel.current_data.pages)) {
            $search_panel.current_data.min_page = page;
            $search_panel.current_data.max_page = page;
        } else {
            if (page == $search_panel.current_data.max_page + 1) {
                insertType = "append";
                $search_panel.current_data.max_page = page;
            } else if (page == $search_panel.current_data.min_page - 1) {
                insertType = "prepend";
                $search_panel.current_data.min_page = page;
            } else {
                $search_panel.current_data.min_page = page;
                $search_panel.current_data.max_page = page;
                $search_panel.current_data.pages = {};
            }
        }
        $search_panel.current_data.pages[page] = page;
        // Обрабатываем паджинатор до списка
        if ($search_panel.current_data.min_page - 1 > 0) {
            $search_panel.elements.result_pagination_pre_load_container.show();
            $search_panel.elements.result_pagination_pre_load_page_text.text($search_panel.current_data.min_page - 1);
        } else {
            $search_panel.elements.result_pagination_pre_load_container.hide();
        }
        return insertType;
    }

    function showNextPage() {
        showPage($search_panel.current_data.max_page + 1);
    }

    function showPrevPage() {
        showPage($search_panel.current_data.min_page - 1);
    }

    function showPage(page) {
        var new_hash = location.hash.replace(/;page=[0-9]+/, "");
        if (page == 1) {
            location.hash = new_hash;
        } else {
            location.hash = new_hash + ";page=" + page;
        }
    }

    function changeWrapper(wrapper_button) {
        var target_button_class = $(wrapper_button).data('wrapper-button-class');
        var old_display_type = $($search_panel.elements.wrappers.filter(".selected")[0]).data('result-list-class');
        $search_panel.elements.wrappers.filter(".selected").removeClass("selected");
        $search_panel.elements.wrappers.filter("." + target_button_class).addClass("selected");
        var new_display_type = $(wrapper_button).data('result-list-class');
        var wrapper_type = $(wrapper_button).data('wrapper-type');
        changeDisplayCars(old_display_type, new_display_type);
        setNewWrapper(wrapper_type);
    }

    function changeDisplayCars(old_type, new_type) {
        $search_panel.elements.display_type_target.removeClass(old_type);
        $search_panel.elements.display_type_target.addClass(new_type);
        $search_panel.elements.cars_list = $(".cars-list");
        $search_panel.elements.cars_list.removeClass(old_type);
        $search_panel.elements.cars_list.addClass(new_type);
    }

    function setNewWrapper(type) {
        fw_MOJAX.send({
            url: "/ajax/cars/setWrapper/",
            data: {
                type: type
            },
            overlay_mode: fw_MOJAX.NONE
        });
    }

    function changeSortOrder(order_button, collection, callback) {
        var class_for_direction = {
            '1': "icon-sort-alt-down",
            '-1': "icon-sort-alt-up"
        };
        var type = $(order_button).data('type');
        var direction = $(order_button).data('direction');
        if ($(order_button).hasClass("selected")) {
            direction = direction * -1;
            $(order_button).data('direction', direction);
            $(order_button).find("span").attr('class', class_for_direction[direction]);
        } else {
            collection.filter(".selected").removeClass("selected");
            $(order_button).addClass("selected");
        }
        setNewSortOrder(type, direction, callback);
    }

    function setNewSortOrder(type, direction, callback) {
        fw_MOJAX.send({
            url: "/ajax/cars/setSortOrder/",
            data: {
                type: type,
                direction: direction
            },
            user_data: {
                callback: callback
            },
            user_success: function (data, user_data) {
                if (user_data.callback) user_data.callback();
            },
            overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
            overlay_to: $()
                .add($search_panel.elements.result_target_container)
                .add($search_panel.elements.search_result_container)
        });
    }

    function processFavorites(favorites_button) {
        var uid = $(favorites_button).data('uid');
        var new_state = $(favorites_button).data('state') * -1;
        $(favorites_button).data('state', new_state);
        $(favorites_button).trigger('gtm_favorites_set_' + (new_state > 0 ? 'true' : 'false'));
        changeStateForCars(uid, new_state);
        saveFavoritesResult(uid, new_state);
    }

    function changeStateForCars(uid, state) {
        var changeFuncName = (state == -1) ? "removeClass" : "addClass";
        $(".car_uid_" + uid)[changeFuncName]("in_favorites");
        var count = parseInt($($search_panel.elements.favorites_count[0]).text());
        if ($.empty(count)) count = 0;
        var new_count = count + state;
        if (new_count == 0) {
            new_count = "";
        }
        $search_panel.elements.favorites_count.text(new_count);
        //if(new_count > 0){
        //	$(".header .favorite-button").removeClass("is_empty");
        //}
        //else{
        //	$(".header .favorite-button").addClass("is_empty");
        //}
    }

    function saveFavoritesResult(uid, state) {
        fw_MOJAX.send({
            url: "/ajax/cars/saveFavorites/",
            data: {
                uid: uid,
                state: state
            },
            overlay_mode: fw_MOJAX.NONE
        });
    }

    function getBrandsAndModelsTree() {
        var tree = {
            root: {
                childs: {}
            }
        };
        for (var brand_id in $search_panel.current_data.selected_brands) {
            if ($search_panel.current_data.selected_brands.hasOwnProperty(brand_id)) {
                var brand_key = "b_" + brand_id;
                tree.root.childs[brand_id] = brand_key;
                tree[brand_key] = {
                    type: "brand_id",
                    value: brand_id,
                    childs: {}
                };

                for (const [key, value] of Object.entries(
                    $search_panel.current_data.selected_brands[brand_id].models)
                    ) {
                    var model_key = "m_" + value;
                    tree[brand_key].childs[value] = model_key;
                    tree[model_key] = {
                        type: "model_id",
                        value: value,
                        childs: {}
                    };
                }
            }
        }

        var tree1 = {
            root: {
                childs: {}
            }
        };

        $.each($search_panel.elements.hidden_panel_content.find(".j-enhanced-brands input[name='brand[]']"), function (key, brand) {
            if ($(brand).prop('checked') == true) {

                var brand_id = $(brand).attr('value');
                var brand_key = "b_" + brand_id;
                tree1.root.childs[brand_id] = brand_key;
                tree1[brand_key] = {
                    type: "brand_id",
                    value: brand_id,
                    childs: {}
                };

                $.each($search_panel.elements.hidden_panel_content
                    .find(".j-enhanced-models fieldset[data-brand-id='" +
                        brand_id + "']  input[name='model[]']"), function (key, model) {
                    if ($(model).prop('checked') == true) {


                        var model_id = $(model).attr('value');
                        var model_key = "m_" + model_id;
                        tree1[brand_key].childs[model_id] = model_key;
                        tree1[model_key] = {
                            type: "model_id",
                            value: model_id,
                            childs: {}
                        };

                    }
                });

            }
        });

        tree = tree1;

        return tree;
    }

    function moveToPage(page) {
        if (page == 1) {
            $("html, body").scrollTo(".search_result_container", 300, {offset: {top: -60}});
        } else {
            $("html, body").scrollTo(".pagination_scroll_to_" + page, 300, {offset: {top: -120}});
        }
    }

    function clearPanel() {
        $search_panel.elements.hidden_panel_content.find("input[type=text]").val('').change();
        $search_panel.elements.hidden_panel_content.find("input.filter_checkbox:checked").prop('checked', false).change();
        $search_panel.elements.hidden_panel_content.find("select.filter_select").ikSelect('select', "").change();
        clearBrandsAndModels();
    }

    function clearBrandsAndModels() {
        $search_panel.elements.hidden_brands_panel_content.find("input" + selectors.panel_checkboxes).prop('checked', false).change();
    }

    function fillPanelFromHash() {
        //Заполнение панели из url
        // Возможно не стоит этог оделать в будущем.
        clearPanel();
        var hash_parsed = fw_MAMMON.trimCustomCharacters($search_panel.current_data.hash, "#").split(";");
        var intervals = [];
        var inputs = [];
        var checks = [];
        var brands = [];
        var models = [];
        for (var i = 0; i < hash_parsed.length; i++) {
            var value_str = hash_parsed[i];
            if (/^brands_and_models/.test(value_str)) {
                var values = value_str.replace(/^.*\[(.*)].*/, "$1").split(",");
                for (var v = 0; v < values.length; v++) {
                    var brand_data = values[v].split(":");
                    brands.push({
                        value: brand_data[0]
                    });
                    if (brand_data.length == 2) {
                        var model_values = fw_MAMMON.trimCustomCharacters(brand_data[1], "()").split(".");
                        for (var mv = 0; mv < model_values.length; mv++) {
                            models.push({
                                value: model_values[mv]
                            });
                        }
                    }
                }
            } else if (/^(city|body|salon\[|spec_program|engine_type|drive_type|sale_price)/.test(value_str)) {
                var name = value_str.replace(/^(.*)\[.*]$/, "$1");
                var values = value_str.replace(/^.*\[(.*)]$/, "$1").split(",");
                for (var v = 0; v < values.length; v++) {
                    if (name == 'spec_program' && values[v] == SEARCH_PANEL.current_data.major_expert_select){
                        var select = SEARCH_PANEL.elements.hidden_panel_content.find("[name=major_expert_selected]");
                        var select_main_menu =  SEARCH_PANEL.elements.panel_container.find('[name=major_expert_selected_main]');
                        select.prop('checked', true);
                        select_main_menu.prop('checked', true);
                    }
                    checks.push({
                        name: name,
                        value: values[v]
                    });
                }
            } else if (/^(run|year|salon_seats|price)/.test(value_str)) {
                var name = value_str.replace(/^(.*)\[.*]$/, "$1");
                var values = value_str.replace(/^.*\[(.*)]$/, "$1").split(",");
                for (var v = 0; v < values.length; v++) {
                    var data = values[v].split(":");
                    if (data.length == 2) {
                        intervals.push({
                            name: name,
                            side: data[0],
                            value: data[1]
                        });
                    }
                }
            } else if (/^(volume|power)/.test(value_str)) {
                var name = value_str.replace(/^(.*)\[.*]$/, "$1");
                var values = value_str.replace(/^.*\[(.*)]$/, "$1").split(",");
                for (var v = 0; v < values.length; v++) {
                    var data = values[v].split(":");
                    if (data.length == 2) {
                        inputs.push({
                            name: name + "[" + data[0] + "]",
                            value: data[1]
                        });
                    }
                }
            } else if (/^(gearbox)/.test(value_str)) {
                var name = value_str.replace(/^(.*)\[.*]$/, "$1");
                var value = value_str.replace(/^.*\[(.*)]$/, "$1");
                intervals.push({
                    name: name,
                    side: "",
                    value: value
                });
            }
        }

        for (var i = 0; i < inputs.length; i++) {
            setInputValue(inputs[i]['name'], inputs[i]['value']);
        }
        for (var i = 0; i < checks.length; i++) {
            setCheckValue(checks[i]['name'], checks[i]['value'], true);
            if (checks[i]['name'] == 'spec_program') {
                setCheckValue('spec_program_2', checks[i]['value'], true);
            }
        }
        for (var i = 0; i < intervals.length; i++) {
            setIntervalValue(intervals[i]['name'], intervals[i]['side'], intervals[i]['value']);
        }
        for (var i = 0; i < brands.length; i++) {
            setBrandsValue(brands[i]['value'], true);
        }
        for (var i = 0; i < models.length; i++) {
            setModelsValue(models[i]['value'], true);
        }
    }

    function setCheckValue(name, id, is_checked) {
        $search_panel.elements.hidden_panel_content.find("input[name='" + name + "[]'][value='" + id + "']").prop('checked', is_checked).change();
        var select = $search_panel.elements.hidden_panel_content
            .find(".checkselect[data-name-wrapper='" + name + "[]']");
        select.closest('.checkselect').checkselect('setValue', {value: id});
    }

    function setIntervalValue(name, side, id) {
        $search_panel.elements.hidden_panel_content.find("select[name='" + name + "[" + side + "]']").ikSelect('select', id).change();
        var select = SEARCH_PANEL.elements.hidden_panel_content
            .find(".checkselect[data-name-wrapper='" + name + "\\[" + side + "\\]']");
        select.closest('.checkselect').checkselect('setDisableAll', {with_trigger: false});
        select.closest('.checkselect').checkselect('setValue', {value: id, with_trigger: true});
    }

    function setBrandsValue(id, is_checked) {
        $search_panel.elements.hidden_brands_panel_content.find("input[name='brand[]'][value='" + id + "']").prop('checked', is_checked).change();
    }

    function setModelsValue(id, is_checked) {
        $search_panel.elements.hidden_models_panel_content.find("input[name='model[]'][value='" + id + "']").prop('checked', is_checked).change();
    }

    function setInputValue(name, value) {
        $search_panel.elements.hidden_panel_content.find("input[name='" + name + "']").val(value).change();
        $search_panel.elements.hidden_panel_content.find("input[name='" + name + "']").addClass('-checked');
    }

    function checkDis(input) {
        var container = $(input).closest(selectors.custom_check_container);
        if ($(input).is(":disabled")) {
            container.addClass(dynamic_classes.disabled);
        } else {
            container.removeClass(dynamic_classes.disabled);
        }
    }

    function checkCheck(input) {
        var container = $(input).closest(selectors.custom_check_container);
        if ($(input).is(":checked")) {
            container.addClass(dynamic_classes.checked);
        } else {
            container.removeClass(dynamic_classes.checked);
        }
    }

    function initBrandsPanel() {
        $search_panel.brands_multi_check_panel = MULTI_CHECK_PANEL.createInst(
            {
                hidden_content: $search_panel.elements.hidden_brands_panel_content,
                panel_title: "Выбор бренда",
                multi_checker_selector: selectors.brands_multi_select_checker,
                submit_button_selector: selectors.brands_confirm_button,
                checkboxes_list_container: selectors.checkboxes_list_container,
                panel_checkboxes: selectors.panel_checkboxes
            },
            {
                beforeOpen: function (inst) {
                    $search_panel.elements.select_brands_button.addClass("active");
                    $search_panel.elements.hidden_panel_content.find(selectors.brands_button).addClass("active");
                    closePanel($search_panel.models_multi_check_panel.panel);
                },
                afterClose: function (inst) {
                    $search_panel.elements.select_brands_button.removeClass("active");
                    $search_panel.elements.hidden_panel_content.find(selectors.brands_button).removeClass("active");
                },
                check: function (inst, value) {
                    addToCurrentBrands(value);
                    var brand_el = $search_panel.elements.hidden_panel_content.find('.j-enhanced-brands');
                    brand_el.checkselect('setValue', {value: value, with_trigger: false});
                    changeModels($search_panel.elements.hidden_panel_content.find('.j-enhanced-models'));
                },
                unCheck: function (inst, value) {
                    removeFromCurrentBrands(value);
                    $search_panel.elements.hidden_panel_content.find('.j-enhanced-brands')
                        .checkselect('setValueOff', value);
                },
                confirm: function (inst) {
                },
                changeSelectionMode: function (inst, new_mode) {

                    $search_panel.models_multi_check_panel.multi_checker.prop('checked', !new_mode).change();
                }
            }
        );
    }

    function initIkSelect(additional_class) {
        additional_class = additional_class || "";
        // Ставим установки для селектора
        $.ikSelect.extendDefaults({
            dynamicWidth: false,
            ddFullWidth: true,
            autoWidth: false,

            onShow: function (inst) {

                inst.$link.addClass("active");

                if (typeof ($search_panel.brands_multi_check_panel.panel) != "undefined"
                    && $search_panel.brands_multi_check_panel.panel !== null) {
                    $search_panel.brands_multi_check_panel.panel.close();
                }

                if (typeof ($search_panel.models_multi_check_panel.panel) != "undefined"
                    && $search_panel.models_multi_check_panel.panel !== null) {
                    $search_panel.models_multi_check_panel.panel.close();
                }

                function fixDropDownPosition() {
                    if (inst.$wrapper.is(":visible")) {
                        var dropdownOffset = inst.getDropDownPosition();
                        inst.$dropdown.css({
                            left: dropdownOffset.left,
                            top: dropdownOffset.top
                        });
                    } else {
                        inst.hideDropdown();
                    }
                }

                // Тут запускаем систему слежения за скроллом
                $(window).on('resize.ikFixDropdown', function () {
                    inst.hideDropdown();
                });
                $(window).on('scroll.ikFixDropdown resize.ikFixDropdown', function () {
                    fixDropDownPosition();
                });
                $(".fw_win_wrapper").on('scroll.ikFixDropdown', function () {
                    fixDropDownPosition();
                });
            },
            onHide: function (inst) {
                inst.$link.removeClass("active");
                // Тут вырубаем систему слежения за скроллом
                $(window).off('.ikFixDropdown');
                $(".fw_win_wrapper").off('.ikFixDropdown');
            },
            syntax: '' +
                '<a href="#" onclick="return false;" class="super_button selector_btn block_button ' + additional_class + ' ik_select_link">' +
                '<span class="gradient"></span>' +
                '<span class="button_label no_icon ik_select_link_text"></span>' +
                '<span class="arrow"></span>' +
                '</a>' +
                '<div class="ik_select_dropdown">' +
                '	<div class="ik_select_list"></div>' +
                '</div>'
        });
    }


    function initModelsPanel() {
        $search_panel.models_multi_check_panel = MULTI_CHECK_PANEL.createInst(
            {
                hidden_content: $search_panel.elements.hidden_models_panel_content,
                panel_title: "Выбор модели",
                multi_checker_selector: selectors.models_multi_select_checker,
                submit_button_selector: selectors.models_confirm_button,
                checkboxes_list_container: selectors.checkboxes_list_container,
                panel_checkboxes: selectors.panel_checkboxes
            },
            {
                beforeOpen: function (inst) {
                    $search_panel.elements.select_models_button.addClass("active");
                    $search_panel.elements.hidden_panel_content.find(selectors.models_button).addClass("active");
                    closePanel($search_panel.brands_multi_check_panel.panel);
                },
                afterClose: function (inst) {
                    $search_panel.elements.select_models_button.removeClass("active");
                    $search_panel.elements.hidden_panel_content.find(selectors.models_button).removeClass("active");
                },
                check: function (inst, value) {

                    addModelToCurrentBrands(value);
                    $search_panel.elements.hidden_panel_content
                        .find('.j-enhanced-models').checkselect('setValue', {value: value});
                    //enhancedChangeModals($search_panel.elements.hidden_panel_content.find('.j-enhanced-models'));
                },
                unCheck: function (inst, value) {
                    removeModelFromCurrentBrands(value);

                    $search_panel.elements.hidden_panel_content
                        .find('.j-enhanced-models').checkselect('setValueOff', value);
                },
                confirm: function (inst) {
                    //
                },
                changeSelectionMode: function (inst, new_mode) {
                    $search_panel.brands_multi_check_panel.multi_checker.prop('checked', !new_mode).change();
                }
            }
        );
    }

    function addModelToCurrentBrands(model_id) {
        var brand_id = $search_panel.data_lib['models'][model_id]['brand_id'];
        if ($search_panel.current_data.selected_brands[brand_id]) {
            $search_panel.current_data.selected_brands[brand_id]['models'][model_id] = model_id;
            displayModelsButtonText();
        }
    }

    ///
    function addToCurrentBrands(brand_id) {
        if (!$search_panel.current_data.selected_brands[brand_id]) {
            $search_panel.current_data.selected_brands[brand_id] = {
                brand_id: brand_id,
                models: {}
            };
            addBrandGroupToModels(brand_id);
            displayBrandsButtonText();
        }
    }

    function removeModelFromCurrentBrands(model_id) {
        var brand_id = $search_panel.data_lib['models'][model_id]['brand_id'];
        if ($search_panel.current_data.selected_brands[brand_id] &&
            $search_panel.current_data.selected_brands[brand_id]['models'][model_id]) {
            delete $search_panel.current_data.selected_brands[brand_id]['models'][model_id];
            displayModelsButtonText();
        }
    }

    function removeFromCurrentBrands(brand_id) {
        if ($search_panel.current_data.selected_brands[brand_id]) {
            removeBrandGroupFromModels(brand_id);
            delete $search_panel.current_data.selected_brands[brand_id];
            displayBrandsButtonText();
            displayModelsButtonText();
        }
    }

    function cityIsChanged() {
        //Перерисовываем бренды и модели
        //generateBrandsListContent();
        setCitiesToCookie();
    }

    function processCityChange(input, vals) {
        var city_id = vals.val_selected;//$(input).val();
        if ($(vals.element_selected).is(":checked")) {
            $search_panel.current_data.selected_cities[city_id] = 1;
        } else if ($search_panel.current_data.selected_cities[city_id]) {
            delete $search_panel.current_data.selected_cities[city_id];
        }
        //Анализируем
        $search_panel.current_city_dependence_id = 0;
        var count = 0;
        for (var current_city_id in $search_panel.current_data.selected_cities) {
            if ($search_panel.current_data.selected_cities.hasOwnProperty(current_city_id)) {
                $search_panel.current_city_dependence_id = (count == 0) ? current_city_id : 0;
                count++;
            }
        }
        cityIsChanged();
    }

    function setCitiesToCookie() {
        var val = {};
        $search_panel.elements.hidden_panel_content.find("[data-name-wrapper=city\\[\\]] input").each(function () {
            if ($(this).prop('checked')) {
                var name = $(this).val();
                val[name] = 1;
            }
        });
        $.cookie('current_cities', JSON.stringify(val), {expires: 7, path: "/"});
    }

    function displayBrandsButtonText() {
        var last_name = "";
        var brands_count = 0;
        var display_text = "Марка";
        for (var brand_id in $search_panel.current_data.selected_brands) {
            if ($search_panel.current_data.selected_brands.hasOwnProperty(brand_id)) {
                brands_count++;
                last_name = $search_panel.data_lib['brands'][brand_id]['name'];
            }
        }
        if (brands_count == 1) {
            display_text = last_name
        } else if (brands_count > 1) {
            display_text = brands_count + " " + (fw_MAMMON.morph(brands_count, "марка", "марки", "марок"))
        }

        if (brands_count > 0) {
            $search_panel.elements.select_brands_button.addClass("selector_btn_has_value");
        } else {
            $search_panel.elements.select_brands_button.removeClass("selector_btn_has_value");
        }

        $search_panel.elements.select_brands_button_text.text(display_text);
    }

    function displayModelsButtonText() {
        var last_name = "";
        var brands_count = 0;
        var models_count = 0;
        var display_text = "Модель";
        for (var brand_id in $search_panel.current_data.selected_brands) {
            if ($search_panel.current_data.selected_brands.hasOwnProperty(brand_id)) {
                brands_count++;
                for (var model_id in $search_panel.current_data.selected_brands[brand_id]['models']) {
                    if ($search_panel.current_data.selected_brands[brand_id]['models'].hasOwnProperty(model_id)) {
                        models_count++;
                        last_name = $search_panel.data_lib['models'][model_id]['name'];
                    }
                }
            }
        }
        if (brands_count == 1 && models_count == 1) {
            display_text = last_name
        } else if (brands_count > 1 || models_count > 1) {
            display_text = models_count + " " + (fw_MAMMON.morph(models_count, "модель", "модели", "моделей"))
        }

        if (models_count > 0) {
            $search_panel.elements.select_models_button.addClass("selector_btn_has_value");
        } else {
            $search_panel.elements.select_models_button.removeClass("selector_btn_has_value");
        }

        $search_panel.elements.select_models_button_text.text(display_text);
    }

    function getCurrentBIC() {
        return $search_panel.data_lib['bam_in_cities'][$search_panel.current_city_dependence_id];
    }

    function getCurrentMIBIC(brand_id) {
        return $search_panel.data_lib['bam_in_cities'][$search_panel.current_city_dependence_id][brand_id]['models'];
    }

    function getMustacheBrands() {
        var bic_list = getCurrentBIC();
        var brands = [];
        for (var brand_id in bic_list) {
            if (bic_list.hasOwnProperty(brand_id)) {
                var brand = $.extend({}, $search_panel.data_lib['brands'][brand_id]);
                brand['all_cars_count'] = bic_list[brand_id]['count'];
                brands[brands.length] = brand;
            }
        }
        brands.sort(function (a, b) {
            return a['name'] >= b['name'] ? 1 : -1;
        });
        return brands;
    }

    // Функция будет сортировать список брендов
    function generateBrandsListContent() {
        var last_selected_brands_and_models = $.extend({}, $search_panel.current_data.selected_brands);
        clearBrandsAndModels();
        $search_panel.brands_multi_check_panel.checkboxes_list_container.html(
            ich['brands_list']({
                brands: getMustacheBrands()
            })
        );
        // Выставляем выбранные ранее бренды и модели
        for (var brand_id in last_selected_brands_and_models) {
            if (last_selected_brands_and_models.hasOwnProperty(brand_id)) {
                setBrandsValue(brand_id, true);
                for (var model_id in last_selected_brands_and_models[brand_id].models) {
                    if (last_selected_brands_and_models[brand_id].models.hasOwnProperty(model_id)) {
                        setModelsValue(model_id, true);
                    }
                }
            }
        }

        initBrandsSorter();
    }

    function getMustacheModelsByBrandId(brand_id) {
        var mibic_list = getCurrentMIBIC(brand_id);
        var models = [];
        for (var model_id in mibic_list) {
            if (mibic_list.hasOwnProperty(model_id)) {
                var model = $.extend({}, $search_panel.data_lib['models'][model_id]);
                model['all_cars_count'] = mibic_list[model_id]['count'];
                models[models.length] = model;
            }
        }
        models.sort(function (a, b) {
            return a['name'] >= b['name'] ? 1 : -1;
        });
        return models;
    }

    function removeBrandGroupFromModels(brand_id) {
        $search_panel.models_multi_check_panel.checkboxes_list_container.find("#brand_group_" + brand_id).remove();
        if ($search_panel.models_multi_check_panel.checkboxes_list_container.find(".brand_group").length == 0) {
            disableModelsButton();
        }
    }

    function addBrandGroupToModels(brand_id) {
        enableModelsButton();
        $search_panel.models_multi_check_panel.checkboxes_list_container.append(
            ich['models_list']({
                brand: $search_panel.data_lib['brands'][brand_id],
                models: getMustacheModelsByBrandId(brand_id)
            })
        );
        $search_panel.sortContainers.models_list.sort();
    }

    $search_panel = {
        //здесь то что отдается!!!

        brands_multi_check_panel: null,
        models_multi_check_panel: null,
        sortContainers: {
            brands_list: null,
            models_list: null
        },
        elements: {
            panel_container: null,
            open_close_button: null,
            change_search_button: null,
            hidden_panel_content: null, // большая панелька поиска SEARCH_PANEL.elements.hidden_panel_content
            hidden_brands_panel_content: null,
            hidden_models_panel_content: null,
            toggle_status_container: null,
            selected_items_container: null,
            start_search_button: null,
            select_brands_button: null,
            select_brands_button_text: null,
            select_models_button: null,
            select_models_button_text: null,
            opened_panel: null,
            opened_brands_panel: null,
            opened_models_panel: null,
            search_result_container: null,
            page_data_container: null,
            remove_after_cars_search: null,
            result_count_text: null,
            result_target_container: null,
            result_pagination_pre_load_container: null,
            result_pagination_pre_load_button: null,
            result_pagination_pre_load_page_text: null,
            result_pagination_post_load_container: null,
            result_pagination_post_load_button: null,
            result_pagination_post_load_page_text: null,
            search_params_container: null,
            sort_orders: null,
            system_sort_orders: null,
            wrappers: null,
            display_type_target: null,
            favorites_count: null,
            new_search_action: null,
            back_panel: null
        },
        flags: {
            now_scrolling: false,
            models_enabled: true
        },
        counter_timer: null,
        current_city_dependence_id: 0,
        // Все данные панели фильтров будут тут.
        data_lib: {},
        current_data: {
            selected_cities: {},
            selected_brands: {},
            pages: {},
            hash: "",
            min_page: -1,
            max_page: -1,
            selected_items_count: 0,
            default_city: 1,
            major_expert_select: 81
        },
        // Функции
        loadDataLib: function () {
            fw_MOJAX.send({
                url: '/ajax/cars/getSearchFormFilterData/',
                user_success: function (json_data) {
                    $search_panel.fillDataLib(json_data['filter_data']);
                },
                overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
                overlay_to: $()
                    .add($search_panel.elements.panel_container)
                    .add($search_panel.elements.hidden_panel_content)
                    .add($search_panel.elements.hidden_brands_panel_content)
                    .add($search_panel.elements.hidden_models_panel_content)
            });
        },
        fillDataLib: function (filter_data_json) {
            $search_panel.data_lib = filter_data_json;
            initSearchPanelData();
        },
        redirectByHistory: function (data) {
            pushHistoryStateByData(data);
            analyzeHash();
        }
    };

    function setCheckBoxes() {
        $(selectors.custom_check_inputs).each(function () {
            checkCheck(this);
            checkDis(this);
        });
        $search_panel.elements.hidden_panel_content.on("change." + events_namespaces.custom_check, selectors.custom_check_inputs, function () {
            checkCheck(this);
        });
        $search_panel.elements.hidden_brands_panel_content.on("change." + events_namespaces.custom_check, selectors.custom_check_inputs, function () {
            checkCheck(this);
        });
        $search_panel.elements.hidden_models_panel_content.on("change." + events_namespaces.custom_check, selectors.custom_check_inputs, function () {
            checkCheck(this);
        });
    }

    function setMainEvents() {
        $('.open_close_panel').on('click', function () {
            openMainPanel();
        });

        $search_panel.elements.start_search_button.on("click." + events_namespaces.start_search, function () {
            clearOldResults();
            startSearch();
        });
        $search_panel.elements.select_brands_button.on("click." + events_namespaces.open_close_panel, function () {
            togglePanel($search_panel.brands_multi_check_panel.panel, $search_panel.brands_multi_check_panel.openPanel);
        });
        $search_panel.elements.select_models_button.on("click." + events_namespaces.open_close_panel, function () {
            if ($search_panel.flags.models_enabled) {
                togglePanel($search_panel.models_multi_check_panel.panel, $search_panel.models_multi_check_panel.openPanel);
            }
        });
        $search_panel.elements.panel_container.find('[name=major_expert_selected]').on('change', function (event, sel){
            var select = $search_panel.elements.hidden_panel_content
                .find(".checkselect[data-name-wrapper='spec_program[]']");
            var select_main_menu =  SEARCH_PANEL.elements.panel_container.find('[name=major_expert_selected_main]');
            if ($(this).prop('checked') == true) {
                select.checkselect('setValue', {value: SEARCH_PANEL.current_data.major_expert_select, with_trigger: false});
                select_main_menu.prop('checked', true);
            }else {
                select.checkselect('setValueOff', SEARCH_PANEL.current_data.major_expert_select);
                select_main_menu.prop('checked', false);
            }
        });

        $search_panel.elements.panel_container.find('.checkselect').on('changeCheckSelect', function (event, selected) {// селекты в большой панельке
            var select_name = $(this).data('name-wrapper');
            switch (select_name) {
                case 'spec_program[]':
                    var select = SEARCH_PANEL.elements.hidden_panel_content.find("[name=major_expert_selected]");
                    var select_main_menu =  SEARCH_PANEL.elements.panel_container.find('[name=major_expert_selected_main]');
                    $('input[name=spec_program\\[\\]]').each(function(cf, el){
                        if ($(el).val() == SEARCH_PANEL.current_data.major_expert_select){
                            if ($(el).prop('checked')) {
                                select.prop('checked', true);
                                select_main_menu.prop('checked', true);
                            } else {
                                value = "''";
                                select.prop('checked', false);
                                select.closest('.custom_check').removeClass('checked');//глюк
                                select_main_menu.prop('checked', false);
                            }
                        }
                    })
                    break;
                case 'gearbox[]':
                case 'year[from]':
                case 'price[to]':
                case 'drive_type[]':
                    if ($(this).find('input:checked').length == 0) {
                        value = "''";
                    } else {
                        var value = $(this).find('input:checked').attr('value');
                    }
                    SEARCH_PANEL.elements.panel_container.find("[name='" + select_name + "'] option[value=" + value + "]").prop('selected', true);
                    SEARCH_PANEL.elements.panel_container.find("select[name='" + select_name + "']").ikSelect('reset');
                    break;
                case 'brand[]':
                    if (typeof selected['element_selected'] !== "undefined") {
                        var val = selected['element_selected'].attr('value');
                        if (selected['element_selected'].prop('checked')) {
                            $search_panel.brands_multi_check_panel.hidden_content.find("input[name='brand[]'][value=" + val + "]").prop('checked', true);
                            addToCurrentBrands(val)

                        } else {
                            $search_panel.brands_multi_check_panel.hidden_content.find("input[name='brand[]'][value=" + val + "]").prop('checked', false);
                            removeFromCurrentBrands(val);

                        }
                    }
                    if (typeof selected['clear_all'] !== "undefined" && selected['clear_all']) {
                        $search_panel.brands_multi_check_panel.hidden_content.find("input:checked[name='brand[]']").each(function (index, element) {
                            $(element).prop('checked', false);
                            removeFromCurrentBrands($(element).attr('value'));
                            $(element).closest('.custom_check.extended_check').removeClass('checked');//не срабатывает checked
                        });
                    }
                    break;
                case 'model[]':
                    if (typeof selected['element_selected'] !== "undefined") {
                        var val = selected['element_selected'].attr('value');
                        if (selected['element_selected'].prop('checked')) {
                            $search_panel.models_multi_check_panel.hidden_content.find("input[name='model[]'][value=" + val + "]").prop('checked', true);
                            addModelToCurrentBrands(val);

                        } else {
                            $search_panel.models_multi_check_panel.hidden_content.find("input[name='model[]'][value=" + val + "]").prop('checked', false);
                            removeModelFromCurrentBrands(val);

                        }
                    }
                    if (typeof selected['clear_all'] !== "undefined" && selected['clear_all']) {
                        $search_panel.models_multi_check_panel.hidden_content.find("input:checked[name='model[]']").each(function (index, element) {
                            $(element).prop('checked', false);
                            removeModelFromCurrentBrands($(element).attr('value'));
                            $(element).closest('.custom_check.extended_check').removeClass('checked');//не срабатывает
                        });
                    }
                    break;
            }
        });


        $search_panel.elements.panel_container.find(".sync_selector").on("onShow." + events_namespaces.sync_selectors, function () {

        });

        $search_panel.elements.panel_container.find("[name=major_expert_selected_main]").on('change', function (event, sel){
            var select2 = SEARCH_PANEL.elements.hidden_panel_content.find("[name=major_expert_selected]");
            var select = $search_panel.elements.hidden_panel_content
                .find(".checkselect[data-name-wrapper='spec_program[]']");
            if ($(this).prop('checked') == true) {
                select.checkselect('setValue', {value: SEARCH_PANEL.current_data.major_expert_select, with_trigger: false});
                select2.prop('checked', true);
            }else {
                select.checkselect('setValueOff', SEARCH_PANEL.current_data.major_expert_select);
                select2.prop('checked', false);
                select2.closest('.custom_check').removeClass('checked');//глюк
            }
        });

        $search_panel.elements.panel_container.find(".sync_selector").on("change." + events_namespaces.sync_selectors, function () {
            var name = $(this).attr('name');
            $search_panel.elements.panel_container.find(".sync_selector[name='" + name + "']").ikSelect('select', $(this).val());
            $search_panel.elements.hidden_panel_content.find(".sync_selector[name='" + name + "']").ikSelect('select', $(this).val());// убрать
            switch (name) {
                case 'major_expert_selected':
                   /* var select = $search_panel.elements.hidden_panel_content
                        .find(".checkselect[data-name-wrapper='spec_program[]']");
                    select.checkselect('setValue', {value: SEARCH_PANEL.current_data.major_expert_select, with_trigger: false});
                    console.log('major_expert_selected');*/
                    break;
                case 'gearbox[]':
                    var select = $search_panel.elements.hidden_panel_content
                        .find(".checkselect[data-name-wrapper='gearbox[]']");
                    var val = $(this).val();
                    select.checkselect('setDisableAll', {with_trigger: false});
                    select.checkselect('setValue', {value: val, with_trigger: false});
                    break;
                case 'price[to]':
                    var select = $search_panel.elements.hidden_panel_content
                        .find(".checkselect[data-name-wrapper='price[to]']");
                    select.checkselect('setValue', {value: $(this).val()});
                    if ($(this).val() == '') {
                        select.checkselect('setDisableAll', {with_trigger: false});
                    }
                    break;
                case 'year[from]':
                    var select = $search_panel.elements.hidden_panel_content
                        .find(".checkselect[data-name-wrapper='year[from]']");
                    select.checkselect('setValue', {value: $(this).val()});
                    if ($(this).val() == '') {
                        select.checkselect('setDisableAll', {with_trigger: false});
                    }
                    break;
                case 'drive_type[]':
                    var select = $search_panel.elements.hidden_panel_content
                        .find(".checkselect[data-name-wrapper='drive_type[]']");
                    select.checkselect('setValue', {value: $(this).val()});
                    if ($(this).val() == '') {
                        select.checkselect('setDisableAll', {with_trigger: false});
                    }
                    break;
            }
        });

        if (window.history && window.history.pushState && !fw_MAMMON.isIE()) {
        } else {
            // если не поддержвиаетя или IE, то на hashchange вешаем эту шляпу для дополнительного отлавливания hashchange
            $(window).on('hashchange.' + events_namespaces.analyze_hash, function () {
                analyzeHash();
            });
        }

        window.addEventListener('popstate', function (e) {
            if (!$.empty(e.state) && !$.empty(e.state.title)) {
                document.title = e.state.title;
            }
            analyzeHash();
        });
        $search_panel.elements.result_pagination_pre_load_button.on("click." + events_namespaces.paginations, function () {

            showPrevPage();
        });
        $search_panel.elements.result_pagination_post_load_container.on("click." + events_namespaces.paginations, ".pagination_button", function () {

            showNextPage();
        });
        $search_panel.elements.result_pagination_post_load_container.on("click." + events_namespaces.paginations, ".numeric_pagination_button", function () {

            showPage($(this).data("page"));
        });
        $search_panel.elements.sort_orders.on("click." + events_namespaces.sort_orders, function () {

            changeSortOrder(this, $search_panel.elements.sort_orders, function () {
                clearOldResults();
                var hash_with_page = location.hash;
                var page = hash_with_page.replace(/^.*;page=([0-9]+)$/, "$1");
                if (page == hash_with_page) {
                    page = 1;
                }
                if (page == 1) {
                    analyzeHash();
                } else {
                    showPage(1);
                }
            });
        });
        $search_panel.elements.system_sort_orders.on("click." + events_namespaces.sort_orders, function () {

            changeSortOrder(this, $search_panel.elements.system_sort_orders, function () {
                location.reload();
            });
        });
        $search_panel.elements.wrappers.on("click." + events_namespaces.wrappers, function () {

            changeWrapper(this);
        });
        $(document).on('click.' + events_namespaces.favorites, '.favorites_button', function () {

            processFavorites(this);

            return false;
        });

        // Синхронизация городов
        $search_panel.elements.hidden_brands_panel_content.find(".sync_city_check").on('change.' + events_namespaces.sync_selectors, function () {

            syncCheckBox(this, $search_panel.elements.hidden_panel_content);
        });
        $search_panel.elements.hidden_panel_content.find(".sync_city_check").on('change.' + events_namespaces.sync_selectors, function () {

            syncCheckBox(this, $search_panel.elements.hidden_brands_panel_content);
        });
        $search_panel.elements.new_search_action.click(
            hideBackToSearchPanel
        );
        $(selectors.back_by_url_hash_action).click(
            function () {

                $search_panel.current_data.hash = $(this).data('url_hash');
                fillPanelFromHash();
                startSearch();
                return false;
            }
        );
    }

    function checkAdvancedSearchFilled() {
        var data = fw_MAMMON.serializeObjSmart($search_panel.elements.hidden_panel_content);
        for (var field_key in data) {
            if (data.hasOwnProperty(field_key)) {
                if ($.inArray(field_key, ['city', 'price', 'year', 'gearbox']) == -1) {
                    if (!$.empty(data[field_key])) {
                        for (var inside_key in data[field_key]) {
                            if (data[field_key].hasOwnProperty(inside_key)) {
                                if ($.inArray(inside_key, ['from', 'to']) == -1 || !$.empty(data[field_key][inside_key])) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    function syncCheckBox(main_checkbox, target_container) {
        var id = $(main_checkbox).val();
        var is_checked = $(main_checkbox).is(":checked");
        var sync_target = $(target_container).find(".sync_city_check[value='" + id + "']");
        if (sync_target.is(":checked") != is_checked) {
            sync_target.prop('checked', is_checked).change();
        }
    }

    function setInputsEvents() {
        $search_panel.elements.panel_container.find("input[type=text]").closest('.input_container').smartFocus().click(function () {
            $(this).find("input[type=text]").focus();
        });
        $search_panel.elements.panel_container.find("input[type=text].float_input").on('blur', function () {
            var result = parseFloat($(this).val().replace(/\,/g, ".").replace(/[^0-9\.]*/g, "").replace(/^([0-9]+\.[0-9]).*$/, "$1"));
            $(this).val(isNaN(result) ? "" : result);
        });
    }

    function hideBackToSearchPanel() {
        $(selectors.with_back_button).removeClass('with_back_button');
        $search_panel.elements.back_panel.hide();
        setIKSelect();
    }

    function setIKSelect() {
        $search_panel.elements.panel_container.find("select:not(.checkselect-select)").ikSelect('redraw');
        $(".process_on_load_ik_select").ikSelect('redraw');
    }

    function setCheckBoxesInSelect() {

        $search_panel.elements.panel_container.find(".checkselect").checkselect();
    }


    function setAllFiltersChangeEvents() {
        // Селекторы верхней панели
        $search_panel.elements.panel_container.find("select:not(.checkselect-select)").on('change.' + events_namespaces.filter_change, function () {
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });
        // Селекторы основной панели
        $search_panel.elements.hidden_panel_content.find("select:not(.checkselect-select)").on('change.' + events_namespaces.filter_change, function () {
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });
        // Чекбоксы основной панели
        $search_panel.elements.hidden_panel_content.find(selectors.custom_check_inputs).on('change.' + events_namespaces.filter_change, function () {
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });
        // Инпуты основной панели
        $search_panel.elements.hidden_panel_content.find("input[type=text]").on('change.' + events_namespaces.filter_change, function () {
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });
        // Чекбоксы у брендов
        $search_panel.elements.hidden_brands_panel_content.on('change.' + events_namespaces.filter_change, selectors.custom_check_inputs, function () {
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });
        // Чекбоксы у моделей
        $search_panel.elements.hidden_models_panel_content.on('change.' + events_namespaces.filter_change, selectors.custom_check_inputs, function () {
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });

        // Новые селекты
        $search_panel.elements.hidden_panel_content.on('changeCheckSelect', '.checkselect', function (element, vals) {
            getCarsCount();
            updateFilerCounter();
            SELECTED_OPTIONS.getOptionsSelected();
            if ($(this).attr('data-name-wrapper') == "city[]") {
                processCityChange(this, vals);
            }
        });

        $search_panel.elements.panel_container.find("[name=major_expert_selected]").on('change', function (event, sel){
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });

        $search_panel.elements.panel_container.find("[name=major_expert_selected_main]").on('change', function (event, sel){
            getCarsCount();
            SELECTED_OPTIONS.getOptionsSelected();
        });
    }

    function initBrandsSorter() {
        if ($search_panel.sortContainers.brands_list != null) {
            $search_panel.sortContainers.brands_list.init($search_panel.brands_multi_check_panel.checkboxes_list_container.find(".brands_panel_list"));
        }
    }

    function setAllSorters() {
        // Brands
        $search_panel.sortContainers.brands_list = new SortContainer();
        $search_panel.sortContainers.brands_list.setQuery('(max-width: 767px) and (orientation: portrait)', 2);
        $search_panel.sortContainers.brands_list.setQuery('(max-width: 767px) and (orientation: landscape)', 2);
        $search_panel.sortContainers.brands_list.setQuery('(min-width: 768px) and (max-width: 991px)', 3);
        $search_panel.sortContainers.brands_list.setQuery('(min-width: 992px) and (max-width: 1365px)', 4);
        $search_panel.sortContainers.brands_list.setQuery('(min-width: 1366px)', 6);
        initBrandsSorter();
        //Models
        $search_panel.sortContainers.models_list = new MultipleSortContainer();
        $search_panel.sortContainers.models_list.init($search_panel.models_multi_check_panel.checkboxes_list_container, ".clearfix");
        $search_panel.sortContainers.models_list.setQuery('(max-width: 767px) and (orientation: portrait)', 2);
        $search_panel.sortContainers.models_list.setQuery('(max-width: 767px) and (orientation: landscape)', 2);
        $search_panel.sortContainers.models_list.setQuery('(min-width: 768px) and (max-width: 991px)', 3);
        $search_panel.sortContainers.models_list.setQuery('(min-width: 992px) and (max-width: 1365px)', 4);
        $search_panel.sortContainers.models_list.setQuery('(min-width: 1366px)', 6);

        $search_panel.sortContainers.salons_list = new MultipleSortContainer();
        $search_panel.sortContainers.salons_list.init($search_panel.elements.hidden_panel_content, ".two_cols_custom_items_container");
        $search_panel.sortContainers.salons_list.setQuery('(max-width: 767px) and (orientation: portrait)', 1);
        $search_panel.sortContainers.salons_list.setQuery('(max-width: 767px) and (orientation: landscape)', 2);
        $search_panel.sortContainers.salons_list.setQuery('(min-width: 768px)', 2);
    }

    function initSearchPanelData() {
        $search_panel.elements.panel_container = $(selectors.sp_container);
        $search_panel.elements.open_close_button = $search_panel.elements.panel_container.find(selectors.sp_open_close_button);
        $search_panel.elements.change_search_button = $search_panel.elements.panel_container.find(selectors.change_search_button);
        $search_panel.elements.hidden_panel_content = $search_panel.elements.panel_container.find(selectors.hidden_panel_content);
        $search_panel.elements.hidden_brands_panel_content = $search_panel.elements.panel_container.find(selectors.hidden_brands_panel_content);
        $search_panel.elements.hidden_models_panel_content = $search_panel.elements.panel_container.find(selectors.hidden_models_panel_content);

        $search_panel.elements.toggle_status_container = $search_panel.elements.panel_container.find(selectors.toggle_status_container);
        $search_panel.elements.selected_items_container = $search_panel.elements.panel_container.find(selectors.selected_items_container);
        $search_panel.elements.start_search_button = $search_panel.elements.panel_container.find(selectors.start_search_button);
        $search_panel.elements.select_brands_button = $search_panel.elements.panel_container.find(selectors.brands_button);
        $search_panel.elements.select_models_button = $search_panel.elements.panel_container.find(selectors.models_button);

        $search_panel.elements.select_brands_button_text = $search_panel.elements.select_brands_button.find(".button_label");
        $search_panel.elements.select_models_button_text = $search_panel.elements.select_models_button.find(".button_label");

        $search_panel.elements.search_result_container = $(selectors.search_result_container);
        $search_panel.elements.search_params_container = $search_panel.elements.search_result_container.find(selectors.search_params_container);


        $search_panel.elements.page_data_container = $(selectors.page_data_container);
        $search_panel.elements.remove_after_cars_search = $(selectors.remove_after_cars_search);

        $search_panel.elements.result_count_text = $search_panel.elements.search_result_container.find(selectors.result_count_text);
        $search_panel.elements.result_target_container = $search_panel.elements.search_result_container.find(selectors.result_target_container);

        $search_panel.elements.result_pagination_pre_load_container = $search_panel.elements.search_result_container.find(selectors.pagination_pre_load_before);
        $search_panel.elements.result_pagination_pre_load_button = $search_panel.elements.result_pagination_pre_load_container.find(".pagination_button");
        $search_panel.elements.result_pagination_pre_load_page_text = $search_panel.elements.result_pagination_pre_load_container.find(".page_for_show");

        $search_panel.elements.result_pagination_post_load_container = $search_panel.elements.search_result_container.find(selectors.pagination_post_load_after);


        $search_panel.elements.sort_orders = $search_panel.elements.search_result_container.find(".sort_type");
        $search_panel.elements.system_sort_orders = $(".page_data_container .sort_type");

        $search_panel.elements.wrappers = $(".wrapper_type");
        $search_panel.elements.display_type_target = $(".display_type_target");

        $search_panel.elements.new_search_action = $(selectors.new_search_action);
        $search_panel.elements.back_panel = $(selectors.back_panel);

        $search_panel.elements.favorites_count = $(".favorites_count");

        //
        initBrandsPanel();
        initModelsPanel();
        //
        initIkSelect("search_panel_button");
        //
        setInputsEvents();
        setMainEvents();
        setCheckBoxes();
        //

        //
        setIKSelect();
        setCheckBoxesInSelect();
        setAllFiltersChangeEvents();

        clearDetach($search_panel.elements.hidden_panel_content);
        clearDetach($search_panel.elements.hidden_brands_panel_content);
        clearDetach($search_panel.elements.hidden_models_panel_content);


        setAllSorters();

        generateBrandsListContent();
        disableModelsButton();

        setDefaultCity();
        analyzeHash();
        getCarsCount();
        SELECTED_OPTIONS.getOptionsSelected();

    }

    return $search_panel;
}();
