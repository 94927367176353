function BRAND_MODEL(brand, model) {
	this.brand = brand;
	this.model = model;

	this.elements = {
		brand: '',
		brandSelect: '',
		brandSelectOptions: '',
		brandIkSelect: '',

		model: '',
		modelSelect: '',
		modelSelectOptions: '',
		modelIkSelect: '',
	}

	this.data = {
		modelOptions: []
	}

	this.init();
}

BRAND_MODEL.prototype.initBrands = function () {
	var context = this;
	this.elements.brandSelectOptions.on('click', function (e) {
		var value = $(e.currentTarget).data('value');
		if (value == '') {
			context.elements.modelIkSelect.ikSelect('disableOptions', context.data.modelOptions);
			context.elements.modelIkSelect.ikSelect('enableOptions', '').ikSelect('enableOptions', 'estimation_car');
			context.elements.modelIkSelect.ikSelect('select', '');
		} else if (value == 'estimation_car') {
			showRequestCallTouchForm();
			fw_MAMMON.preventHref(event);

			context.elements.modelIkSelect.ikSelect('disableOptions', context.data.modelOptions);
			context.elements.modelIkSelect.ikSelect('enableOptions', '').ikSelect('enableOptions', 'estimation_car');
			context.elements.modelIkSelect.ikSelect('select', '');
		} else {
			var valueModel = (value.split(';')[1].split(','));
			valueModel.unshift('');

			context.elements.modelIkSelect.ikSelect('disableOptions', context.data.modelOptions);
			context.elements.modelIkSelect.ikSelect('enableOptions', valueModel).ikSelect('enableOptions', 'estimation_car');
			context.elements.modelSelect.find('.ik_select_link').removeClass('disabled');
			context.elements.modelIkSelect.ikSelect('select', '');
		}
	});
}

BRAND_MODEL.prototype.initModels = function () {
	var context = this;
	this.elements.modelSelectOptions.each(function (item, value) {
		if ($(value).data('value') !== '') context.data.modelOptions.push(String($(value).data('value')));
	});
	context.elements.modelIkSelect.ikSelect('disableOptions', context.data.modelOptions);
	context.elements.modelIkSelect.ikSelect('enableOptions', '').ikSelect('enableOptions', 'estimation_car');

	this.elements.modelSelectOptions.on('click', function (e) {
		var value = $(e.currentTarget).data('value');
		if (value == 'estimation_car') {
			showRequestCallTouchForm();
			fw_MAMMON.preventHref(event);
		}
	});
}

BRAND_MODEL.prototype.init = function () {
	this.elements.brand = $(this.brand);
	this.elements.brandSelect = this.elements.brand.find('.ik_select');
	this.elements.brandSelectOptions = this.elements.brand.find('.ik_select_option');
	this.elements.brandIkSelect = this.elements.brand.find('select');

	this.elements.model = $(this.model);
	this.elements.modelSelect = this.elements.model.find('.ik_select');
	this.elements.modelSelectOptions = this.elements.model.find('.ik_select_option');
	this.elements.modelIkSelect = this.elements.model.find('select');

	this.initBrands();
	this.initModels();
}
