function trackYandexHit(url, title) {
    try {
        yaCounter35135640.hit(
            url,
            {
                title: title
            }
        );
    } catch (a) {
        console.log('Ошибка при работе с яндекс метрикой. Возможно, требуется поменять Yandex ID');
    }
}

function trackGoogleHit(url, title) {
    try {
        ga('send', title, url);
    } catch (a) {
        console.log('Ошибка при работе с Google аналитикой. Возможно, требуется поменять Google ID');
    }
}

/*main.js*/
$(function () {
    $(".diagnostic-button").click(function (event) {
        showDiagnosticForm($(this).data('show-uid'), $(this).data('diagrostic_calltouch_code'));
        fw_MAMMON.preventHref(event);
    });
    $(".add_question_button").click(function (event) {
        showFaqAddQuestionForm();
        fw_MAMMON.preventHref(event);
    });
    $(".open_estimation_form").click(function (event) {
        showEstimationForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".open_request_form").click(function (event) {
        showRequestForm();
        fw_MAMMON.preventHref(event);
    });
    $(".open_call_back_form, .buy_presale_car_button").click(function (event) {
        showCallBackForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".form_subscription").click(function (event) {
        showSubscriptionForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".open_leasing_form").click(function (event) {
        showLeasingForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".open_pre_pay_window").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var url = $(this).attr('href');
        var cid = $.cookie('_ga') ? '&cid=' + $.cookie('_ga') : '';
        var redirectUrl = url + cid;
        var win = window.open(redirectUrl, '_blank'); //костыль, чтобы открывать ссылку в новой вкладке (target="_blank" не помог)
        win.focus();
    });
    if (location.hash.indexOf('#prepay') === 0) {
        var addr = $(".open_pre_pay_window").attr('href');
        if (addr && addr != '#') gotoPaymentsForm($(".open_pre_pay_window").attr('href'));
    }
    $(".open_credit_form").click(function (event) {
        showCreditWindow($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".open_feedback_form").click(function (event) {
        showFeedBackForm();
        fw_MAMMON.preventHref(event);
    });
    openCallbackCalltouchForm();
    $(".js_open_estimation_calltouch_form").click(function (event) {
        showEstimationCallTouchForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".js_open_request_calltouch_form").click(function (event) {
        showRequestCallTouchForm();
        fw_MAMMON.preventHref(event);
    });

    $(".js_open_delivery_car_form").click(function (event) {
        showDeliveryCarForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".js_open_online_video_form").click(function (event) {
        showOnlineVideoForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    });
    $(".js_open_call_seller_form").click(function (event) {
        showCallSellerForm($(this).data('show-uid'));
        fw_MAMMON.preventHref(event);
    })

//Некоторые номера телефонов меняются не нашим скриптом, при этом не меняется атрибут href ссылки, это решение.
// 	$('a[href*="tel:"]').bind('DOMSubtreeModified', function () {
// 		if ($(this).data('tel') === $(this).text()) return;
// 		var text = 'tel:' + $.trim($(this).text());
// 		$(this).attr('data-tel', $(this).text());
// 		$(this).attr('href', text);
// 	});

    if ($('.js-change-image-wrapper').length) {
        slidePreviewImages();
    }

    initConsentToDataProcessing();
});

function openCallbackCalltouchForm() {
    $(".js_open_callback_calltouch_form").click(function (event) {
        showCallBackCallTouchForm(
            $(this).data('show-uid'),
            $(this).data('calltouch-route-key')
        );
        fw_MAMMON.preventHref(event);
    });
}

function createFormWindow(title) {
    return fw_WIN.create({
        offset: 0,
        top_offset: 80,
        title: title,
        container_class: "form_window",
        content_class: "fw_win_form_content",
        overlay_class: "form_overlay",
        lock_page: false
    }, {
        onBeforeOpen: function () {
            $(".search_panel_container").css({zIndex: 5});
        },
        onAfterClose: function () {
            $(".search_panel_container").css({zIndex: ""});
        }
    });
}

function initConsentToDataProcessing() {
    $(".open_security_policy_form").click(function (event) {
        showSecurityPolicyForm();
        fw_MAMMON.preventHref(event);
    });

    $(".open_reverse_communication_form").click(function (event) {
        showReverseCommunicationForm();
        fw_MAMMON.preventHref(event);
    });
}

function initAucRequestForm(is_legal_entity) {
    var container = $(".auc_request_form_container");
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    if (!is_legal_entity) {
        container.find(".form_org_block").hide().find("input").val("ФИЗИЧЕСКОЕ ЛИЦО");
    }
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        container.find(".additional_information").removeClass("hidden");
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
}

function setCustomBrandByName(name) {
    $(document).on("brands_list_ready.set_brand_on_ready", function (e, target) {
        $(document).off(".set_brand_on_ready");
        $(target).find("option").filter(function () {
            return name == $(this).text();
        }).prop("selected", true).change();
        $(target).ikSelect('reset');
        $(target).ikSelect('redraw');
    });
}

function setCustomModelByName(name) {
    $(document).on("models_list_ready.set_model_on_ready", function (e, target) {
        $(document).off(".set_model_on_ready");
        $(target).find("option").filter(function () {
            return name == $(this).text();
        }).prop("selected", true).change();
        $(target).ikSelect('reset');
        $(target).ikSelect('redraw');
    });
}

function getBrandsListForSelector(target, selected) {
    selected = selected || "";
    fw_MOJAX.send({
        to: target,
        url: "/ajax/cars/getCarsBrands/",
        user_success: function (data, user_data) {
            target.val(selected);
            $(target).ikSelect('reset');
            $(target).ikSelect('redraw');
            $(document).trigger("brands_list_ready", target);
        },
        overlay_mode: fw_MOJAX.NONE
    });
}

function getModelsListForSelector(target, brand, selected) {
    if (!brand || brand == "") return;
    selected = selected || "";
    fw_MOJAX.send({
        to: target,
        url: "/ajax/cars/getCarsModels/",
        data: {
            brand: brand
        },
        user_success: function (data, user_data) {
            target.val(selected);
            $(target).ikSelect('reset');
            $(target).ikSelect('redraw');
            if (typeof brand == "string") $(document).trigger("models_list_ready", target);
        },
        overlay_mode: fw_MOJAX.NONE
    });
}

function setMappingWithModels(brands_target, models_target, selected_brand, selected) {
    brands_target.on("change", function () {
        var brand = $(this).val();
        getModelsListForSelector(models_target, brand);
    });
    if (selected) {
        getModelsListForSelector(models_target, selected_brand, selected);
    }
}

function initFeedBackForm(container) {
    INIT.initFormElements();
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
	let ratingField = $("input[name='rating']");
	createRatingSlider($('#feedback_rating_slider'), ratingField);

}

function unlockWindowByForm(form) {
    var win = fw_WIN.getInstanceByChild($(form));
    if (win !== undefined) {
        win.locked = false;
    }
}

function createRatingSlider(slider_obj, input_obj) {
    noUiSlider.create(slider_obj[0], {
        animate: true,
        behaviour: 'tap',
        start: [0],
        step: 1,
        connect: 'lower',
        range: {
            'min': 0,
            'max': 5
        },
        format: {
            to: function (value) {
                return Math.round(value);
            },
            from: function (value) {
                return value;
            }
        },
        pips: {
            mode: 'steps',
            density: 20,
            filter: function (value, type) {
                return value ? 1 : 0;
            }
        }
    });
    slider_obj[0].noUiSlider.on("update", function (values, handle) {
        var value = values[handle];
        input_obj.val(value);

    });
    slider_obj[0].noUiSlider.on("change", function (values, handle) {
        $(slider_obj).trigger('gtm_feedback_slider_change');
    });
}

function initEstimationForm(container, in_page, selected_brand, selected_model, selected_run, selected_year) {
    INIT.initEstimationFormElements();

    container.find(".submit_button").click(function () {
        var code_estimation = container.find("[name=salon_select] option:selected").data('salon-code-estimation');
		if (!code_estimation){
            code_estimation = 'obmen_avto';
        }
        container.find('[data-calltauch-key]').data('calltauch-key', code_estimation);
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        if (in_page) {
            container.find(".additional_information").removeClass("hidden");
        } else {
            unlockWindowByForm(this);
        }
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
    container.find(".show_form_additional_info").on("click", function () {
        container.find(".additional_estimation_information").toggleClass("hidden");
    });
    // Вешаем систему на получение селектора с брендами
    addBrandModelSelectors(container, "brand", "model", selected_brand, selected_model);
    if (selected_year) {
        var yearSelector = container.find(".form_year_block select");
        yearSelector.val(selected_year);
        yearSelector.ikSelect('reset');
        yearSelector.ikSelect('redraw');
    }
    if (selected_run) {
        var runSelector = container.find(".form_run_block select");
        runSelector.val(selected_run);
        runSelector.ikSelect('reset');
        runSelector.ikSelect('redraw');
    }
}

function showSecurityPolicyForm() {
    var win = fw_WIN.create({
        offset: 0,
        width: 530,
        top_offset: 20,
        title: "Политика безопасности",
        lock_page: false,
        block_mode: true,
        container_class: "form_window form_window_fix",
        content_class: "fw_win_form_content",
        overlay_class: "form_overlay"
    }, {
        onBeforeOpen: function () {
            $(".search_panel_container").css({zIndex: 5});
        },
        onAfterClose: function () {
            if (!$('.fw_win_wrapper').length) {
                $(".search_panel_container").css({zIndex: ""});
            }
        }
    });
    fw_MOJAX.send({
        to: win.content,
        overlay_to: win.content,
        overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
        url: "/ajax/forms/securityPolicyForm/"
    });
}

function showReverseCommunicationForm() {
    var win = fw_WIN.create({
        offset: 0,
        width: 530,
        top_offset: 20,
        title: "Согласие на обратную коммуникацию",
        lock_page: false,
        block_mode: true,
        container_class: "form_window form_window_fix",
        content_class: "fw_win_form_content",
        overlay_class: "form_overlay"
    }, {
        onBeforeOpen: function () {
            $(".search_panel_container").css({zIndex: 5});
        },
        onAfterClose: function () {
            if (!$('.fw_win_wrapper').length) {
                $(".search_panel_container").css({zIndex: ""});
            }
        }
    });
    fw_MOJAX.send({
        to: win.content,
        overlay_to: win.content,
        overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
        url: "/ajax/forms/reverseCommunicationForm/"
    });
}

function showMoreLifeInsurance() {
    var win = fw_WIN.create({
        offset: 0,
        width: 530,
        top_offset: 20,
        title: "",
        lock_page: false,
        block_mode: true,
        container_class: "form_window form_window_fix",
        content_class: "fw_win_form_content",
        overlay_class: "form_overlay"
    }, {
        onBeforeOpen: function () {
            $(".search_panel_container").css({zIndex: 5});
        },
        onAfterClose: function () {
            if (!$('.fw_win_wrapper').length) {
                $(".search_panel_container").css({zIndex: ""});
            }
        }
    });
    fw_MOJAX.send({
        to: win.content,
        overlay_to: win.content,
        overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
        url: "/ajax/forms/moreLifeInsurance/"
    });
}

function showFaqAddQuestionForm() {
    var faqWin = createFormWindow("Задайте свой вопрос сейчас");
    fw_MOJAX.send({
        to: faqWin.content,
        url: "/ajax/forms/getAddQuestionForm/",
        user_success: function (data, user_data) {
            INIT.initFormElements();
            faqWin.content.find(".submit_button").click(function () {
                $(this).closest("form").submit();
            });
            faqWin.content.find("form").on("success.formjor", function () {
                unlockWindowByForm(this);
            });
            initConsentToDataProcessing();
        }
    });
}

function showDiagnosticForm(uid, calltouch_route_key) {
    var diagnosticWin = createFormWindow("Узнать диагностику и состояние кузова");
    fw_MOJAX.send({
        to: diagnosticWin.content,
        url: "/ajax/forms/getDiagnosticForm/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            if (calltouch_route_key !== undefined && calltouch_route_key != '') {
                $("[data-calltauch-key]").data('calltauch-key', calltouch_route_key);
            }
            initCallBackForm(diagnosticWin.content);
            initConsentToDataProcessing();
        }
    });
}

function showEstimationForm(uid, form) {
    var estimationWin = createFormWindow(uid ? 'Обмен автомобиля' : 'Заявка на оценку автомобиля');
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/getEstimationForm/",
        data: {show_uid: form ? false : uid},
        user_success: function (data, user_data) {
            initConsentToDataProcessing();

            if (uid) {
                initEstimationForm(estimationWin.content, false, null, null, null, null);
            }
            else {
                initEstimationForm(
                    estimationWin.content,
                    false,
                    $('.root_estimation_brands').val(),
                    $('.root_estimation_models').val(),
                    $('.root_estimation_run').val(),
                    $('.root_estimation_year').val()
                );
            }
        }
    });
    return estimationWin;
}

function showRequestForm() {
    var estimationWin = createFormWindow("Заявка на подбор автомобиля");
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/requestForm/",
        //data: {show_uid: uid},
        user_success: function (data, user_data) {
            initRequestForm(estimationWin.content);
            initConsentToDataProcessing();
            //initCallBackForm(estimationWin.content);
        }
    });
}

function showCallBackForm(uid) {
    var estimationWin = createFormWindow("Заказать звонок");
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/callBackForm/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            initCallBackForm(estimationWin.content);
            initConsentToDataProcessing();
        }
    });
}

function showSubscriptionForm(uid) {
    var estimationWin = createFormWindow("Подписаться");
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/getFormSubscription/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            initCallBackForm(estimationWin.content);
            initConsentToDataProcessing();
        }
    });
}

function initCallBackForm(container) {
    INIT.initFormElements();
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
}

function showFeedBackForm() {
    var estimationWin = createFormWindow("Оставить отзыв о сайте");
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/feedBackForm/",
        user_success: function (data, user_data) {
            initFeedBackForm(estimationWin.content);
            initConsentToDataProcessing();
        }
    });
}

function showCallBackCallTouchForm(uid, calltouch_route_key) {
    var estimationWin = createFormWindow("Заказать звонок");
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/callBackFormCallTouch/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            initCallBackForm(estimationWin.content);
            initConsentToDataProcessing();
            if (calltouch_route_key !== undefined && calltouch_route_key != '') {
                $("[data-calltauch-key]").data('calltauch-key', calltouch_route_key);
            }
            $(document).ajaxSuccess(function (event, request, settings) {
                if (settings.url === "/ajax/forms/execForm/21/?ajax_display_mode") {
                    var dataObject = jQuery.parseJSON(request.responseText);
                    if (dataObject.success) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'formSendSuccess',
                            eventCategory: 'Заказать звонок'
                        });
                    }
                }
            });
        }
    });
}

function showEstimationCallTouchForm(uid, form) {
    var estimationWin = createFormWindow(uid ? 'Обмен автомобиля' : 'Заявка на оценку автомобиля');
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/getEstimationFormCallTouch/",
        data: {show_uid: form ? false : uid},
        user_success: function (data, user_data) {
            initConsentToDataProcessing();
            initEstimationForm(estimationWin.content);
            $(document).ajaxSuccess(function (event, request, settings) {
                if (settings.url === "/ajax/forms/execForm/21/?ajax_display_mode") {
                    var dataObject = jQuery.parseJSON(request.responseText);
                    if (dataObject.success) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'formSendSuccess',
                            eventCategory: 'Узнать цену'
                        });
                    }
                }
            });
        }
    });
    return estimationWin;
}

function showDeliveryCarForm(uid) {
    var win = createFormWindow("Автомобиль с доставкой на дом");
    fw_MOJAX.send({
        to: win.content,
        url: "/ajax/forms/getDeliveryCarForm/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            initRequestForm(win.content);
            initConsentToDataProcessing();
        }
    });
}

function showOnlineVideoForm(uid) {
    var win = createFormWindow("Онлайн-показ");
    fw_MOJAX.send({
        to: win.content,
        url: "/ajax/forms/getOnlineVideoForm/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            initRequestForm(win.content);
            initConsentToDataProcessing();
        }
    });
}

var c_window_enhanced;

function showRequestCallTouchForm(uid) {
    var estimationWin = createFormWindow("Заявка на подбор автомобиля");
    fw_MOJAX.send({
        to: estimationWin.content,
        url: "/ajax/forms/requestFormCallTouch/",
        //data: {show_uid: uid},
        user_success: function (data, user_data) {
            initRequestForm(estimationWin.content);
            initConsentToDataProcessing();
            //initCallBackForm(estimationWin.content);
        }
    });
}

function showCallSellerForm(uid){
    var win = createFormWindow("Вызов продавца");
    fw_MOJAX.send({
        to: win.content,
        url: "/ajax/forms/getCallSellerForm/",
        data: {show_uid: uid},
        user_success: function(data, user_data){
            initRequestForm(win.content);
            initConsentToDataProcessing();
        }
    });
}


function initRequestForm(container) {
    INIT.initFormElements();
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        container.find(".additional_information").removeClass("hidden");
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
    container.find(".add_additional_car").on("click", function () {
        var fields_container = $(this).addClass("hidden").next();
        fields_container.removeClass("hidden");
        fields_container.find("select").ikSelect('redraw');
        fields_container.parent().next().removeClass("hidden")
    });
    // Вешаем систему на получение селектора с брендами
    addBrandModelSelectors(container, "brand_main", "model_main");
    addBrandModelSelectors(container, "brand_second", "model_second");
    addBrandModelSelectors(container, "brand_third", "model_third");
}

function initSubscribeNewCarsForm(container) {
    INIT.initFormElements();
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        container.find(".additional_information").removeClass("hidden");
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
    new BRAND_MODEL('#form_brand', '#form_model');
    new BRAND_MODEL('#form_brand2', '#form_model2');
    new BRAND_MODEL('#form_brand3', '#form_model3');
}

function addBrandModelSelectors(container, brand_code, model_code, selected_brand, selected_model) {
    var brands_target_selector = container.find(".form_" + brand_code + "_block select");
    var models_target_selector = container.find(".form_" + model_code + "_block select");
    getBrandsListForSelector(brands_target_selector, selected_brand);
    setMappingWithModels(brands_target_selector, models_target_selector, selected_brand, selected_model);
}

function showLeasingForm(uid) {
    var leasingFormWin = createFormWindow("Расчет лизинга");
    fw_MOJAX.send({
        to: leasingFormWin.content,
        url: "/ajax/forms/leasingForm/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            initLeasingForm(leasingFormWin.content);
            initConsentToDataProcessing();
        }
    });
}

function initLeasingForm(container) {
    INIT.initFormElements();
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
}


function showPrePayWindow(paymentLink) {
    var prePayWin = fw_WIN.create({
        offset: 0,
        top_offset: 80,
        title: "Предоплата автомобиля",
        container_class: "prepay_window",
        content_class: "fw_win_pay_content",
        window_class: "full_screen_window",
        overlay_class: "form_overlay",
        lock_page: true
    }, {
        onBeforeOpen: function () {
            $(".search_panel_container").css({zIndex: 5});
        },
        onAfterClose: function () {
            $(".search_panel_container").css({zIndex: ""});
        }
    });
    prePayWin.content.html(
        '<div id="payment_win">' +
        '	<iframe id="pay_iframe" src="' + paymentLink + '"></iframe>' +
        '</div>'
    );
}

function gotoPaymentsForm(paymentLink) {
    location.href = paymentLink;
}

function showCreditWindow(uid) {
    var creditWin = createFormWindow("Кредитный калькулятор");
    fw_MOJAX.send({
        to: creditWin.content,
        url: "/ajax/forms/creditForm/",
        data: {show_uid: uid},
        user_success: function (data, user_data) {
            $.each(data.credit_params, function (key, value) {
                creditWin.content.find('input[name=' + key + ']').val(value);
            });
            $('.life-insurance-about').click(function () {
                showMoreLifeInsurance();
            });
            initCreditForm(creditWin.content, data.credit_params);
            initConsentToDataProcessing();
        }
    });
}

function initCreditForm(container, creditParams) {
    INIT.initFormElements();
    container.find(".submit_button").click(function () {
        $(this).closest("form").submit();
    });
    container.find("form").on("success.formjor", function () {
        unlockWindowByForm(this);
        $("html, body").scrollTo(container.parent(), 300, {offset: {top: -60}});
    });
    var creditForm = new CreditForm(creditParams.type, creditParams.price);
    creditForm.init();
}


function SortContainer() {
    var self = this;
    this._container = null;
    this._items = null;
    this._itemsCount = 0;
    this._currentCount = null;

    function init(container) {
        setContainerWithItems(container);
        sort();
        show();
    }

    function setContainerWithItems(container) {
        self._container = $(container);
        self._items = self._container.data('items');
        if (!self._items) {
            self._items = self._container.children().filter(":not(.filler)").each(function (index) {
                $(this).data('main-index', index);
                $(this).attr('id', "test-" + index);
            });
            self._container.data('items', self._items);
        }
        self._itemsCount = self._items.length;
    }

    function sort() {
        if (self._currentCount != null && self._currentCount > 0 && self._itemsCount > 0) {
            var filler = self._items.eq(0).clone(true).off().css({visibility: "hidden"}).addClass("filler");
            filler.find("input").remove();
            self._items.detach();
            var rows = Math.ceil(self._itemsCount / self._currentCount);
            var newItemsOrder = [];
            for (var row = 0; row < rows; row++) {
                for (var col = 0; col < self._currentCount; col++) {
                    var index = row + col * rows;
                    var item = self._items.eq(index);
                    if (item.length == 0) {
                        item = filler.clone();
                    }
                    newItemsOrder.push(item);
                }
            }
            self._container.html(newItemsOrder);
        }
    }

    function setQuery(mediaQuery, rowsCount) {
        (function (mediaQuery, rowsCount) {
            Harvey.attach(mediaQuery, {
                on: function () {
                    self._currentCount = rowsCount;
                    self.sort();
                }
            });
        })(mediaQuery, rowsCount);
    }

    function show() {
        self._container.css('opacity', '1');
    }

    this.init = init;
    this.setQuery = setQuery;
    this.sort = sort;
}

function MultipleSortContainer() {
    var self = this;

    SortContainer.apply(this, arguments);
    var parentInit = this.init;

    this._containerSelector = "";

    this.sort = function () {
        self._mainContainer.find(self._containerSelector).each(function () {
            parentInit($(this));
        });
    };
    this.init = function (mainContainer, containerSelector) {
        self._containerSelector = containerSelector;
        self._mainContainer = mainContainer;
        self.sort();
    };
}


var _GALLERY_3D = function () {

    var _pano_in = null;
    var _skin_in = null;
    var _xml_url = null;
    var _swf_url = null;

    var _GAL_RESOURCES_FOLDER = "/files/resources/3d_view_player/";
    var SELECTOR_3D_BLOCK = '.view_3d_block';
    var SELECTOR_FOTO_BLOCK = '.fotorama';
    var PLAYER_CONTAINER = 'view_3d_container';

    function isCanvasSupported() {
        var elem = document.createElement('canvas');
        return !!(elem.getContext && elem.getContext('2d'));
    }

    function isFlashSupported() {
        var flash_version = swfobject.getFlashPlayerVersion();
        return !!flash_version.major || flash_version.minor || flash_version.release;
    }

    function init3dpanelHTML5() {

        if (!_xml_url) return false;
        $(SELECTOR_3D_BLOCK).addClass('html5_gallery');
        // create the panorama player with the container
        _pano_in = new pano2vrPlayer(PLAYER_CONTAINER);
        // add the skin object
        _skin_in = new pano2vrSkin(_pano_in, _GAL_RESOURCES_FOLDER);
        // load the configuration
        _pano_in.readConfigUrl(_xml_url);
    }

    function init3dpanelFlash() {

        if (!_swf_url) return false;
        if ($(SELECTOR_3D_BLOCK).data("flash-pano-loaded")) return;
        var flashvars = {};
        var params = {};
        params.quality = "high";
        params.bgcolor = "#ffffff";
        params.allowscriptaccess = "sameDomain";
        params.allowfullscreen = "true";
        params.base = ".";
        params.scale = "exactfit";
        var attributes = {};
        attributes.id = "pano";
        attributes.name = "pano";
        attributes.align = "middle";
        swfobject.embedSWF(
            _swf_url,
            PLAYER_CONTAINER,
            "627",
            "470",
            "9.0.0",
            _GAL_RESOURCES_FOLDER + "expressInstall.swf",
            flashvars,
            params,
            attributes
        );
        $(SELECTOR_3D_BLOCK).data("flash-pano-loaded", true);
    }

    function show3dbutton(button) {
        button.show();
    }

    function toggle3dpanel(button) {
        if ($(button).data('opened')) {
            $(button).data('opened', false);
            hide3dpanel();
        } else {
            $(button).data('opened', true);
            show3dpanel();
        }
    }

    function show3dpanel() {
        $(SELECTOR_3D_BLOCK).show();
        $(SELECTOR_FOTO_BLOCK).hide();
        if (isFlashSupported()) {
            init3dpanelFlash();
        } else if (isCanvasSupported()) {
            init3dpanelHTML5();
        }
    }

    function hide3dpanel() {
        $(SELECTOR_3D_BLOCK).hide();
        $(SELECTOR_FOTO_BLOCK).show().resize();
    }

    var $this = {
        init: function (xml_url, swf_url, button) {
            _xml_url = xml_url;
            _swf_url = swf_url;
            if (_xml_url && _swf_url) {
                button = $(button);
                show3dbutton(button);
                button.on('click', function () {
                    toggle3dpanel(this);

                    $(button).trigger('gtm_interior3dView');
                    return false;
                });
            }
        },
        toggleIOSFullscreen: function (callback) {
            $(window).resize(function () {
                if ($('#pano_int').hasClass('brazzers_pano')) {
                    $('#pano_int, body, html, .search_panel_container, #interior_panorama').removeAttr('style');
                    $('#pano_int').removeClass('brazzers_pano');
                    $(window).resize();
                }
            });
            $('#pano_int').toggleClass('brazzers_pano');
            if ($('#pano_int').hasClass('brazzers_pano')) {
                var width = window.innerWidth,
                    height = window.innerHeight;
                $('#pano_int').css({
                    width: width,
                    height: height,
                    position: 'fixed',
                    left: 0,
                    zIndex: 70000,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    margin: 0
                });
                $('#interior_panorama').css('height', window.innerHeight);
                $('#interior_panorama').css('width', window.innerWidth);
                $('.search_panel_container').css('zIndex', 0);
                $('body, html').css('overflow', 'hidden');
                window.pseudoFullscreen = true;
                callback(true);
            } else {
                $('#pano_int, body, html, .search_panel_container').removeAttr('style');
                $('#interior_panorama').removeAttr('style');
                $(window).resize();
                window.pseudoFullscreen = false;
                callback(false);
            }
        },
    };
    return $this;
}();

var createSellingPageSlider = function () {
    if ($(window).width() < 767) {
        $('#numbers_promo_carousel').addClass('carousel slide');
        $('#numbers_promo_carousel .numbers_promo__list').addClass('carousel-inner');
        $("#numbers_promo_carousel").swiperight(function () {
            $(this).carousel('prev');
        });
        $("#numbers_promo_carousel").swipeleft(function () {
            $(this).carousel('next');
        });
    } else {
        $('#numbers_promo_carousel').removeClass('carousel').removeClass('slide');
        $('#numbers_promo_carousel .numbers_promo__list').removeClass('carousel-inner');
    }
};

var sellingPageButtonsEvents = function () {
    $('.estimation_form_link').click(function () {
        $('body, html').animate({
            scrollTop: $('.estimation_form_container').offset().top - 53
        }, 300, 'swing');
    });
};

var buyNewMotorcycleShowBrands = function () {
    $(window).load(function () {
        $('.page_brands_selector_container > .row').animate({
            opacity: 1
        }, 500);
    });
};

var buyNewCarShowBrands = function () {
    $(window).load(function () {
        $('.page_brands_selector_container > .row').animate({
            opacity: 1
        }, 500);
    });
};

var buyingPageCipherDataBlocksEqualizing = function () {
    var resetHeight = function () {
        $(".cipher_data_promo").find(".cipher_data_promo__block").removeAttr('style');
    };
    Harvey.attach('(min-width: 1366px)', {
        on: function () {
            resetHeight();
            $(".cipher_data_promo").fixHeight(".cipher_data_promo__block", 3);
        },
        off: function () {
            resetHeight();
            $(".cipher_data_promo").fixHeight(".cipher_data_promo__block", 3);
        }
    });
    Harvey.attach('(min-width: 992px) and (max-height: 1365px)', {
        on: function () {
            resetHeight();
            $(".cipher_data_promo").fixHeight(".cipher_data_promo__block", 3);
        },
        off: function () {
            resetHeight();
            $(".cipher_data_promo").fixHeight(".cipher_data_promo__block", 3);
        }
    });
    Harvey.attach('(min-width: 768px) and (max-height: 991px)', {
        on: function () {
            resetHeight();
            $(".cipher_data_promo").fixHeight(".cipher_data_promo__block", 3);
        },
        off: function () {
            resetHeight();
            $(".cipher_data_promo").fixHeight(".cipher_data_promo__block", 3);
        }
    });
    Harvey.attach('(max-width: 767px)', {
        on: function () {
            resetHeight();
        }
    });
};

var changesBlockCarSelection = function () {
    var title = $('.block_select_car .select_car_title'),
        descr = $('.block_select_car .select_car_description'),
        container = $('.block_select_car'),
        rand = Math.ceil(Math.random() * 3);
    switch (rand) {
        case 1:
            title.text('30 и более новых поступлений в день!');
            descr.text('Закажите подбор автомобиля по вашим параметрам и наши менеджеры будут следить за новыми постпуплениями и свяжутся с вами когда появится нужный автомобиль.');
            break;
        case 2:
            title.text('Устал искать самостоятельно?');
            descr.text('Заполните форму и наши менеджеры подберут автомобиль под ваши потребности. Доверь подбор автомобиля профессионалам!');
            break;
        case 3:
            title.text('Не нашли подходящий автомобиль на сайте?');
            descr.text('Каждый день мы выкупаем более 30 автомобилей и можем подобрать автомобиль с пробегом именно для вас!');
            break;
        default:
            break;
    }
    // $.cookie('selling_random', rand, { expires: 7, path: '/'});
    container.addClass(('selling_random_' + rand));
};


var select_city_object = {
    win_map: null,
    current_select_city: 1
};

function closeBallonAndSelectSeletor(salonOid) {
    select_city_object.win_map.close();
    $("select[name=salon_select]  option[data-salon-oid=" + salonOid + "]").attr('selected', 'selected');
    $("select[name=salon_select] ").ikSelect('reset');
    $("select[name=salon_select] ").ikSelect('redraw');
}


function initCityAndSalonsSelector() {
    $('select[name=city]').change(function () {
        select_city_object.current_select_city = $(this).val();
        var target = $('select[name=salon_select]');
        fw_MOJAX.send({
            to: target,
            url: "/ajax/cars/getSallonsList/",
            data: {
                city: select_city_object.current_select_city
            },
            user_success: function (data, user_data) {
                $(target).ikSelect('reset');
                $(target).ikSelect('redraw');
                target.trigger('salonsLoad');
            },
            overlay_mode: fw_MOJAX.NONE
        });
    });


    $('select[name=city]').val(select_city_object.current_select_city);
    $('select[name=city]').change();
    /*$('select[name=city]').ikSelect('reset');
    $('select[name=city]').ikSelect('redraw');*/

    $('.map_select_salon').click(function () {
        select_city_object.win_map = fw_WIN.create({
            offset: 0,
            width: 780,
            top_offset: 20,
            title: "Выберите салон",
            lock_page: false,
            block_mode: true,
            container_class: "form_window form_window_fix",
            content_class: "fw_win_form_content",
            overlay_class: "form_overlay"
        }, {
            onBeforeOpen: function () {
                $(".search_panel_container").css({zIndex: 5});
            },
            onAfterClose: function () {
                if (!$('.fw_win_wrapper').length) {
                    $(".search_panel_container").css({zIndex: ""});
                }
            }
        });
        fw_MOJAX.send({
            to: select_city_object.win_map.content,
            data: {
                city: select_city_object.current_select_city
            },
            overlay_to: select_city_object.win_map.content,
            overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
            url: "/ajax/cars/getSallonsListMap/"
        });
    });
}

function slidePreviewImages() {
    if (window.innerWidth > '575') {
        $.each($('[data-js-image]'), function (index, value) {
            //если нет фото, тогда удаляем элемент, чтоб показывать дефолтное фото, а не превью
            if (!$(value).data('js-image')) {
                $(value).remove();
                return true;
            }
        });

        $.each($('.js-change-image-wrapper'), function (index, value) {
            $($(value).find('.js-change-image')[0]).addClass('active');
            $(value).find('.js-change-image').on('mouseenter touchstart', function (e) {
                var image = $(this).parent().parent().find('.js-car-image');
                var image_box_hover = $(this);
                image.attr('src', $(this).data('js-image'));
                image_box_hover.parent().find('.js-change-image').removeClass('active');
                image_box_hover.addClass('active');
            });
        });
        $('.js-change-image-wrapper').on('mouseleave', function (e) {
            $(this).siblings('.js-car-image').attr('src', $(this).siblings('.js-car-image').data('src'));
        });
    } else {
        $('.js-change-image-wrapper').remove();
    }
}

if ($('.js-add-car') != undefined) {
    $('.js-add-car').on('click', function () {
        $($('.js-add-cars').find('.add-cars-hidden')[0]).removeClass('add-cars-hidden');
        if ($('.js-add-cars').find('.add-cars-hidden').length < 1) $('.js-add-car').remove();
    });
}


if ($('.js-additional') != undefined) {
    $('.js-additional').on('click', function () {
        $('.js-additional').parent().find('.additional-hidden').removeClass('additional-hidden');
        this.remove();
    });
}

function sliderInit() {
    $(document).ready(function () {
        var slick_element = '.js-slick';
        if ($(slick_element).length) {
            $(slick_element).not('.slick-initialized').slick({
                arrows: false,
                swipeToSlide: true,
                swipe: true,
                infinite: false,
                variableWidth: true,
                slidesToShow: 1,
                adaptiveHeight: true,
                lazyLoad: 'ondemand'
            });
            resizeCarSlider();
        }
    });
}

sliderInit();

function resizeCarSlider() {
    var scale = 0.82,
        resize = 575,
        car_class = '.cars-list .car',
        img_elements = '.js-slick .sm-slick-car-photo, .js-slick .slick-item';
    if (window.innerWidth <= resize) $(img_elements).width($(car_class).width() * scale);
    $(window).on('resize', function () {
        if (window.innerWidth <= resize) $(img_elements).width($(car_class).width() * scale);
    })
}

function truncateCarName() {
    var elements = $('.sm-car-title');
    var length = 35;
    $.each(elements, function (index, el) {
        if ($(el).html().length > length) $(el).html($.truncate($(el).html(), length));
    })
}

truncateCarName();

checkBrowsers();

function checkBrowsers() {
    var elements = [
        '.cars-list .car',
        '.dynamic-banner-wrapper',
        '.static-banner-wrapper',
    ];
    if (isIE()) {
        for (var i = 0, n = elements.length; i < n; i++) {
            var el = $(elements[i]);
            if (el.length) {
                el.addClass('-ie');
            }
        }
    }

    if (isSafari()) {
        for (var i = 0, n = elements.length; i < n; i++) {
            var el = $(elements[i]);
            if (el.length) {
                el.addClass('-safari');
            }
        }
    }
}

function checkCarListStyle() {
    var cars_list = document.querySelectorAll(".cars-list");
    if (window.innerWidth < 992) {
        for (var i = 0; cars_list.length > i; i++) {
            var list = cars_list[i];
            list.classList.add('cars-block-sm')
        }
    } else {
        for (var i = 0; cars_list.length > i; i++) {
            var list = cars_list[i];
            list.classList.remove('cars-block-sm')
        }
    }
}

checkCarListStyle();
$(window).resize(function () {
    if (document.querySelector(".cars-list")) {
        checkCarListStyle();
    }
});
