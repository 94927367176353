(function($, window, document, undefined){
	
	function LookedObj(){
		var self = this;
		
		this._elem = null;
		this._top = 0;
		this._left = 0;
		this._right = 0;
		this._bottom = 0;
		
		this._isVisible = false;
		this._isReady = false;
		
		var readyTimer = null;
		
		function init(target){
			self._elem = $(target); 
			var offset = self._elem.offset();
			self._left = offset.left; 
			self._top = offset.top;
			self._right = offset.left + self._elem.outerWidth();
			self._bottom = offset.top + self._elem.outerHeight();
		}
		function getCoords(){
			return {
				left: self._left,
				right: self._right,
				top: self._top,
				bottom: self._bottom
			}
		}
		function startReadyTimer(){
			readyTimer = setTimeout(function(){
				self._isReady = true;
				trigger("lookAtReady");
			}, 1000);
		}
		function stopReadyTimer(){
			if(readyTimer != null){
				clearTimeout(readyTimer);
				readyTimer = null;
			}
		}
		function setVisible(value){
			if(self._isReady) return;
			if(self._isVisible && value) return;
			if(!self._isVisible && value){
				self._isVisible = true;
				trigger("lookAtStart");
				startReadyTimer();
			}
			if(self._isVisible && !value){
				self._isVisible = false;
				trigger("lookAtEnd");
				stopReadyTimer();
			}
		}
		function trigger(eventName){
			self._elem.trigger(eventName);
		}
		
		this.trigger = trigger;
		this.init = init;
		this.data = getCoords;
		this.setVisible = setVisible;
	}
	
	function LookAtMe(){
		var self = this;
		this._viewportWidth = 0;
		this._viewportHeight = 0;
		this._viewportTop = 0;
		this._viewportLeft = 0;
		this._viewportBottom = 0;
		this._viewportRight = 0;
		this._lookList = [];
		
		var isDebug = false;
		var debugContainer = null;
		
		var checkItemsTimer = null;
		
		function init(){
			updateAll();
			setUpdateEvents();
		}
		
		function updatePosition(){
			self._viewportTop = window.pageYOffset || document.documentElement.scrollTop;
			self._viewportLeft = window.pageXOffset || document.documentElement.scrollLeft;
			updateAdditionalParams();
			showDebug();
			delayedCheckItems();
		}
		function updateSize(){
			self._viewportWidth = $(window).width();
			self._viewportHeight = $(window).height();
			updateAdditionalParams();
			showDebug();
			delayedCheckItems();
		}
		function updateAdditionalParams(){
			self._viewportBottom = self._viewportTop + self._viewportHeight;
			self._viewportRight = self._viewportLeft + self._viewportWidth;
		}
		function updateAll(){
			updatePosition();
			updateSize();
		}
		function setUpdateEvents(){
			$(window).on("resize.look_at_me", function(){
				updateSize();
			});
			$(window).on("scroll.look_at_me", function(){
				updatePosition();
			});
		}
		
		function lookAt(target){
			var item = new LookedObj();
			item.init(target);
			self._lookList.push(item);
			delayedCheckItems();
		}
		function delayedCheckItems(){
			if(checkItemsTimer != null){
				clearTimeout(checkItemsTimer);
			}
			checkItemsTimer = setTimeout(function(){
				checkItems();
			}, 20);
		}
		function checkItems(){
			self._lookList.forEach(function(item, index){
				var coords = item.data();
				item.setVisible(
					coords.left >= self._viewportLeft
					&& coords.top >= self._viewportTop
					&& coords.right <= self._viewportRight
					&& coords.bottom <= self._viewportBottom
				);
			});
		}
		
		function setDebugContainer(target){
			isDebug = true;
			debugContainer = $(target);
			showDebug();
		}
		function showDebug(){
			if(isDebug){
				debugContainer.text("Позиция: "+self._viewportTop+", "+self._viewportRight+", "+self._viewportBottom+", "+self._viewportLeft);
			}
		}
		
		this.init = init;
		this.setDebugContainer = setDebugContainer; 
		this.lookAt = lookAt; 
	}
	
	
	$(window).on("load", function(){
		//if(location.hash != "#test") return;
		window.lam = new LookAtMe();
		window.lam.init();
		/*
		var debug_elem = $("<div>").css({position: "fixed", top: 0, left: 0, background: "#fff", padding: 5, zIndex: 99999}).text("TEST");
		$("body:first").append(debug_elem);
		
		var button = $(".look_at_button");
		window.lam.lookAt(button);

		// Обработка эвентов
		button.on("lookAtStart", function(){
			debug_elem.css({background: "#FFF5AD"});
		});
		button.on("lookAtEnd", function(){
			debug_elem.css({background: "#AEE3CC"});
		});
		button.on("lookAtReady", function(){
			debug_elem.css({background: "#1CE316"});
		});
		*/
	});
})(jQuery, window, document);