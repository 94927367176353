var Calc = function(){
	var container = null;
	var result_block = null;
	var process_button = null;
	
	//чтоб каждый раз селекторы не гонять
	var result_places = {
		'12_2': null,
		'12_full_2': null,
		'24_2': null,
		'24_full_2': null,
		'12_3': null,
		'12_full_3': null,
		'24_3': null,
		'24_full_3': null,
		'12_5': null,
		'12_full_5': null,
		'24_5': null,
		'24_full_5': null
	};
	var result_rows = {
		'2': null,
		'full_2': null,
		'3': null,
		'full_3': null,
		'5': null,
		'full_5': null
	};

	/*
	 //группы объемов двигателей
	 var cc_data = [
	 1599,        //group 0 1200-1599
	 1999,        //group 1 1600-1999
	 2000,        //group 2 2000-2999
	 Больше 3000  //group 3 >=3000
	 ];
	 //все модели кроме "Русских" и "прочих" в группе 2
	 [
	 'RUSSIAN BRANDS',  //group 0
	 'OTHER BRANDS',    //group 1
	 'TOYOTA',          //group 2
	 'FIAT'             //group 2
	 //....             //group 2
	 ]
	 */
	//info[месяцев_звезд][модель][объем]
	var info = {
		sold: [
			{
				stars: "2",
				is_full: false,
				delta: {
					main: 1,
					four_wd: 1.25,
					super_car: 1.5
				},
				prices: {
					'12_2': [
						[11985, 15774, 22272],
						[11985, 15774, 22272],
						[10332, 13545, 19051]
					],
					'24_2': [
						[21039, 27803, 39399],
						[21039, 27803, 39399],
						[18093, 22634, 32460]
					]
				}
			},
			{
				stars: "3",
				is_full: false,
				delta: {
					main: 1,
					four_wd: 1.25,
					super_car: 1.7
				},
				prices: {
					'12_3': [
						[15466, 21015, 30534],
						[14359, 19520, 28376],
						[12109, 16484, 23985]
					],
					'24_3': [
						[29637, 40369, 58766],
						[27499, 37538, 54676],
						[23182, 31654, 46180]
					]
				}
			},
			{
				stars: "5",
				is_full: false,
				delta: {
					main: 1,
					four_wd: 1.25,
					super_car: 1.7
				},
				prices: {
					'12_5': [
						[],
						[34803, 46980, 67858],
						[29495, 39815, 57507]
					],
					'24_5': [
						[],
						[64381, 86911, 125530],
						[54563, 73655, 106385]
					]
				}
			}
		],
		one_month: [
			{
				stars: "2",
				is_full: true,
				delta: {
					main: 1,
					four_wd: 1.25,
					super_car: 1.5
				},
				prices: {
					'12_full_2': [
						[9148, 12306, 17722],
						[9148, 12306, 17722],
						[7770, 10446, 15039]
					],
					'24_full_2': [
						[13066, 17580, 25320],
						[13066, 17580, 25320],
						[11098, 14924, 21482]
					]
				}
			},
			{
				stars: "3",
				is_full: true,
				delta: {
					main: 1,
					four_wd: 1.25,
					super_car: 1.7
				},
				prices: {
					'12_full_3': [
						[14198, 19321, 28106],
						[13176, 17942, 26115],
						[11098, 15138, 22063]
					],
					'24_full_3': [
						[28370, 38674, 56338],
						[26316, 35959, 52414],
						[22171, 30307, 44258]
					]
				}
			},
			{
				stars: "5",
				is_full: true,
				delta: {
					main: 1,
					four_wd: 1.25,
					super_car: 1.7
				}
				,
				prices: {
					'12_full_5': [
						[],
						[27841, 37585, 54286],
						[23595, 31853, 46004]
					],
					'24_full_5': [
						[],
						[51504, 69528, 100423],
						[43650, 58923, 85107]
					]
				}
			}
		]
	};

	function preparePrice(val, _default){
		val = val.replace(/[^0-9]+/g, "");
		return isNaN(val*1) ? _default : val*1;
	}
	function prepareValue(val, _default){
		return isNaN(val*1) ? _default : val*1;
	}
	function showRow(stars_index, is_full){
		result_rows[(is_full ? "full_" : "") + stars_index].removeClass("hidden");
		result_rows[(!is_full ? "full_" : "") + stars_index].addClass("hidden");
	}
	/*
	 data['cc'] - индекс объема: 0, 1, 2, 3 (Если > 2, то приравниваем к 2);
	 data['model'] - индекс модели
	 */
	function count(data){
		var volume_index = prepareValue(data['volume_index'], 0);
		var model_index = data['model_index'] != null ? prepareValue(data['model_index'], 0) : null;
		var price = preparePrice(data['price'], 0);
		var garantee_type = data['garantee_type'] || "sold";
		var is_4wd = prepareValue(data['is_4wd'], 0) == 1;
		var is_super_car = prepareValue(data['is_super_car'], 0) == 1;
		var delta_code = 'main';
		if(is_4wd){
			delta_code = 'four_wd';
		}
		//цена, объем или суперкар
		if (price >= 1900000 || is_super_car || volume_index == 3){
			delta_code = 'super_car';
		}
		var active_volume_index = (volume_index > 2) ? 2 : volume_index;
		var current_info = info[garantee_type];
		
		for(var program_id in current_info){
			if(current_info.hasOwnProperty(program_id)){
				// Какая-то программа
				var current_program = current_info[program_id];
				var delta = current_program['delta'][delta_code];
				showRow(current_program['stars'], current_program['is_full']);
				for(var place_id in current_program['prices']){
					if(current_program['prices'].hasOwnProperty(place_id)){
						var place = current_program['prices'][place_id];
						if(model_index != null && place[model_index] && place[model_index][active_volume_index]){
							result_places[place_id].text(fw_MAMMON.formattedNumber(place[model_index][active_volume_index] * delta, 2)+" руб.")
						}
						else{
							result_places[place_id].text('Не определено');
						}
					}
				}
			}
		}
	}

	var onSubmit = function(){
		process_button.blur();
		var data = fw_MAMMON.serializeObjSmart(container);
		count(data);
		result_block.removeClass("hidden");
		$("html, body").scrollTo(result_block, 300, {offset:{top:-60 }});
	};

	return {
		init: function(){
			container = $('#garantee_calc');
			process_button = container.find('.process_button');
			result_block = $('.result_block');
			for(var place_id in result_places){
				if(result_places.hasOwnProperty(place_id)) {
					result_places[place_id] = $('#value_' + place_id);
				}
			}
			for(var row_id in result_rows){
				if(result_rows.hasOwnProperty(row_id)) {
					result_rows[row_id] = $('.row_' + row_id);
				}
			}
			container.on("submit", function(){
				onSubmit();
			});
			process_button.click(function(){
				container.submit();
			});
		}
	}
}();

$(function(){
	Calc.init();
	INIT.initFormElements();
});