function MeYaMap(block_id) {
	var $map = this;
	var meMap = null;
	var placeMarks = {};
	this.setCity = function (center_lat, center_lng, zoom) {
		zoom = zoom || 15;
		meMap = new ymaps.Map(block_id, {
			center: [center_lat, center_lng],
			zoom: zoom
		});
	};
	this.setPlacemark = function (code, mark_lat, mark_lng, link, title, address, work_time, phone, cars_link) {
		var placemark = new ymaps.Placemark(
			[mark_lat, mark_lng],
			{
				balloonContentHeader: '<a class="contact_link link_title" href="' + link + '"><span>' + title + '</span></a>',
				balloonContentBody: '<span class="contact_content">' +
				'<span class="address">' + address + '</span>' +
				'<span class="work_time">' + work_time + '</span>' +
				'<span class="phone">' + phone + '</span>' +
				(cars_link ? '<a class="cars_link link_title" href="' + cars_link + '"><span>Посмотреть предложения филиала</span></a>' : '') +
				'</span>'
			},
			{
				iconLayout: 'default#image',
				iconImageHref: '/files/resources/images/map_placemark.png',
				iconImageSize: [38, 47],
				iconImageOffset: [-19, -44]
			}
		);
		placeMarks[code] = placemark;
		meMap.geoObjects.add(placemark);
	};
	this.reInit = function () {
		if (meMap != null) {
			meMap.container.fitToViewport();
		}
	};
	this.open = function (code) {
		if (placeMarks[code]) {
			placeMarks[code].balloon.open(meMap.getCenter());
		}
	}
}

var INIT = function () {
	var $init = {};
	var fixCarsListCount = 0;
	$init = {
		setFixedPanel: function () {
			$(".search_panel").setAutoFixed({
				full_screen: true
			});
		},
		setToolTips: function () {
			$('[data-toggle="tooltip"], .has_tooltip').tooltip();
		},
		setPageAdditionalMenu: function () {
			$(".additional_page_menu .menu-header").click(function () {
				$(this).closest(".additional_page_menu").toggleClass("visible_menu");
			});
		},
		setBCPopOvers: function () {
			var bc_popovers_collection = $('[data-toggle="breadcrumbs_popover"]');
			bc_popovers_collection.popover({
				container: '.bc_popover_container',
				trigger: 'manual',
				content: function () {
					return $(this).closest(".has_dropdown").find(".bc_popover_data").html();
				},
				html: true,
				placement: "bottom"
			});
			bc_popovers_collection.on('click', function () {
				if (!$(this).hasClass('opened')) {
					bc_popovers_collection.filter(".opened").popover('hide');
					$(this).popover('show');
					$(document).off('.bc_popover');
					$(document).on('click.bc_popover touchstart.bc_popover', function (event) {
						var target = event.target || event.srcElement;
						var hide_popover = true;
						while (target != this) {
							if (target.classList.contains("popover-content")) {
								hide_popover = false;
								break;
							}
							target = target.parentNode;
						}
						if (hide_popover) {
							bc_popovers_collection.popover('hide');
							$(document).off('.bc_popover');
						}
					});
				}
				return false;
			});

			bc_popovers_collection.on('hidden.bs.popover', function () {
				$(this).removeClass("opened");
			});
			bc_popovers_collection.on('shown.bs.popover', function () {
				$(this).addClass("opened");
			});

			var bcSorter = new MultipleSortContainer();
			bcSorter.init($(".bc_container"), ".multy_columns_bc");
			bcSorter.setQuery('(min-width: 768px)', 3);
		},
		setPopOverFixer: function () {
			var popover_fix_timer = null;

			function fixPopoverDelayed() {
				if (popover_fix_timer != null) {
					clearTimeout(popover_fix_timer);
				}
				popover_fix_timer = setTimeout(function () {
					$(".bc_container .opened").popover('show');
				}, 300);
			}

			$(".custom_menu_toggle").click(function () {
				$(this).toggleClass("opened");
			});
			$(window).resize(function () {
				fixPopoverDelayed();
			});
		},
		setCardPopOvers: function () {
			var prepay_button = $("html:not(.is_ipad):not(.is_iphone) .car_card_prepay_button");
			prepay_button.popover({
				container: '.car_card',
				trigger: 'hover',
				content: function () {
					return $(this).closest(".prepay_button_container").find(".prepay_notes_container").html();
				},
				html: true,
				placement: "bottom"
			});

			var bcSorter = new MultipleSortContainer();
			bcSorter.init($(".bc_container"), ".multy_columns_bc");
			bcSorter.setQuery('(min-width: 768px)', 3);
		},
		setCarousel: function () {
			var carousel = $("#myCarousel");
			carousel.swiperight(function () {
				$(this).carousel('prev');
			});
			carousel.swipeleft(function () {
				$(this).carousel('next');
			});
			carousel.click(function () {
				$(this).carousel('pause');
			});
		},
		setRootAdditionalText: function () {
			$(".root_text_content .show_more").click(function () {
				$(this).closest(".root_text_content").addClass("opened");
				$(this).hide();
			});
		},
		setBigSeoAdditionalText: function () {
			$(".big_seo_text_content .show_more").click(function () {
				$(this).closest(".big_seo_text_content").addClass("opened");
				$(this).hide();
			});
		},
		setEstimationBlock: function () {
			var estContainer = $(".est_car");
			// Инпуты:
			var brands_target_selector = estContainer.find(".root_estimation_brands");
			getBrandsListForSelector(brands_target_selector);
			setMappingWithModels(brands_target_selector, estContainer.find(".root_estimation_models"));
		},

		faqSearch: function () {
			var faq_search_form = $(".search_questions_panel");
			var faq_results_container = $(".faq_questions_container");
			var start_search_button = faq_search_form.find(".faq_start_search_button");
			var search_result_str_container = faq_search_form.find(".search_result_text_container");
			var cat_block = $(".faq_help_block");

			function emptySearchModifications() {
				cat_block.find("h2").hide();
				cat_block.find(".description").hide();
				cat_block.find(".empty_search_description").show();
			}

			function normalSearchModifications() {
				cat_block.find("h2").show();
				cat_block.find(".description").show();
				cat_block.find(".empty_search_description").hide();
			}

			function getResult(page) {
				page = page || 1;
				fw_MOJAX.send({
					to: faq_results_container,
					form: faq_search_form,
					data: {
						page: page
					},
					user_success: function (data, user_data) {
						if (data['has_questions']) {
							search_result_str_container.html('Результаты поиска по “<span class="search_text">' + fw_MAMMON.escapeHtml(data['search']) + '</span>”');
							normalSearchModifications();
						}
						else {
							search_result_str_container.html('По запросу “<span class="search_text">' + fw_MAMMON.escapeHtml(data['search']) + '</span>” ничего не найдено');
							emptySearchModifications();
						}
					},
					overlay_to: $(faq_results_container, faq_search_form)
				});
			}

			start_search_button.on('click', function () {
				faq_search_form.submit();
			});
			faq_search_form.on("submit", function () {
				getResult();
			});
			faq_results_container.on('click', ".search_pagination .numeric_pagination_button:not(.disabled)", function () {
				getResult($(this).data('page'));
			});
		},
		setInputsEvents: function () {
			$(".content_smart_input").closest('.input_container').smartFocus().click(function () {
				$(this).find("input").focus();
			});
		},

		initIkSelect: function (additional_class, toParentDrop = false) {

			additional_class = additional_class || "";
			// Ставим установки для селектора
			$.ikSelect.extendDefaults({
				dynamicWidth: false,
				ddFullWidth: false,
				autoWidth: false,

				onShow: function (inst) {
					inst.$link.addClass("active");

					if(toParentDrop) {
						//прикидываем кастомный селект в родительский dom
						$(inst.$dropdown).appendTo(inst.$wrapper);
						$(inst.$dropdown).css({
							left: 0,
							top: '100%'
						})
					}
					function fixDropDownPosition() {
						if (inst.$wrapper.is(":visible")) {
							var dropdownOffset = inst.getDropDownPosition();
							inst.$dropdown.css({
								left: dropdownOffset.left,
								top: dropdownOffset.top
							});
						}
						else {
							inst.hideDropdown();
						}
					}

					// Тут запускаем систему слежения за скроллом
					$(window).on('resize.ikFixDropdown', function () {
						inst.hideDropdown();
					});
					$(window).on('scroll.ikFixDropdown resize.ikFixDropdown', function () {
						fixDropDownPosition();
					});
					$(".fw_win_wrapper").on('scroll.ikFixDropdown', function () {
						fixDropDownPosition();
					});
				},
				onHide: function (inst) {
					inst.$link.removeClass("active");
					// Тут вырубаем систему слежения за скроллом
					$(window).off('.ikFixDropdown');
					$(".fw_win_wrapper").off('.ikFixDropdown');
				},
				syntax: '' +
				'<a href="#" onclick="return false;" class="super_button selector_btn block_button ' + additional_class + ' ik_select_link">' +
				'<span class="gradient"></span>' +
				'<span class="button_label no_icon ik_select_link_text"></span>' +
				'<span class="arrow"></span>' +
				'</a>' +
				'<div class="ik_select_dropdown">' +
				'	<div class="ik_select_list"></div>' +
				'</div>'
			});
		},

		initSelectors: function () {
			$init.initIkSelect();
			$(".content_custom_selector:not(.ready)").addClass("ready").ikSelect('redraw');
		},

		initDatepicker: function () {
			$('.datepicker_target:not(.ready)').addClass("ready").each(function () {
				var datePicker = $(this);
				var datePickerInput = datePicker.find('input');
				var timePicker = $('.timepicker_target');
				datePickerInput.datepicker({
					inline: true,
					minDate: new Date(),
					beforeShow: function (input, inst) {
						timePicker.trigger('close');
						datePicker.addClass("active");
					},
					onSelect: function (date_text, date_picker) {
						// Сгенерить событие формджора.
						datePickerInput.trigger('change');
					},
					onClose: function () {
						datePicker.removeClass("active");
					}
				});

				datePicker.click(function () {
					datePickerInput.datepicker('show');
				});
			});
		},

		initTimepicker: function () {
			$('.timepicker_target:not(.ready)').addClass("ready").each(function () {
				var timePicker = $(this);
				var timePickerInput = timePicker.find("input");
				var timePickerInstance = timePickerInput.timepicker({
					countColumn: 1,
					useBackground: false,
					beforeShow: function () {
						timePicker.addClass("active");
					},
					afterHide: function () {
						timePicker.removeClass("active");
					}
				});
				timePicker.click(function (event) {
					event.stopPropagation();
					event.preventDefault();
					timePickerInstance.changePosition();
					timePickerInstance.show();
				});
			});
		},

		setCardCols: function () {
			var mark_class_prefix = "object_mark_place_";
			$('[data-mark-class^='+ mark_class_prefix + ']').each(function (index, value) {
				var mark_class = $(value).data('mark-class');
				var el = $('.' + mark_class);
				if (el.length) {
					el.replaceWith($(value).clone());
				}
			});
		},
		initSmartAppBanner: function () {
			$.smartbanner({
				daysHidden: 15,
				daysReminder: 15,
				button: "Установить",
				title: "Удобнее и быстрее",
				price: "Major Auto ",
				inGooglePlay: "для Android",
				inAppStore: "для iPhone"
			});
		},

		setContactsTabs: function () {
			var MAP_VIEW = "map";
			var LIST_VIEW = "list";
			var views = {};
			views[MAP_VIEW] = {
				name: "Карта"
			};
			views[LIST_VIEW] = {
				name: "Список"
			};
			var last_view = LIST_VIEW;
			var current_view = LIST_VIEW;

			// Пишем функцию определения текущего вида от хэша
			function analyzeContactsHash() {
				if (!/^#contacts:/.test(location.hash)) {
					return;
				}
				var view_hash = location.hash.replace(/^#contacts:/, "");
				last_view = current_view;
				current_view = views[view_hash] ? view_hash : LIST_VIEW;
				if (last_view != current_view) {
					setNewView();
				}
			}

			function setNewView() {
				$(".contacts_change_view_type .selected").removeClass("selected");
				$(".contacts_change_view_type a[href='#contacts:" + current_view + "']").addClass("selected");
				$(".salons_" + last_view).hide();
				$(".salons_" + current_view).show();
				$(".contacts_city_select_block a").each(function () {
					this.href = this.href.replace(/#.*$/, "") + "#contacts:" + current_view;
				});
				if (current_view == MAP_VIEW) {
					$init.contactsMaps.reInit();
				}
			}

			// Вешаем событие на смену хэша и запускаем её сразу
			$(window).on('hashchange', function () {
				analyzeContactsHash();
			});
			analyzeContactsHash();

			// XS-LD
			Harvey.attach('(max-width: 767px) and (orientation: landscape)', {
				on: function () {
					$(".salons_list .salon_block_height_fix_container").css({height: ""});
					$(".salons_list").fixHeight(".salon_block_height_fix_container", 2);
				},
				off: function () {
					$(".salons_list .salon_block_height_fix_container").css({height: ""});
				}
			});
		},

		contactsMaps: function () {
			return new MeYaMap("map");
		}(),

		setCarBenefitsSlider: function () {
			var slider = $(".benefits_slider");
			var list = slider.find(".benefits_list");
			var collection = list.find(".benefit_card");
			var next_button = slider.find(".next");
			var prev_button = slider.find(".prev");
			var current_index = 0;
			var current_offset = 0;
			var count = collection.length;

			function updateCurrentOffset() {
				current_offset = $(collection.get(current_index)).position().left;
			}

			function updateInterface() {
				next_button.removeClass("disabled");
				prev_button.removeClass("disabled");
				if (isLast()) {
					next_button.addClass("disabled");
				}
				if (isFirst()) {
					prev_button.addClass("disabled");
				}
			}

			function isFirst() {
				return current_index == 0;
			}

			function isLast() {
				return current_index == (count - 1);
			}

			function next() {
				if (isLast()) return;
				current_index++;
				updateCurrentOffset();
				updateCurrentPosition();
				updateInterface();
			}

			function prev() {
				if (isFirst()) return;
				current_index--;
				updateCurrentOffset();
				updateCurrentPosition();
				updateInterface();
			}

			function updateCurrentPosition() {
				list.css({marginLeft: -current_offset});
			}

			function init() {
				updateCurrentOffset();
				updateCurrentPosition();
				if (count > 1) {
					next_button.removeClass("hidden");
					prev_button.removeClass("hidden");
					updateInterface();
					next_button.click(function () {
						next();
					});
					prev_button.click(function () {
						prev();
					});
					slider.swiperight(function () {
						prev();
					});
					slider.swipeleft(function () {
						next();
					});
				}
				// XS-PT
				Harvey.attach('(max-width: 767px) and (orientation: portrait)', {
					on: function () {
						updateCurrentOffset();
						updateCurrentPosition();
					}
				});
				// XS-LD
				Harvey.attach('(max-width: 767px) and (orientation: landscape)', {
					on: function () {
						updateCurrentOffset();
						updateCurrentPosition();
					}
				});
				// SM
				Harvey.attach('(min-width: 768px) and (max-width: 991px)', {
					on: function () {
						updateCurrentOffset();
						updateCurrentPosition();
					}
				});
				// MD
				Harvey.attach('(min-width: 992px) and (max-width: 1365px)', {
					on: function () {
						updateCurrentOffset();
						updateCurrentPosition();
					}
				});
				// LG
				Harvey.attach('(min-width: 1366px)', {
					on: function () {
						updateCurrentOffset();
						updateCurrentPosition();
					}
				});
			}

			if (slider.length > 0) {
				init();
			}
		},

		setOptionsGroups: function() {
			$('.options_group_title').click(
					function () {
						var list = $(this).siblings('.options_list'),
								curHeight = list.height(),
								autoHeight = list.css('height', 'auto').height(),
								duration = 400;

						$(this).toggleClass('closed');

						if (list.hasClass('open')) {
							list.height(curHeight).animate({height: 0}, duration);
						} else {
							list.height(curHeight).animate({height: autoHeight}, duration, function () {
								list.css('height', 'auto');
							});
						}

						list.toggleClass('open');
					}
			);
		},

		handleCarCardEvents: function () {
			$(document).ready(function () {
				switch (window.location.hash) {
					case '#callback':
						$('.js_open_callback_calltouch_form').first().trigger('click');
						break;
					default:
						break;
				}

				if ($('#views_block').length && (window.innerWidth > 767)) {
					var mopup = Mopup.createInstance({
						auto_close_time: 35,
						custom_class: 'major_expert_mopup views_panel',
						close_button_class: 'close_mopup',
						content: $('#views_block').html(),
						action: 'slide',
						timeout: 5000
					}).showMopup(function () {
						$('.views_block .buy_car').click(function (event) {
							if ($('.open_pre_pay_window').length) {
								$('.open_pre_pay_window').first().trigger('click');
							} else {
								$('.js_open_callback_calltouch_form').first().trigger('click');
							}
							fw_MAMMON.preventHref(event);
						});
					});
				}
			})
		},
		setFormInputs: function () {
			$(".field_wrapper:not(.ready) .super_input:has(input[type=text])").addClass("ready").smartFocus().click(function () {
				$(this).find("input[type=text]").focus();
			});
		},
		initPhoneMask: function () {
			$(".field_wrapper .super_input input[name=phone]").inputmask('+7 (999) 999-99-99');
		},
		clearCarsListFix: function () {
			$(".cars_list .car_inline_content").css({height: ""});
			$(".cars_list .car-fix-height").css({height: ""});
			$(".cars_list.fix-blocks .car-fix-height").css({height: ""});
		},
		fixCarsList: function () {
			if (fixCarsListCount > 0) {
				$init.clearCarsListFix();
				if ($("[data-wrapper-type=2]").hasClass("selected")) {
					$(".cars_list").fixHeight(".car_inline_content", fixCarsListCount, true);
					$(".cars_list.fix-blocks").fixHeight(".car-fix-height", fixCarsListCount, true);
				}
				$(".js-fixed-height-cars .cars_list.blocks").fixHeight(".car_inline_content", fixCarsListCount, true);
				$(".js-fixed-height-cars .cars_list.blocks").fixHeight(".car-fix-height", fixCarsListCount, true);
			}
		},
		initCarsListFixer: function () {
			Harvey.attach('(max-width: 991px)', {
				on: function () {
					fixCarsListCount = 0;
					$init.clearCarsListFix();
				}
			});
			Harvey.attach('(min-width: 992px) and (max-width: 1365px)', {
				on: function () {
					fixCarsListCount = 18;
					$init.fixCarsList();
				}
			});
			Harvey.attach('(min-width: 1366px)', {
				on: function () {
					fixCarsListCount = 18;
					$init.fixCarsList();
				}
			});
		},
		/* =================================== */

		initMain: function () {
			fw_MAMMON.markBodyByDevice();
			fw_MAMMON.fixBodyScroll.setNewFix();
			fw_MAMMON.smartTouchHover();
			$init.initSmartAppBanner();
			$init.setFixedPanel();
			$init.setToolTips();
			$init.setPageAdditionalMenu();
			$init.setBCPopOvers();
			$init.setPopOverFixer();
			$init.initCarsListFixer();
		},

		initFormElements: function () {
			$init.setFormInputs();
			$init.initPhoneMask();
			$init.initSelectors();
			$init.initDatepicker();
			$init.initTimepicker();
		},
		initEstimationFormElements: function() {
			$init.setFormInputs();
			$init.initPhoneMask();
			$init.initIkSelect(false, true);
			$(".content_custom_selector:not(.ready)").addClass("ready").ikSelect('redraw');
			$init.initDatepicker();
			$init.initTimepicker();
		},
		initMotoEstimationFormElements: function () {
			$init.setFormInputs();
			$init.initPhoneMask();
			$init.initIkSelect(false, true);
			$(".content_custom_selector:not(.ready)").addClass("ready").ikSelect('redraw');
			$init.initDatepicker();
			$init.initTimepicker();
		},
		initCarCard: function (outXML3D, inXML3D, src, type) {
			$init.setCardPopOvers();
			$init.setCardCols();
			$init.setCarBenefitsSlider();
			$init.handleCarCardEvents();
			$init.yaShare();
			if (arguments.length) {
				$init.initGallery3D(outXML3D, inXML3D, src, type);
			}
		},

		runInsidePanoEmbedding: function (inXML3D) {
			embedpano({
				xml: inXML3D,
				target: "pano_int",
				passQueryParameters: true,
				html5: "only",
				onready: function () {
					//неплохо неплохо
				}
			});
		},

		initPanellum: function (data) {
			runPannellum(data, 'interior_panorama');
		},

		initYandexPanorama: function () {
			_YANDEX_PANORAMA.init();
		},

		initRoot: function () {
			$init.setCarousel();
			$init.initSelectors();
			$init.setRootAdditionalText();
			$init.setEstimationBlock();
		},

		initRoute: function () {
			Harvey.attach('(min-width: 768px) and (max-width: 991px)', {
				on: function () {
					$(".route_page_content").fixHeight(".fixed-height-sm", 3);
				},
				off: function () {
					$(".route_page_content .fixed-height-sm").css({height: ""});
				}
			});
		},

		initContacts: function () {
			$init.setContactsTabs();
		},

		initContactsCard: function () {
			$(".contacts_additional_container").fixHeight(".data_block_content", 2);
			Harvey.attach('(max-width: 767px) and (orientation: portrait)', {
				on: function () {
					$(".contacts_additional_container .data_block_content").css({height: ""});
				},
				off: function () {
					$(".contacts_additional_container").fixHeight(".data_block_content", 2);
				}
			});
			Harvey.attach('(min-width: 1366px)', {
				on: function () {
					$(".contacts_additional_container .data_block_content").css({height: ""});
				},
				off: function () {
					$(".contacts_additional_container").fixHeight(".data_block_content", 2);
				}
			});
		},

		initNews: function () {
			$(window).load(function () {
				Harvey.attach('(max-width: 767px) and (orientation: portrait)', {
					on: function () {
						$(".news_list .news_list_block").css({height: ""});
					}
				});
				Harvey.attach('(max-width: 767px) and (orientation: landscape)', {
					on: function () {
						$(".news_list .news_list_block").css({height: ""});
						$(".news_list").fixHeight(".news_list_block", 2);
					}
				});
				Harvey.attach('(min-width: 768px) and (max-width: 1365px)', {
					on: function () {
						$(".news_list .news_list_block").css({height: ""});
						$(".news_list").fixHeight(".news_list_block", 2);
					}
				});
				Harvey.attach('(min-width: 1366px)', {
					on: function () {
						$(".news_list .news_list_block").css({height: ""});
						$(".news_list").fixHeight(".news_list_block", 3);
					}
				});
			});
		},

		initFaq: function () {
			$init.setInputsEvents();
			$init.initSelectors();
			$init.faqSearch();
		},

		initBrandSelectPage: function () {
			var brandsSorter = new SortContainer();
			brandsSorter.init($(".page_brands_selector_container > .row"));
			brandsSorter.setQuery('(max-width: 767px) and (orientation: portrait)', 1);
			brandsSorter.setQuery('(max-width: 767px) and (orientation: landscape)', 2);
			brandsSorter.setQuery('(min-width: 768px) and (max-width: 991px)', 3);
			brandsSorter.setQuery('(min-width: 992px) and (max-width: 1365px)', 4);
			brandsSorter.setQuery('(min-width: 1366px)', 6);
			$init.setBigSeoAdditionalText();
		},

		initModelSelectPage: function () {
			var modelsSorter = new SortContainer();
			modelsSorter.init($(".page_models_selector_container > .row"));
			modelsSorter.setQuery('(max-width: 767px) and (orientation: portrait)', 1);
			modelsSorter.setQuery('(max-width: 767px) and (orientation: landscape)', 2);
			modelsSorter.setQuery('(min-width: 768px) and (max-width: 991px)', 3);
			modelsSorter.setQuery('(min-width: 992px) and (max-width: 1365px)', 4);
			modelsSorter.setQuery('(min-width: 1366px)', 6);
		},

		initSpecialSelectPage: function () {
			var specialsSorter = new SortContainer();
			specialsSorter.init($(".specials_selector_list > .row"));
			specialsSorter.setQuery('(max-width: 767px)', 1);
			specialsSorter.setQuery('(min-width: 768px) and (max-width: 1365px)', 2);
			specialsSorter.setQuery('(min-width: 1366px)', 3);
		},

		initTradeInPages: function () {
			var opened_class = 'open';
			var event_namespace = 'quick_navigation_event';

			function closeAllQuickNavigationBlocks() {
				$('.quick_navigation_block').removeClass(opened_class);
				unSetHookOnDocumentClick(event_namespace);
			}

			function setHookOnDocumentClick(namespace, func) {
				$(document).bind('click.' + namespace, func);
			}

			function unSetHookOnDocumentClick(namespace) {
				$(document).unbind('click.' + namespace);
			}

			$('.quick_navigation_selection').click(function () {
				var quick_navigation_block = $(this).closest('.quick_navigation_block');
				if (quick_navigation_block.hasClass(opened_class)) {
					quick_navigation_block.removeClass(opened_class);
					unSetHookOnDocumentClick(event_namespace);
				}
				else {
					closeAllQuickNavigationBlocks();
					quick_navigation_block.addClass(opened_class);
					setHookOnDocumentClick(event_namespace, closeAllQuickNavigationBlocks);
				}
				return false;
			});
		},
		initSimplePage: function () {
			$('.show_answer_button').click(function () {
				$(this).closest('.question_block').addClass('opened');
				return false;
			});
		},
		yaShare: function () {
			try {
				Ya.share2(document.getElementsByClassName('ya-share2')[0], {
					contentByService: {
						twitter: {
							title: $('<div/>').html($('title').html()).text().substring(0, 113) + '...'
						}
					}
				});
			}
			catch (err) {
			}
		},
		initGallery3D: function (outXML3D, inXML3D, src, type) {
			$('.pano_object').one('click', function () {//иницилизация экстерьера
				obj = new object2vrPlayer("pano");
				skin = new object2vrSkin(obj);
				obj.readConfigUrl(outXML3D);
			});
			$('.pano_inside').one('click', function () {//иницилизация интерьера
				switch (type) {
					case 'mono':
						$init.initPanellum($('#interior_panorama').length ? $('#interior_panorama')[0].dataset : {});
						break;
					case 'yandex':
						$init.initYandexPanorama();
						break;
					default:
						$init.runInsidePanoEmbedding(inXML3D);
						break;

				}
			});
			$(window).resize(function () {
				if (window.innerWidth < 767) {
					var height = (window.innerWidth - 30) / 1.5;
					$('#pano, #pano_int, #interior_panorama').height(height);
				}
				else {
					$('#pano, #pano_int, #interior_panorama').removeAttr('style');
				}
			});
			$('.visual_thumb').click(function (e) {
				$('.visual_thumb').removeClass('active');
				$(e.currentTarget).addClass('active');
				$('.visual_blocks > *').removeClass('active');
				switch (e.currentTarget.dataset.target) {
					case "photo":
						$('.visual_blocks .gallery-container').show().addClass('active');
						$('.visual_blocks .gallery-container img').eq(2).trigger('click');
						break;
					case "open":
						$('.visual_blocks #pano').addClass('active');
						$('#pano div.ggskin.ggskin_button').eq(0).trigger('click');
						break;
					case "closed":
						$('.visual_blocks #pano').addClass('active');
						$('#pano div.ggskin.ggskin_button').eq(1).trigger('click');
						break;
					case "interior":
						$('.visual_blocks #pano_int').addClass('active');
						break;
				}
				$(window).resize();
			});
		},
		carsCompareTable: function () {

			$('.cars_compare').compareTable({
				correctTopFixedHeader: $('.search_panel').height(),
				initFunction: function () {
					var setPadding = $('.cars_compare .fixed_header .car_content .car_desc').innerHeight();
					$('.cars_compare .fixed_header .car_content').attr('data-def-padding', setPadding)
						.css('paddingBottom', setPadding);

					var carImage = $('.cars_compare .fixed_header .car_image'),
						setTop = carImage.offset().top - $('.cars_compare .fixed_header').offset().top
							+ (carImage.height() / 2)
							- ($('.cars_compare .fixed_header .slider_navigation .button').height() / 2);
					$('.cars_compare .fixed_header .slider_navigation').css('top', setTop);
				},
				scrollFunction: function () {
					var maxPadding = parseInt($('.cars_compare .fixed_header .car_content').data('def-padding')),
						setPadding = maxPadding + ($('.cars_compare .cars_compare_table > * > *').offset().top - $(window).scrollTop() - $('.search_panel').height());

					setPadding = setPadding < 0 ? 0 : setPadding;
					setPadding = setPadding > maxPadding ? maxPadding : setPadding;
					$('.cars_compare .fixed_header .car_content').css('paddingBottom', setPadding);

					var carImage = $('.cars_compare .fixed_header .car_image'),
						button = $('.cars_compare .fixed_header .slider_navigation .button'),
						setTop = carImage.offset().top - $('.cars_compare .fixed_header').offset().top
							+ (carImage.height() / 2)
							- (button.height() / 2);

					var k = $('.cars_compare .fixed_header .car_desc').offset().top - carImage.offset().top
						- $('.cars_compare .fixed_header').offset().top + carImage.offset().top
						- setTop
						- button.height();
					k = k > 0 ? 0 : k;


					$('.cars_compare .fixed_header .slider_navigation').css('top', setTop + k);

				},
				onHide: function () {
					var mopup = Mopup.createInstance({
						auto_close_time: 20,
						custom_class: 'undo_panel major_expert_mopup',
						close_button_class: 'close_mopup',
						content: 'Автомобиль убран <a href="#" class="white_dotted_link close_mopup"><span>вернуть</span></a> <div class="close_mopup x_close_button"></div>'
					});
					$('.undo_panel .white_dotted_link').click(
						function (event) {
							$(document).trigger('undoHideCar');
							fw_MAMMON.preventHref(event);
						}
					);
					mopup.showMopup();
				}
			});

		}
	};
	return $init;
}();
