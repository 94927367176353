function disableRegisterInputs() {
	$('input[name=org]').attr('disabled', 'disabled');
	$('input[name=name]').attr('disabled', 'disabled');
	$('input[name=surname]').attr('disabled', 'disabled');
	$('input[name=patronymic]').attr('disabled', 'disabled');
	$('input[name=mail]').attr('disabled', 'disabled');
	$('input[name=phone]').attr('disabled', 'disabled');
	$('[name=gender]').ikSelect('disable');
	$('[name=gender]').closest('.form_gender_block').addClass('disabled');
}


function enableRegisterInputs() {
	$('input[name=org]').removeAttr('disabled');
	$('input[name=name]').removeAttr('disabled');
	$('input[name=surname]').removeAttr('disabled');
	$('input[name=patronymic]').removeAttr('disabled');
	$('input[name=mail]').removeAttr('disabled');
	$('input[name=phone]').removeAttr('disabled');
	$('[name=gender]').ikSelect('enable');
	$('[name=gender]').closest('.form_gender_block').removeClass('disabled');
}


function disableAdditionalFields() {
	$('input[name=city]').attr('disabled', 'disabled');
	$('input[name=site]').attr('disabled', 'disabled');
	$('input[name=cars_per_month]').attr('disabled', 'disabled');
	$('input[name=address]').attr('disabled', 'disabled');
	$('.submit_buttons_wrapper').addClass('disabled');
}

function enableAdditionalFields() {
	$('input[name=city]').removeAttr('disabled');
	$('input[name=site]').removeAttr('disabled');
	$('input[name=cars_per_month]').removeAttr('disabled');
	$('input[name=address]').removeAttr('disabled');
	$('.submit_buttons_wrapper').removeClass('disabled');
}

function checkRegisterFields() {
	var form_correct = true;
	$('.register_form_wrapper .field_wrapper').removeClass('formjor-err');

	if ($('input[name=org]').val().length <= 0) {
		form_correct = false;
		$('input[name=org]').closest('.field_wrapper').addClass('formjor-err');
	}

	if ($('input[name=name]').val().length <= 0) {
		form_correct = false;
		$('input[name=name]').closest('.field_wrapper').addClass('formjor-err');
	}
	if ($('input[name=surname]').val().length <= 0) {
		form_correct = false;
		$('input[name=surname]').closest('.field_wrapper').addClass('formjor-err');
	}
	if ($('input[name=patronymic]').val().length <= 0) {
		form_correct = false;
		$('input[name=patronymic]').closest('.field_wrapper').addClass('formjor-err');
	}
	if ($('input[name=mail]').val().length <= 0) {
		form_correct = false;
		$('input[name=mail]').closest('.field_wrapper').addClass('formjor-err');
	}
	if ($('input[name=phone]').val().replace(/\D/g,'').length != 11) {
		form_correct = false;
		$('input[name=phone]').closest('.field_wrapper').addClass('formjor-err');
	}
	return form_correct;
}


$(document).ready(function () {
	$('.form_wrapper.register_form_wrapper').on('additional_form_send', function () {
		$('.form_wrapper.register_form_wrapper').hide();
	});


	disableAdditionalFields();
	$('.button_accept_phone, .send_again_sms_code').click(function () {
		if (checkRegisterFields()) {
			var phone = $('[name=phone]').val().replace(/( |-|\+7|\(|\)|\+)/ig, '');
			fw_MOJAX.send({
				url: '/ajax/forms/sendSmsToAccess/',
				overlay_type: fw_MOJAX.FULL_OVERLAY,
				data: {
					phone: phone
				},
				user_success: function (json) {
					if (json.isSmsSended) {
						disableRegisterInputs();
						$('.register_button_wrapper').hide();
						$('.change_user_data_wrapper').show();
						$('.enter_sms_code_wrapper').show();
					} else {
						alert('Ошибка отправки SMS');
					}

				},
				user_fail: function () {
					alert('Ошибка отправки SMS');
				},
				user_error: function () {
					alert('Ошибка отправки SMS');
				}
			});
		}
	});

	$('.change_user_data').click(function () {
		enableRegisterInputs();
		disableAdditionalFields();
		$('.register_button_wrapper').show();
		$('.change_user_data_wrapper').hide();
		$('.enter_sms_code_wrapper').hide();
	});

	$('.send_sms_code').click(function () {
		var smsCode = $('input[name=code_sms_input]').val();
		var name = $('input[name=name]').val();
		var surname = $('input[name=surname]').val();
		var patronymic = $('input[name=patronymic]').val();
		var email = $('input[name=mail]').val();
		var gender = $('[name=gender]').val();
		var phone = $('[name=phone]').val().replace(/( |-|\+7|\(|\)|\+)/ig, '');
		fw_MOJAX.send({
			url: '/ajax/forms/checkSmsCodeAndRegister/',
			overlay_type: fw_MOJAX.FULL_OVERLAY,
			data: {
				name: name,
				surname: surname,
				patronymic: patronymic,
				email: email,
				smsCode: smsCode,
				phone: phone,
				gender: gender
			},
			user_success: function (data) {
				if (data.isValidSMSCode && data.user_oid) {
					enableAdditionalFields();
					$('.enter_sms_code_wrapper').hide();
					$('.form_phone_block').addClass('success_phone');
					$('[name=client_oid]').val(data.user_oid);
					$('[name=organization]').val($('[name=org]').val());
					$('[name=name_hidden]').val($('[name=name]').val());
					$('[name=phone_hidden]').val($('[name=phone]').val());
					$('[name=mail_hidden]').val($('[name=mail]').val());
				} else {
					$('.enter_sms_code_wrapper .field_wrapper').addClass('formjor-err');
				}
			},
			user_fail: function () {
				alert('Ошибка');
			},
			user_error: function () {
				alert('Ошибка');
			}
		});
		console.log('send_sms_code');
	});
});