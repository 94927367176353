function CreditStates(type) {
	var self = this;

	this._states = [];

	this._type = null;
	this._carPrice = null;


	function checkState(initial_fee, years, with_life_insurance) {
		var result = {
			creditSum: self._carPrice - initial_fee,
			error: "",
			validState: null,
			creditPeriod: years,
			initialFee: initial_fee
		};
		var curState = getState(initial_fee);
		if (with_life_insurance) {
			var credit_sum = parseFloat(result.creditSum);
			result.creditSum = credit_sum + Math.round(
					credit_sum *
					parseFloat(curState.getLifeInsuranceRate()) / 12 / 100 *
					parseFloat(result.creditPeriod) / (1 - parseFloat(curState.getLifeInsuranceRate()) / 12 / 100 * parseFloat(result.creditPeriod))
			);
		}
		console.log(result.creditSum);
		if (!curState) {
			result.error = "Нет программы для установленного первоначального взноса.";
			$('#initial_fee_data').closest('.super_input').addClass('error');
		} else if (years < 12 || years > curState.getYearsLimit()) {
			result.error = "Выбранная программа не поддерживает такой срок кредита.";
			$('#years_credit_data').closest('.super_input').addClass('error');
		} else if (result.creditSum > curState.getMaxCresutSum()) {
			result.error = "Сумма кредита не может превышать 5\u00A0000\u00A0000 руб. Пожалуйста, увеличьте размер первоначального взноса.";
			$('#initial_fee_data').closest('.super_input').addClass('error');
		} else {
			result.validState = curState;
			$('#years_credit_data').closest('.super_input').removeClass('error');
			$('#initial_fee_data').closest('.super_input').removeClass('error');
		}
		return result;
	}

	function getState(initial_fee) {
		var pc = (initial_fee / self._carPrice) * 100;
		return self._states.find(function (item) {
			return item.isCurrent(pc);
		});
	}

	function init(type, carPrice) {
		self._type = type;
		self._carPrice = carPrice;
		if (self._type == "normal") {
			// Working state http://test.major-expert.ru/cars/moscow/volvo/xc90/1049704/
			self._states.push(new CreditState({
				initial_fee_pc: 80,
				rate: 13.6,
				rate_with_life_insurance: 10.6,
				life_insurance_rate: 2.5,
				years_limit: 60,
				min_price: 120000,
				max_price: 4000000,
				max_credit_sum: 5000000
			}));
		}
	}

	this.init = init;
	this.getState = getState;
	this.checkState = checkState;
}

function CreditState(data) {
	var self = this;

	this._initialFeePercentage = data['initial_fee_pc'];
	this._rate = data['rate'];
	this._rate_with_life_insurance = data['rate_with_life_insurance'];
	this._life_insurance_rate = data['life_insurance_rate'];
	this._minPrice = data['min_price'];
	this._maxPrice = data['max_price'];
	this._yearsLimit = data['years_limit'];
	this._maxCreditSum = data['max_credit_sum'];


	function isCurrent(initial_fee_pc) {
		return self._initialFeePercentage >= initial_fee_pc;
	}

	function getYearsLimit() {
		return self._yearsLimit;
	}

	function getMinPrice() {
		return self._minPrice;
	}

	function getMaxPrice() {
		return self._maxPrice;
	}

	function getMaxCreditSum() {
		return self._maxCreditSum;
	}

	function getRate() {
		return self._rate;
	}

	function getRateWithLifeInsurance() {
		return self._rate_with_life_insurance;
	}

	function getLifeInsuranceRate() {
		return self._life_insurance_rate;
	}

	this.getRate = getRate;
	this.getRateWithLifeInsurance = getRateWithLifeInsurance;
	this.getLifeInsuranceRate = getLifeInsuranceRate;
	this.getMaxPrice = getMaxPrice;
	this.getMaxCresutSum = getMaxCreditSum;
	this.getMinPrice = getMinPrice;
	this.isCurrent = isCurrent;
	this.getYearsLimit = getYearsLimit;
}

function CreditForm(type, carPrice) {
	var START_YEAR = 12;

	var self = this;
	this._lifeInsuranceCheck = $("input[name=life_insurance]");
	this._isWithLifeInsurance = true;
	this._type = type;
	this._carPrice = parseInt(carPrice);
	this._states = new CreditStates();
	this._states.init(this._type, this._carPrice);

	this._priceSlider = document.getElementById('slider_price');
	this._priceInput = $('#initial_fee_data');

	this._yearsSlider = document.getElementById('slider_years');
	this._yearsInput = $('#years_credit_data');

	this._calcButton = $('#calc_credit_button');
	this._calcResults = $('#calc_results');
	this._calcError = $('#calc_error');

	function init() {
		initPriceSlider();
		initYearSlider();
		self._calcButton.on("click", function (e) {
			calclateCredit();
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push ({
				event: 'formSendSuccess',
				eventCategory: 'Рассчитать кредит'
			});
		});
		self._lifeInsuranceCheck.change(function () {
			calclateCredit();
		});
	}

	function calclateCredit() {
		self._isWithLifeInsurance = isWithLifeInsurance();
		console.log(self._isWithLifeInsurance);
		var data = getCurrentCreditInfo();
		displayResults(data);
		$(this).blur();
		$(this).find(".button_label").text("Пересчитать кредит");
	}

	function isWithLifeInsurance() {
		return !!(self._lifeInsuranceCheck[0].checked);
	}

	function initPriceSlider() {
		// Слайдеры
		var range_price_slider = {
			'min': [self._carPrice * 0.2],
			'33.3%': [self._carPrice * 0.4],
			'66.6%': [self._carPrice * 0.6],
			'max': [self._carPrice * 0.8]
		};
		var price_pips_values = [0, 33.3, 66.6, 100];

		if (self._type == "lada") {
			range_price_slider = {
				'min': [0],
				'25%': [self._carPrice * 0.15],
				'50%': [self._carPrice * 0.2],
				'max': [self._carPrice]
			};
			price_pips_values = [0, 25, 50, 100];
		}

		noUiSlider.create(self._priceSlider, {
			animate: true,
			behaviour: 'tap',
			start: [self._carPrice * 0.2],
			connect: 'lower',
			range: range_price_slider,
			format: {
				to: function (value) {
					return fw_MAMMON.formattedNumber(Math.round(value));
				},
				from: function (value) {
					return value.replace(' ', '');
				}
			},
			pips: {
				mode: 'positions',
				density: 1000,
				values: price_pips_values,
				format: {
					to: function (value) {
						return Math.floor((value / self._carPrice) * 100) + '%';
					},
					from: function (value) {
						var result = parseInt(value.replace('%', ''));
						return self._carPrice * (result / 100);
					}
				}
			}
		});

		// Привязки
		self._priceSlider.noUiSlider.on("update", function (values, handle) {
			self._priceInput.val(values[handle]);
		});
		self._priceInput.on('change.slider', function () {
			self._priceSlider.noUiSlider.set([$(this).val()]);
		});
		self._priceSlider.noUiSlider.on('set', function () {
			var curState = self._states.getState(getCurrentInitialFee());
			setYears(curState.getYearsLimit());
			updateYearSlider();
		});
	}

	function getCurrentInitialFee() {
		return parseInt(self._priceSlider.noUiSlider.get().split(' ').join(''));
	}

	function getCurrentYear() {
		return parseInt(self._yearsSlider.noUiSlider.get());
	}

	function getCurrentCreditInfo() {
		return self._states.checkState(getCurrentInitialFee(), getCurrentYear(), isWithLifeInsurance());
	}

	function updateYearSlider() {
		if (getLastYears() != getYears()) {
			setYearSlider();
		}
	}

	function initYearSlider() {
		setYearSlider(true);
	}

	function setLastYears(lastYears) {
		self._yearsInput.data('last-years', lastYears);
	}

	function getLastYears() {
		return self._yearsInput.data('last-years');
	}

	function setYears(years) {
		return self._yearsInput.data('years', years);
	}

	function getYears() {
		var curState = self._states.getState(getCurrentInitialFee());
		var end_year = curState.getYearsLimit();
		return end_year;
	}

	function setYearSlider(initial) {
		var val = START_YEAR;
		var end_year = getYears();
		setLastYears(getYears());
		if (!initial) {
			val = self._yearsSlider.noUiSlider.get();
			self._yearsSlider.noUiSlider.destroy();
		}

		var year_pips_values = end_year / 12;
		var range_year_slider = {
			'min': [START_YEAR],
			'max': [end_year]
		};

		createYearSlider(val, range_year_slider, year_pips_values);
	}

	function createYearSlider(val, range_year_slider, year_pips_values) {
		noUiSlider.create(self._yearsSlider, {
			animate: true,
			behaviour: 'tap',
			start: [val],
			connect: 'lower',
			range: range_year_slider,
			format: {
				to: function (value) {
					return Math.round(value);
				},
				from: function (value) {
					return value;
				}
			},
			pips: {
				mode: 'count',
				density: 1000,
				values: year_pips_values
			}
		});

		self._yearsSlider.noUiSlider.on("update", function (values, handle) {
			var value = values[handle];
			self._yearsInput.val(value);
		});
		self._yearsInput.off('.slider');
		self._yearsInput.on('change.slider', function () {
			self._yearsSlider.noUiSlider.set([$(this).val()]);
		});
	}

	function displayResults(data) {
		if (data.validState != null) {
			self._calcResults.removeClass("hidden");
			self._calcError.addClass("hidden");
			$(".fw_win_wrapper").scrollTo(self._calcResults, 300, {offset: {top: -60}});
			var rate = self._isWithLifeInsurance ? data.validState.getRateWithLifeInsurance() : data.validState.getRate();
			var textRate = rate+ '%';
			// var textRate = data.validState.getRate() + '%';
			var textPeriod = data.creditPeriod + ' ' + fw_MAMMON.morph(data.creditPeriod, "месяц", "месяца", "месяцев");
			var textEstimatedPeriod = getEstimatedCreditPeriod(data.creditPeriod);
			self._calcResults.find(".credit_sum_block .value").html(fw_MAMMON.formattedNumber(data.creditSum) + ' <i class="icon-rouble"></i>');
			self._calcResults.find(".credit_percents_rate .value").html(textRate);
			self._calcResults.find(".credit_period .value").html(textPeriod);
			/* Взято со старого*/
			var monthly_ratio = parseFloat(data.validState.getRate() / 12 / 100);
			var total_cost_ratio = Math.pow(1 + monthly_ratio, data.creditPeriod);
			// var monthly_pay = data.creditSum * monthly_ratio * total_cost_ratio / (total_cost_ratio - 1);
			console.log(rate);
			var monthly_pay =
					data.creditSum * (
							rate/12/100 * Math.pow(
									(1 + rate/12/100), data.creditPeriod
							)
					) / (Math.pow((1 + rate/12/100), data.creditPeriod) - 1);
			/* /Взято со старого*/
			self._calcResults.find(".credit_monthly_payment .value").html(fw_MAMMON.formattedNumber(monthly_pay) + ' <i class="icon-rouble"></i>');
			self._calcResults.find(".credit_estimated_period .value").html(textEstimatedPeriod);

			setSystemField("period", textPeriod);
			setSystemField("an_initial_fee", fw_MAMMON.formattedNumber(data.initialFee) + ' руб.');

			setSystemField("credit_summ", fw_MAMMON.formattedNumber(data.creditSum) + ' руб.');
			setSystemField("percentage", textRate);
			setSystemField("monthly_payment", fw_MAMMON.formattedNumber(monthly_pay) + ' руб.');
			setSystemField("full_percentage_summ", fw_MAMMON.formattedNumber(monthly_pay * data.creditPeriod - data.creditSum) + ' руб.');
			setSystemField("estimated_period", textEstimatedPeriod);
			/* Используется для отправки события расчета*/
			self._calcResults.trigger('gtm_display_result');
			/* /Используется для отправки события расчета*/
		} else {
			self._calcError.removeClass("hidden");
			self._calcResults.addClass("hidden");
			self._calcError.find(".credit_error_message").text(data.error);
		}
	}

	function getMonthName(month) {
		return ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"][month];
	}

	function getEstimatedCreditPeriod(months) {
		var tmp_date = new Date(),
				credit_period = getMonthName(tmp_date.getMonth()) + ' ' + tmp_date.getFullYear() + ' - ';
		tmp_date.setMonth(tmp_date.getMonth() + months + 1);
		tmp_date.setDate(0);
		credit_period += getMonthName(tmp_date.getMonth()) + ' ' + tmp_date.getFullYear();
		return credit_period;
	}

	function setSystemField(name, value) {
		self._calcResults.find(".system input[name='" + name + "']").val(value);
	}

	this.init = init;
}