function checkselectClearElement(el) {
    el.find('input').prop('checked', false).removeClass('-checked');
    var placeholder = el.attr('data-placeholder');
    el.find('select option:first').html(placeholder);
    el.removeClass('-checked');

    if (el.hasClass('j-enhanced-brands')) {
        $('.j-enhanced-models').html('').addClass('-disabled');
        $('.j-enhanced-models').checkselect();
    }
}

function checkselectOpen(el) {
    el.addClass('-opened');
}

function checkselectClose(el) {
    el.removeClass('-opened');
}

(function ($) {
    function setChecked(target, with_trigger, element_selected, val_selected) {
        var wrapper = $(target).closest('.checkselect');
        if (!(typeof (with_trigger) != "undefined" && with_trigger !== null)) {
            with_trigger = true;
        }

        if (with_trigger) {
            wrapper.trigger('changeCheckSelect',
                {
                    element_selected: element_selected,
                    val_selected: val_selected
                }
            );

        }

        var checked = wrapper.find("input:checked").length;

        if (checked) {

            var text = '';
            var name = '';

            if ((wrapper.hasClass('j-enhanced-brands') || wrapper.hasClass('j-enhanced-models')) && checked > 1) {
                text = checked;
                if (wrapper.hasClass('j-enhanced-brands')) {
                    name = ' марки';
                    if (checked > 4)
                        name = ' марок';
                } else {
                    name = ' модели';
                    if (checked > 4)
                        name = ' моделей';
                }
                text += name;
            } else {
                wrapper.find("input:checked").each(function () {
                    text += ', ' + $(this).closest('label').find('.text').text();
                });
                text = text.slice(1);
            }
            wrapper.find('select option:first').html(text);
            wrapper.addClass('-checked');
        } else {

            var placeholder = wrapper.attr('data-placeholder');
            wrapper.find('select option:first').html(placeholder);
            wrapper.removeClass('-checked');
        }
    }

    function clearAll(wrapper, with_trigger) {
        //var with_trigger = true;
        if (typeof with_trigger == "undefined") {
            with_trigger = true;
            //with_trigger = with_trigger;
        }

        var selected = wrapper.find('input:checked');
        if (selected.length)
            checkselectClearElement(wrapper);
        else
            checkselectClose(wrapper);
        if (with_trigger) {
            wrapper.trigger('changeCheckSelect', {
                clear_all: true
            });
        }
    }

    var methods = {
        init: function (options) {
            this.wrapInner('<div class="checkselect-popup"><div class="popup-inner"></div></div>');
            this.prepend(
                '<div class="checkselect-control">' +
                '<select class="checkselect-select"><option></option></select>' +
                '<div class="checkselect-over"></div><div class="checkselect-clear"></div>' +
                '</div>'
            );

            this.find('.checkselect-clear').click(function () {

                var wrapper = $(this).closest('.checkselect');
                clearAll(wrapper);
            });

            this.find('input').each(function () {
                $(this).parent().find('.text-and-ch').append('<span class="ch"></span>');
            });

            this.each(function () {
                setChecked(this);
            });
            this.find('input').click(function () {

                setChecked($(this).parents('.checkselect'), true, $(this), $(this).attr('value'));
            });

            this.parent().find('.checkselect-over').on('click', function () {

                $wrapper = $(this).closest('.checkselect');
                if ($wrapper.hasClass('-disabled'))
                    return false;

                if (!$wrapper.hasClass('-opened')) {
                    checkselectClose($('.checkselect'));
                    checkselectOpen($wrapper);
                } else
                    checkselectClose($('.checkselect'));
            });

            $('html, body').on('click', function (e) {
                if ($(e.target).closest('.checkselect').length === 0){
                    checkselectClose($('.checkselect'));
                }
            });
            // А ВОТ ЭТОТ
        },
        show: function () {

            // ПОДХОД
        },
        hide: function () {
            // ПРАВИЛЬНЫЙ
        },
        update: function (content) {
            // !!!
        },
        setValue: function (param) {

            var value = param.value;
            var with_trigger = true;
            if (typeof param['with_trigger'] !== "undefined") {
                with_trigger = param['with_trigger'];

            }
            var el = this.find('input[value="' + value + '"]');
            if (el.length > 0) {
                el.prop('checked', true);
                setChecked(el, with_trigger);
            }
        },

        setValueOff: function (value) {
            var el = this.find('input[value=' + value + ']');
            el.prop('checked', false);
            setChecked(el);
        },
        setDisableAll: function (param) {
            var with_trigger = true;
            if (typeof param['with_trigger'] !== "undefined") {
                with_trigger = param['with_trigger'];

            }


            clearAll(this, with_trigger);
        }

    };
    $.fn.checkselect = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Метод с именем ' + method + ' не существует для jQuery.tooltip');
        }
    };

})(jQuery);

$(function () {
    setCheckselects();
});


function setCheckselects() {
    $('.enhanced-search-block .checkselect').checkselect();
}

$(function () {
    $('body').on('blur', '.j-input-digit', function () {
        if (this.value)
            $(this).addClass('-checked');
    });


    $('body').on('focus', '.j-input-digit', function () {
        $(this).removeClass('-checked');
    });

    $('body').on('changeCheckSelect', '.j-enhanced-brands', function () {//смена бренда на панели поиска перенести в search panel
        changeModels(SEARCH_PANEL.elements.hidden_panel_content.find('.j-enhanced-models'));
    });

    $('body').on('click', '.j-send-enhanced', function () {

        var form = $(this).closest('form');
        //$(this).closest('form').submit();
        window.location.replace("/cars/#search");
        return false;
    });

    $(document).on('change', '.-enhanced-search input', function () {
        var form = $(this).closest('form');
    });

    $('body').on('click', '.j-clear-enhanced', function () {

        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='brand[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='price[from]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='price[to]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='run[from]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='run[to]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='year[from]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='year[to]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='gearbox[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='engine_type[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='body[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='salon_seats[from]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='salon_seats[to]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='city[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='salon[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='drive_type[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='sale_price[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='spec_program_2[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find(".checkselect[data-name-wrapper='spec_program[]']").checkselect('setDisableAll', {with_trigger: true});
        SEARCH_PANEL.elements.hidden_panel_content.find('input[type=text]').removeClass('-checked').val('');
    });
});

function changeModels(modelsElement) {
    $(modelsElement).html('');
    $(modelsElement).closest('.section').find('.j-enhanced-brands input:checked').each(function () {
        var brandId = $(this).val();
        var brandActive = brands_and_models[brandId];
//

        var brandText = '';

        for (var key in brandActive) {
            var modelInfo = models[key];
            if (modelInfo) {
                brandText += '<div class="item"><label><input type="checkbox" name="model[]" value="' + key + '"><div class="text-and-ch"><span class="text">' + modelInfo[0] + '</span></div>';
                if (modelInfo[1])
                    brandText += "<div class='small'>" + modelInfo[1] + " авто</div>";
                brandText += '</label></div>';
            }
        }
        if (brandText && brandsNames[brandId]) {
            brandText = '<fieldset data-brand-id="' + brandId + '"><legend>' + brandsNames[brandId] + '</legend>' + brandText + '</fieldset>';
            modelsElement.append(brandText);
        }
    });

    if (modelsElement.html())
        modelsElement.removeClass('-disabled');
    else
        modelsElement.addClass('-disabled');

    modelsElement.checkselect();
}











