var SELECTED_OPTIONS = function () {
    var selected_options = {

        selected_timer: null,

        getOptionsSelected: function () {
            SELECTED_OPTIONS.dropSelectedTimer();
            SELECTED_OPTIONS.selected_timer =
            window.setTimeout(
                function () {
                    var size = 0;
                    size = size +
                        SELECTED_OPTIONS.getSelectedValue("brand\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("model\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("price\\[from\\]") +
                        SELECTED_OPTIONS.getSelectedValue("price\\[to\\]") +
                        SELECTED_OPTIONS.getSelectedValue("run\\[from\\]") +
                        SELECTED_OPTIONS.getSelectedValue("run\\[to\\]") +
                        SELECTED_OPTIONS.getSelectedValue("year\\[from\\]") +
                        SELECTED_OPTIONS.getSelectedValue("year\\[to\\]") +
                        SELECTED_OPTIONS.getSelectedValue("engine_type\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("gearbox\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("body\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("drive_type\\[\\]") +
                        SELECTED_OPTIONS.getChangedInputValue("[name=volume\\[from\\]]") +
                        SELECTED_OPTIONS.getChangedInputValue("[name=volume\\[to\\]]") +
                        SELECTED_OPTIONS.getChangedInputValue("[name=power\\[from\\]]") +
                        SELECTED_OPTIONS.getChangedInputValue("[name=power\\[to\\]]") +
                        SELECTED_OPTIONS.getSelectedValue("salon_seats\\[from\\]") +
                        SELECTED_OPTIONS.getSelectedValue("salon_seats\\[to\\]") +
                        SELECTED_OPTIONS.getSelectedValue("sale_price\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("spec_program\\[\\]") +
                        SELECTED_OPTIONS.getSelectedValue("spec_program_2\\[\\]") +
                        //SELECTED_OPTIONS.getSelectedValue("city\\[\\]") + // не учитывается при поиске
                        SELECTED_OPTIONS.getSelectedValue("salon\\[\\]")
                    ;
                    $(".filter_button .count").text(size);
                    $(".open_close_panel .count").text(size);
                    if (size == 0){
                        $(".filter_button .count").hide();
                        $(".open_close_panel .count").hide();
                    }else {
                        $(".filter_button .count").show();
                        $(".open_close_panel .count").show();
                    }
                },
                100
            );
        },

        getSelectedValue: function (selector){
            var edited = false;
            var selected = 0;
            SEARCH_PANEL.elements.hidden_panel_content.find("[data-name-wrapper="+ selector +"] input").each(function(){
                if (edited) return;
                if ($(this).prop('checked')){
                    selected = 1
                    edited = true;
                }
            });
            return selected;
        },

        getChangedInputValue: function (selector){
            if (SEARCH_PANEL.elements.hidden_panel_content.find(selector).val() === ''){
                return 0;
            }
            return 1;
        },

        dropSelectedTimer() {
            if (SELECTED_OPTIONS.selected_timer != null) {
                window.clearTimeout(SELECTED_OPTIONS.selected_timer);
                SELECTED_OPTIONS.selected_timer = null;
            }
        }
    };

    return selected_options;
}();