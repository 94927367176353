var fw_MAPLATER = function(){
	"use strict";
	if(typeof(fw_MAPLATER) !== "undefined"){
		return fw_MAPLATER;
	}
	var $this,
		functions = {
			spaceToSpecialChars: function(str){
				return (str + '').replace(/\t/g, "&nbsp;&nbsp;&nbsp;");
			},
			print_r_simple: function(str){
				return "<span class='primitive_value value_is_string'>" + functions.spaceToSpecialChars($.nl2br(fw_MAMMON.escapeHtml(str))) + "</span>";
			},
			print_r_null: function(){
				return "<span class='primitive_value value_is_null'>null</span>";
			},
			print_r_nan: function(){
				return "<span class='primitive_value value_is_null'>NaN</span>";
			},
			print_r_boolean: function(obj){
				return "<span class='primitive_value value_is_bool'>" + (obj ? "true" : "false") + "</span>";
			},
			print_r_object: function(obj){
				var strDump = "<span class='value_is_object'>" + ($.isArray(obj) ? "Array" : "Object") + "</span>(",
					key;
				for(key in obj){
					if(obj.hasOwnProperty(key)){
						strDump += "<div class='object_wrapper'>";
						strDump += "<span class='object_key'>[" + key + "]</span> => ";
						strDump += functions.print_r_by_type(obj[key]);
						strDump += "</div>";
					}
				}
				strDump += ")";
				return strDump;
			},
			print_r_function: function(func){
				var funcDump = func.toString(),
					prepend = "", append = "";
				if(!/^function/.test(funcDump)){
					prepend = "<span class='value_is_object'>function</span>(){";
					append = "}";
				}
				funcDump = "<strong>" + prepend + functions.spaceToSpecialChars($.nl2br(fw_MAMMON.escapeHtml(funcDump))).replace(/(^|[^\w])(function) *(\w+)* *\((\w+( ?, ?(\w+))*)\)/ig, "$1<span class='value_is_object'>function</span> <span class='function_name'>$3</span>(<span class='function_params'>$4</span>)") + append + "</strong>";
				return funcDump;
			},
			print_r_by_type: function(obj){
				var varDump = "",
					objType = typeof(obj);
				switch(true){
					case obj === null:
						varDump += functions.print_r_null();
						break;
					case objType === "number" && isNaN(obj):
						varDump += functions.print_r_nan();
						break;
					case objType === "function":
						varDump += functions.print_r_function(obj);
						break;
					case objType === "boolean":
						varDump += functions.print_r_boolean(obj);
						break;
					case objType === "object":
						varDump += functions.print_r_object(obj);
						break;
					default:
						varDump += functions.print_r_simple(obj);
				}
				return varDump;
			},
			
			fetchTemplate: function(data, template){
				var missing_fields = [],
					html = template.replace(new RegExp("%([A-z_]+)%", "g"), function(param_tpl, param_name){
						if(data.hasOwnProperty(param_name)){
							return data[param_name];
						}
						missing_fields.indexOf(param_name) === -1 && missing_fields.push(param_name);
						return "";
					});
				(!$.empty(missing_fields) && console.warn("В объекте данных не найдены поля: " + missing_fields.join(", ")));
				return html;
			},
			fetchArrayTemplate: function(param_array, template){
				var html = "",
					length = param_array.length,
					i;
				for(i = 0; i < length; i++){
					html += functions.fetchTemplate(param_array[i], template);
				}
				return html;
			},
			fetchObjectTemplate: function(param_object, template){
				var html = "",
					key;
				for(key in param_object){
					if(param_object.hasOwnProperty(key)){
						html += functions.fetchTemplate(param_object[key], template);
					}
				}
				return html;
			}
		};
	$this = {
		print_r: function(varObj, to_id){
			if(to_id){
				$('#' + to_id).append(functions.print_r_by_type(varObj));
				return true;
			}
			return functions.print_r_by_type(varObj);
		},
		fetchTemplate: function(data, template){
			return functions.fetchTemplate(data, template);
		},
		fetchArrayTemplate: function(data, template){
			return functions.fetchArrayTemplate(data, template);
		},
		fetchObjectTemplate: function(data, template){
			return functions.fetchObjectTemplate(data, template);
		}
	};
	return $this;
}();