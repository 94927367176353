var Mopup = function () {
	var default_options = {
		content: 'Укажите контент',
		auto_close_time: 0, //секунды,
		single_mode: true, //скрывать остальные мопапы, когда следующий мопап появляется
		custom_class: '',
		close_button_class: 'close_mopup_button'
	};
	var MOPUP_CLASS = 'mopup';
	
	var $mopup = {
		instances: {},
		createInstance: function (custom_options) {
			function init() {
				$instance.options = $.extend({}, default_options, custom_options);
				if ($instance.options.single_mode) {
					hideAllMopups();
				}
				createHtml();
				$mopup.instances[$instance.id] = $instance;
				$('body').on('click', '.' + $instance.options['close_button_class'], function () {
					$instance.hideMopup();
				});
			}
			var $instance = {
				id: null,
				container: null,
				options: null,
				showMopup: function (callback) {
					callback = callback || function (){};
					var timeout = $instance.options.timeout || 0;
					switch ($instance.options.action) {
						case 'slide':
							var bottom_state = $instance.container.css('bottom');
							setTimeout(function () {
								$instance.container.css({bottom: '-20%', display: 'block'}).animate({bottom: bottom_state}, 500, function () {
									$instance.container.removeAttr('style');
									callback();
								});
							}, timeout);
							break;
						default:
							setTimeout(function () {
								$instance.container.show();
							}, timeout);
							break;
					}
					if ($instance.options.auto_close_time) {
						var time = $instance.options.auto_close_time * 1000;
						setTimeout($instance.hideMopup, time);
					}
				},
				hideMopup: function () {
					$instance.container.remove();
					delete $mopup.instances[$instance.id];
				}
			};
			function hideAllMopups() {
				for (var instance_key in $mopup.instances) {
					if ($mopup.instances.hasOwnProperty(instance_key)) {
						$mopup.instances[instance_key].hideMopup();
					}
				}
			}
			function createHtml() {
				$instance.container = $('<div>');
				$instance.container.addClass(MOPUP_CLASS).addClass($instance.options.custom_class).css('display','none').html($instance.options.content);
				$('body').append($instance.container);
				$instance.id = $instance.container.getUniqId();
			}
			
			init();
			return $instance;
		}
	};
	return $mopup;
}();