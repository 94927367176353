function RootBlockSearch(){
	var self = this;
	this.container = null;
	this.searchButton = null;
	this.activeElements = null;
	this.checkBoxes = null;
	this.buttonLabelTarget = null;
	this.carsCountTarget = null;
	
	function init(){
		self.container = $(".buy_car");
		self.searchButton = self.container.find(".start_search_button");
		self.activeElements = self.container.find("select, input");
		self.checkBoxes = self.container.find(".custom_check input");
		self.buttonLabelTarget = self.searchButton.find(".button_label");
		self.carsCountTarget = $('.all_cars_count_fill_ajax');
		setEvents();
		getCountListByFilter();
	}
	
	function setEvents(){
		self.checkBoxes.on("change", function(){
			checkCheck(this);
		});
		self.searchButton.on("click", function(){
			getSearchLink();
		});
		self.activeElements.on("change", function(){
			getCountListByFilter();
		});
	}

	function checkCheck(input){
		var container = $(input).closest(".custom_check");
		if($(input).is(":checked")){
			container.addClass("checked");
		}
		else{
			container.removeClass("checked");
		}
	}
	
	function getCountListByFilter(){
		var data = fw_MAMMON.serializeObjSmart(self.container);
		data.current_url = location.pathname;
		fw_MOJAX.send({
			url: "/ajax/cars/getCarsCountByFilter/",
			data: data,
			user_success: function(data, user_data){
				setCarsCountResult(data['cars_count']);
			},
			overlay_mode: fw_MOJAX.NONE,
			code: "counter_root"
		});
	}

	
	function setCarsCountResult(count){
		self.buttonLabelTarget.text("Показать "+count+" авто");
		self.carsCountTarget.text(count);
	}
	
	function getSearchLink(){
		var data = fw_MAMMON.serializeObjSmart(self.container);
		data.current_url = location.pathname;
		fw_MOJAX.send({
			url: "/ajax/cars/analyzeFilter/",
			data: data,
			user_data: data,
			user_success: function(data, user_data){
				if(data['need_redirect_to']){
					if (window.history && history.pushState) {
						SEARCH_PANEL.redirectByHistory(data);
					}
					else {
						window.location.href = data['need_redirect_to'];
					}
				}
			},
			overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
			overlay_to: self.container
		});
	}
	
	this.init = init;
}