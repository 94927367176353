// проерка на IE
function isIE() {
	var ua = navigator.userAgent;
	var is_ie = ua.indexOf("MSIE") > -1
			|| ua.indexOf("Trident/") > -1
			|| ua.indexOf("Edge") > -1;
	return is_ie;
}

// добавляем класс -ie для элементов
if (isIE()) {
	var elements = [
		'.cars-list .car',
		'.dynamic-banner-wrapper',
		'.static-banner-wrapper',
	];

	for (var i = 0, n = elements.length; i < n; i++) {
		var el = $(elements[i]);
		if (el.length) {
			el.addClass('-ie');
		}
	}
}

// проверка на safari
function isSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);;
}
if (isSafari()) {
	var elements = [
		'.cars-list .car',
		'.dynamic-banner-wrapper',
		'.static-banner-wrapper',
	];

	for (var i = 0, n = elements.length; i < n; i++) {
		var el = $(elements[i]);
		if (el.length) {
			el.addClass('-safari');
		}
	}
}
